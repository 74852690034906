import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withModifiers as _withModifiers, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_dependants_icon = _resolveComponent("dependants-icon")!
  const _component_side_menu_item = _resolveComponent("side-menu-item")!
  const _component_security_icon = _resolveComponent("security-icon")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_side_menu_item, {
      isNestedItem: "",
      onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.goToMenuItem(_ctx.Routes.manageDependants)), ["stop"]))
    }, {
      icon: _withCtx(() => [
        _createVNode(_component_dependants_icon)
      ]),
      title: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.manageDependantsTitle), 1)
      ]),
      _: 1
    }),
    _createVNode(_component_side_menu_item, {
      isNestedItem: "",
      onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_ctx.goToMenuItem(_ctx.Routes.accountSettingsSecurity)), ["stop"]))
    }, {
      icon: _withCtx(() => [
        _createVNode(_component_security_icon)
      ]),
      title: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.securityTitle), 1)
      ]),
      _: 1
    })
  ], 64))
}