import { HttpError } from "@/http/types/http-error.interface";
import { MutationTypes } from "@/store/constants/mutation-types";
import { PatientProfile } from "../types/patient-profile";
import { PatientProfileState } from "../types/patient-profile-state";
import { PhoneEntry } from "../types/phone-entry";

export const patientProfileMutations = {
  [MutationTypes.PATIENT_PROFILE__SET_PATIENT_PROFILE](
    state: PatientProfileState,
    profile: PatientProfile | null
  ) {
    state.patientProfile = profile;
  },
  [MutationTypes.PATIENT_PROFILE__SET_PATIENT_ID_TO_INITIATE_PATIENT_PROFILE](
    state: PatientProfileState,
    patientId: number | null
  ) {
    state.patientIdToInitiatePatientProfile = patientId;
  },
  [MutationTypes.PATIENT_PROFILE__SET_PATIENT_PHONES](
    state: PatientProfileState,
    phones: PhoneEntry[]
  ) {
    if (state.patientProfile?.phones) {
      state.patientProfile.phones = phones;
    }
  },
  [MutationTypes.PATIENT_PROFILE__SET_PATIENT_AVATAR](
    state: PatientProfileState,
    img: string | null
  ) {
    if (img && state.patientProfile) state.patientProfile.base64Avatar = img;
  },
  [MutationTypes.PATIENT_PROFILE__TOGGLE_LOADING](
    state: PatientProfileState,
    isLoading: boolean
  ) {
    state.loading = isLoading;
  },
  [MutationTypes.PATIENT_PROFILE__SET_ERROR](
    state: PatientProfileState,
    error: HttpError | null
  ) {
    state.error = error;
  },
};

export type PatientProfileMutations = typeof patientProfileMutations;
