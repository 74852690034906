
import { defineComponent } from "vue";
import { Routes } from "@/router";
import ClinicIcon from "@/ui-library/icons/clinic-icon.vue";
import ProfileSettingsIcon from "@/ui-library/icons/profile-settings-icon.vue";
import SideMenuItem from "./side-menu-item.vue";

export default defineComponent({
  emits: {
    goToRoute: (route: Routes) => Boolean(route),
  },
  components: {
    SideMenuItem,
    ClinicIcon,
    ProfileSettingsIcon,
  },
  setup(props, context) {
    const goToMenuItem = (route: Routes) => {
      context.emit("goToRoute", route);
    };
    return {
      manageClinicsTitle: "Manage Clinics",
      editPatientProfileTitle: "Edit Patient Profile",
      Routes,
      goToMenuItem,
    };
  },
});
