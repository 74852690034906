import { computed, watchEffect } from "vue";
import { useMassCommunicationsStore } from "@/features/mass-communications/store/mass-communications-store";
import { useSessionStore } from "../../../store/session-store";

export function useInitializeMassCommunications() {
  const massCommunicationStore = useMassCommunicationsStore();
  const sessionStore = useSessionStore();
  const patientId = computed(() => sessionStore.getSessionOwnerPatientId);
  watchEffect(() => {
    if (sessionStore.getIsSessionFetched) {
      if (patientId.value) {
        massCommunicationStore.fetchMassCommunications(patientId.value);
      }
    }
  });
}
