import { AxiosResponse } from "axios";
import { RegistratonStatus } from "../registration-status.enum";
import { AccountStatus } from "../subscriptions/account-status";
import { AuthSession, SessionResponseDto } from "./auth-session.interface";

export function isTwoFactorEnabledInSession(session: AuthSession) {
  return session.twoFactorEnabled;
}

export function isRegistrationCompleteBySession(session: AuthSession) {
  return session.patientConfirmed && session.emailConfirmed;
}

export function createInitialAuthSession(): AuthSession {
  return {
    name: null,
    patientUserId: null,
    patientId: null,
    email: null,
    patientConfirmed: false,
    recoveryPhone: null,
    emailConfirmed: false,
    registrationStatus: RegistratonStatus.incomplete,
    twoFactorEnabled: false,
    activeTrial: false,
    accountStatus: "Trial",
    trialEndDate: "",
  };
}

export function createAuthSessionFromDto(
  response: AxiosResponse<SessionResponseDto>
): AuthSession {
  const { attributes } = response.data.data;
  return {
    name: null,
    patientUserId: parseInt(response.data.data.id),
    patientId: attributes.patient_id,
    email: attributes.email,
    patientConfirmed: attributes.patient_confirmed,
    recoveryPhone: attributes.recovery_phone,
    emailConfirmed: attributes.email_confirmed,
    registrationStatus: attributes.registration_status as RegistratonStatus,
    twoFactorEnabled: attributes.two_factor_enabled,
    activeTrial: attributes.active_trial,
    accountStatus: attributes.account_status as AccountStatus,
    trialEndDate: attributes.trial_end_date,
  };
}
