import { ActionTypes } from "@/store/constants/action-types";
import { MutationTypes } from "@/store/constants/mutation-types";
import { AvaStore } from "@/store/types/store.type";
import { router, Routes } from "@/router";
import { AvaActionContext } from "@/store/modules/types/ava-action-context";
import { ManageClinicsState } from "./types/manage-clinics-state";
import manageClinicsApi from "./services/manage-clinics-services";
import { createClinicContactInformationFromAuthorizedClinicDto } from "./types/authorized-clinic-dto";
import { createClinicContactInformationFromSearchedClinicDto } from "./types/searched-clinic-dto";
import { HttpError } from "@/http/types/http-error.interface";

export interface ManageClinicsActions {
  [ActionTypes.FETCH_AUTHORIZED_CLINICS]: (
    context: AvaActionContext<ManageClinicsState, AvaStore>,
    patientId: number
  ) => Promise<void>;
  [ActionTypes.FETCH_SEARCHED_CLINICS]: (
    context: AvaActionContext<ManageClinicsState, AvaStore>,
    searchTerm: string
  ) => Promise<void>;
  [ActionTypes.AUTHORIZE_CLINIC]: (
    context: AvaActionContext<ManageClinicsState, AvaStore>,
    payload: { patientId: number; clinicId: number }
  ) => Promise<void>;
  [ActionTypes.DEAUTHORIZE_CLINIC]: (
    context: AvaActionContext<ManageClinicsState, AvaStore>,
    payload: { patientId: number; authorizedClinicId: number }
  ) => Promise<void>;
}

export const manageClinicsActions: ManageClinicsActions = {
  async [ActionTypes.FETCH_AUTHORIZED_CLINICS](store, patientId) {
    try {
      store.commit(MutationTypes.MANAGE_CLINICS__TOGGLE_LOADING, true);
      const results = await manageClinicsApi.getManagedClinics(patientId);
      const activeAuthorizedClinics = results.data.authorized_clinics.filter(
        (clinic) => clinic.active
      );
      store.commit(
        MutationTypes.MANAGE_CLINICS__SET_AUTHORIZED_CLINICS,
        activeAuthorizedClinics.map(
          createClinicContactInformationFromAuthorizedClinicDto
        )
      );
      store.commit(MutationTypes.MANAGE_CLINICS__SET_ERROR, null);
    } catch (error) {
      store.commit(MutationTypes.MANAGE_CLINICS__SET_ERROR, error as HttpError);
    } finally {
      store.commit(MutationTypes.MANAGE_CLINICS__TOGGLE_LOADING, false);
    }
  },
  async [ActionTypes.FETCH_SEARCHED_CLINICS](store, searchTerm) {
    try {
      store.commit(MutationTypes.MANAGE_CLINICS__TOGGLE_LOADING, true);
      const results = await manageClinicsApi.getSearchedClinics(searchTerm);
      store.commit(
        MutationTypes.MANAGE_CLINICS__SET_SEARCHED_CLINICS,
        results.data.clinics.map(
          createClinicContactInformationFromSearchedClinicDto
        )
      );
      store.commit(MutationTypes.MANAGE_CLINICS__SET_ERROR, null);
    } catch (error) {
      store.commit(MutationTypes.MANAGE_CLINICS__SET_ERROR, error as HttpError);
    } finally {
      store.commit(MutationTypes.MANAGE_CLINICS__TOGGLE_LOADING, false);
    }
  },
  async [ActionTypes.AUTHORIZE_CLINIC](store, payload) {
    try {
      store.commit(MutationTypes.MANAGE_CLINICS__TOGGLE_LOADING, true);
      const result = await manageClinicsApi.authorizedClinic(
        payload.patientId,
        payload.clinicId
      );
      store.commit(
        MutationTypes.MANAGE_CLINICS__SET_NEW_AUTHORIZED_CLINIC,
        createClinicContactInformationFromAuthorizedClinicDto(
          result.data.authorized_clinic
        )
      );
      router.goTo(Routes.manageClinics);
      store.dispatch(
        ActionTypes.TOAST_SIMPLE_MESSAGE,
        "Authorization successful"
      );
      store.commit(MutationTypes.MANAGE_CLINICS__SET_ERROR, null);
    } catch (error) {
      store.commit(MutationTypes.MANAGE_CLINICS__SET_ERROR, error as HttpError);
    } finally {
      store.commit(MutationTypes.MANAGE_CLINICS__TOGGLE_LOADING, false);
    }
  },
  async [ActionTypes.DEAUTHORIZE_CLINIC](store, payload) {
    try {
      store.commit(MutationTypes.MANAGE_CLINICS__TOGGLE_LOADING, true);
      await manageClinicsApi.deAuthorizedClinic(
        payload.patientId,
        payload.authorizedClinicId
      );
      store.commit(
        MutationTypes.MANAGE_CLINICS__DELETE_CLINIC,
        payload.authorizedClinicId
      );
      store.commit(MutationTypes.MANAGE_CLINICS__SET_ERROR, null);
    } catch (error) {
      store.commit(MutationTypes.MANAGE_CLINICS__SET_ERROR, error as HttpError);
    } finally {
      store.commit(MutationTypes.MANAGE_CLINICS__TOGGLE_LOADING, false);
    }
  },
};
