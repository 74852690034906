
import { defineComponent } from "vue";
export default defineComponent({
  props: {
    primary: {
      type: Boolean,
      required: false,
    },
    secondary: {
      type: Boolean,
      required: false,
    },
    tertiary: {
      type: Boolean,
      required: false,
    },
    accent: {
      type: Boolean,
      required: false,
    },
    transparent: {
      type: Boolean,
      required: false,
    },
    caution: {
      type: Boolean,
      required: false,
    },
    spacerLeft: {
      type: Boolean,
      required: false,
    },
    spacerRight: {
      type: Boolean,
      required: false,
    },
    spacer: {
      type: Boolean,
      required: false,
    },
    disabled: {
      type: Boolean,
      required: false,
    },
    capitalize: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  computed: {
    computedButtonClasses(): object {
      return {
        primary: this.primary,
        secondary: this.secondary,
        tertiary: this.tertiary,
        accent: this.accent,
        transparent: this.transparent,
        caution: this.caution,
        capitalize: this.capitalize,
        disabled: this.disabled,
        "spacer-left": this.spacerLeft || this.spacer,
        "spacer-right": this.spacerRight || this.spacer,
      };
    },
  },
});
