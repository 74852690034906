import { useToggledValue } from "@/composition/use-toggled-value";
import { router, Routes } from "@/router";

export function useConfirmDependantSelectionModalManager() {
  const {
    isTrue: isConfirmDependantSelectionModalOpen,
    toggleValue: toggleConfirmDependantSelectionModal,
  } = useToggledValue();

  const goToDashboardAndCloseModal = () => {
    router.goTo(Routes.dashboard);
    toggleConfirmDependantSelectionModal(false);
  };
  return {
    isConfirmDependantSelectionModalOpen,
    toggleConfirmDependantSelectionModal,
    goToDashboardAndCloseModal,
  };
}
