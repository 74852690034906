import {
  Appointment,
  orderAppointmentsByStartDate,
} from "@/features/shared-appointments/types/appointment";
import { AppointmentsState } from "../types/appointments-state";

export interface AppointmentsGetters {
  isAppointmentsLoading: (appointmentsState: AppointmentsState) => boolean;
  getBookedAppointments: (
    appointmentsState: AppointmentsState
  ) => Appointment[];
  getAppointments: (appointmentsState: AppointmentsState) => Appointment[];
}

export const appointmentsGetters: AppointmentsGetters = {
  isAppointmentsLoading(appointmentsState) {
    return appointmentsState.loading;
  },
  getBookedAppointments(appointmentsState) {
    return orderAppointmentsByStartDate(appointmentsState.appointments).filter(
      (appointment) => appointment.status !== "Cancelled"
    );
  },
  getAppointments(appointmentsState) {
    return orderAppointmentsByStartDate(appointmentsState.appointments);
  },
};
