<template>
  <transition-group
    name="drop-down"
    tag="span"
    class="transition-group-wrapper"
  >
    <slot />
  </transition-group>
</template>

<style lang="scss" scoped>
.transition-group-wrapper :deep() {
  .drop-down-enter-active,
  .drop-down-leave-active {
    transition: all 0.8s ease-out;
  }
  .drop-down-enter-from,
  .drop-down-leave-to {
    transform: translateY(60px);
    opacity: 0;
  }
}
</style>
