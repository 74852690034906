import { ActionTypes } from "@/store/constants/action-types";
import { MutationTypes } from "@/store/constants/mutation-types";
import { AvaStore } from "@/store/types/store.type";
import { AvaActionContext } from "@/store/modules/types/ava-action-context";
import { ActionContext } from "vuex";
import { PatientProfileState } from "../types/patient-profile-state";
import patientProfileApi from "../services/patient-profile-services";
import {
  createPatientProfileFromPatientProfileDto,
  PatientProfile,
} from "../types/patient-profile";
import { SaveAvatarRequestPayload } from "../types/save-avatar-request-payload";
import { EditPatientRequestPayload } from "../types/edit-patient-request-payload";
import {
  createPhoneEntryFromDto,
  DeletePhonePayload,
  EditPhonePayload,
  NewPhonePayload,
} from "../types/phone-entry";
import { HttpError } from "@/http/types/http-error.interface";

export interface PatientProfileActions {
  [ActionTypes.FETCH_PATIENT_PROFILE]: (
    context: AvaActionContext<PatientProfileState, AvaStore>,
    patientId: number
  ) => Promise<PatientProfile | null>;
  [ActionTypes.SET_PATIENT_ID_TO_INITIATE_PATIENT_PROFILE]: (
    context: AvaActionContext<PatientProfileState, AvaStore>,
    patientId: number
  ) => Promise<void>;
  [ActionTypes.SAVE_PATIENT_AVATAR]: (
    context: ActionContext<PatientProfileState, AvaStore>,
    payload: SaveAvatarRequestPayload
  ) => Promise<void>;
  [ActionTypes.EDIT_PATIENT_PROFILE]: (
    context: ActionContext<PatientProfileState, AvaStore>,
    payload: EditPatientRequestPayload
  ) => Promise<void>;
  [ActionTypes.ADD_PATIENT_PHONE]: (
    context: ActionContext<PatientProfileState, AvaStore>,
    payload: NewPhonePayload
  ) => Promise<void>;
  [ActionTypes.EDIT_PATIENT_PHONE]: (
    context: ActionContext<PatientProfileState, AvaStore>,
    payload: EditPhonePayload
  ) => Promise<void>;
  [ActionTypes.DELETE_PATIENT_PHONE]: (
    context: ActionContext<PatientProfileState, AvaStore>,
    payload: DeletePhonePayload
  ) => Promise<void>;
}

export const patientProfileActions: PatientProfileActions = {
  async [ActionTypes.FETCH_PATIENT_PROFILE](
    store,
    patientId
  ): Promise<PatientProfile | null> {
    try {
      store.commit(MutationTypes.PATIENT_PROFILE__TOGGLE_LOADING, true);
      const profile = createPatientProfileFromPatientProfileDto(
        await patientProfileApi.getPatientProfile(patientId)
      );
      store.commit(MutationTypes.PATIENT_PROFILE__SET_PATIENT_PROFILE, profile);

      store.commit(MutationTypes.PATIENT_PROFILE__SET_ERROR, null);
      return profile;
    } catch (error) {
      store.commit(
        MutationTypes.PATIENT_PROFILE__SET_ERROR,
        error as HttpError
      );
      return null;
    } finally {
      store.commit(MutationTypes.PATIENT_PROFILE__TOGGLE_LOADING, false);
    }
  },
  async [ActionTypes.SET_PATIENT_ID_TO_INITIATE_PATIENT_PROFILE](
    store,
    patientId
  ) {
    store.commit(
      MutationTypes.PATIENT_PROFILE__SET_PATIENT_ID_TO_INITIATE_PATIENT_PROFILE,
      patientId
    );
  },
  async [ActionTypes.SAVE_PATIENT_AVATAR](store, payload) {
    try {
      store.commit(MutationTypes.PATIENT_PROFILE__TOGGLE_LOADING, true);
      const result = await patientProfileApi.submitPatientAvatar(
        payload.patientId,
        payload.imgData
      );
      store.commit(
        MutationTypes.PATIENT_PROFILE__SET_PATIENT_AVATAR,
        result.data.img
      );
      store.commit(MutationTypes.PATIENT_PROFILE__SET_ERROR, null);
    } catch (error) {
      store.commit(
        MutationTypes.PATIENT_PROFILE__SET_ERROR,
        error as HttpError
      );
    } finally {
      store.commit(MutationTypes.PATIENT_PROFILE__TOGGLE_LOADING, false);
    }
  },
  async [ActionTypes.EDIT_PATIENT_PROFILE](store, payload) {
    try {
      store.commit(MutationTypes.PATIENT_PROFILE__TOGGLE_LOADING, true);
      const result = await patientProfileApi.editPatientProfile(
        payload.patientId,
        payload.data
      );
      store.commit(
        MutationTypes.PATIENT_PROFILE__SET_PATIENT_PROFILE,
        createPatientProfileFromPatientProfileDto(result)
      );
      store.commit(MutationTypes.PATIENT_PROFILE__SET_ERROR, null);
    } catch (error) {
      store.commit(
        MutationTypes.PATIENT_PROFILE__SET_ERROR,
        error as HttpError
      );
    } finally {
      store.commit(MutationTypes.PATIENT_PROFILE__TOGGLE_LOADING, false);
    }
  },
  async [ActionTypes.ADD_PATIENT_PHONE](store, payload) {
    try {
      store.commit(MutationTypes.PATIENT_PROFILE__TOGGLE_LOADING, true);
      const result = await patientProfileApi.addPatientPhone(payload);
      store.commit(
        MutationTypes.PATIENT_PROFILE__SET_PATIENT_PHONES,
        result.data.data.map(createPhoneEntryFromDto)
      );
      store.commit(MutationTypes.PATIENT_PROFILE__SET_ERROR, null);
    } catch (error) {
      store.commit(
        MutationTypes.PATIENT_PROFILE__SET_ERROR,
        error as HttpError
      );
    } finally {
      store.commit(MutationTypes.PATIENT_PROFILE__TOGGLE_LOADING, false);
    }
  },
  async [ActionTypes.EDIT_PATIENT_PHONE](store, payload) {
    try {
      store.commit(MutationTypes.PATIENT_PROFILE__TOGGLE_LOADING, true);
      const result = await patientProfileApi.editPatientPhone(payload);
      store.commit(
        MutationTypes.PATIENT_PROFILE__SET_PATIENT_PHONES,
        result.data.data.map(createPhoneEntryFromDto)
      );
      store.commit(MutationTypes.PATIENT_PROFILE__SET_ERROR, null);
    } catch (error) {
      store.commit(
        MutationTypes.PATIENT_PROFILE__SET_ERROR,
        error as HttpError
      );
    } finally {
      store.commit(MutationTypes.PATIENT_PROFILE__TOGGLE_LOADING, false);
    }
  },
  async [ActionTypes.DELETE_PATIENT_PHONE](store, payload) {
    try {
      store.commit(MutationTypes.PATIENT_PROFILE__TOGGLE_LOADING, true);
      const result = await patientProfileApi.deletePatientPhone(payload);
      store.commit(
        MutationTypes.PATIENT_PROFILE__SET_PATIENT_PHONES,
        result.data.data.map(createPhoneEntryFromDto)
      );
      store.commit(MutationTypes.PATIENT_PROFILE__SET_ERROR, null);
    } catch (error) {
      store.commit(
        MutationTypes.PATIENT_PROFILE__SET_ERROR,
        error as HttpError
      );
    } finally {
      store.commit(MutationTypes.PATIENT_PROFILE__TOGGLE_LOADING, false);
    }
  },
};
