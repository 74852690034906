import { HttpError } from "@/http/types/http-error.interface";

export const createDefaultBiometricSubmissionFormState = () => ({
  formSubmissionSucceeded: null,
  loading: false,
  error: null,
});

export interface BiometricFormState {
  formSubmissionSucceeded: boolean | null;
  loading: boolean;
  error: HttpError | null;
}
