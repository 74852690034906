import { createRouter, createWebHistory } from "vue-router";
import { Routes } from "./types/routes.enum";
import { AvaRouter, RoutePathOptions } from "./types/ava-router.interface";
import { routes } from "./routes/routes";

export const router = ((): AvaRouter => ({
  ...createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
  }),
  handleUnauthorizedStatusError() {
    if (this.currentRoute.value.name !== Routes.preVerifiedInvitation) {
      this.goTo(Routes.login);
    }
  },
  goTo(name: Routes, options?: RoutePathOptions) {
    return this.push({ name, params: options?.params, query: options?.query });
  },
  getFullRoutePath(name: Routes, options?: RoutePathOptions) {
    const path = this.resolve({
      name,
      params: options?.params,
      query: options?.query,
    }).href;
    const origin = window.location.origin;
    return origin + path;
  },
  getDefaultLandingPagePath() {
    return router.resolve({ name: Routes.dashboard }).path;
  },
}))();
