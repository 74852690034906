import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_typography = _resolveComponent("base-typography")!
  const _component_logout_icon = _resolveComponent("logout-icon")!
  const _component_media_query_layout = _resolveComponent("media-query-layout")!

  return (_openBlock(), _createBlock(_component_media_query_layout, null, {
    "extra-small": _withCtx(() => [
      _createVNode(_component_logout_icon)
    ]),
    default: _withCtx(() => [
      _createVNode(_component_base_typography, { bold: "" }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.logoutButtonText), 1)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}