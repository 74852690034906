import { RouteRecordRaw } from "vue-router";
import { Routes } from "@/router/types/routes.enum";
import MainLayout from "@/layouts/page-layouts/main-layout.vue";

const EntryPointPatientChartsPage = () =>
  import(
    /* webpackChunkName: "patient_charts_index_page" */ "@/pages/patient-charts/patient-charts.vue"
  );

const MedicalHistory = () =>
  import(
    /* webpackChunkName: "patient_charts_medical_history_page" */ "@/pages/patient-charts/pages/medical-history/medical-history.vue"
  );

const FamilyHistory = () =>
  import(
    /* webpackChunkName: "patient_charts_family_history_page" */ "@/pages/patient-charts/pages/family-history/family-history.vue"
  );

const SocialHistory = () =>
  import(
    /* webpackChunkName: "patient_charts_social_history_page" */ "@/pages/patient-charts/pages/social-history/social-history.vue"
  );

const AllergyHistory = () =>
  import(
    /* webpackChunkName: "patient_charts_allergy_history_page" */ "@/pages/patient-charts/pages/allergy-history/allergy-history.vue"
  );

const MedicationReview = () =>
  import(
    /* webpackChunkName: "patient_charts_medication_review_page" */ "@/pages/patient-charts/pages/medication-review/medication-review.vue"
  );

const ReferralReview = () =>
  import(
    /* webpackChunkName: "patient_charts_referral_review_page" */ "@/pages/patient-charts/pages/referral-review/referral-review.vue"
  );

const VaccineHistory = () =>
  import(
    /* webpackChunkName: "patient_charts_vaccine_history_page" */ "@/pages/patient-charts/pages/vaccine-history/vaccine-history.vue"
  );

export const patientChartRoute: RouteRecordRaw = {
  path: "/patient-charts",
  component: EntryPointPatientChartsPage,
  name: Routes.patientCharts,
  meta: {
    layout: MainLayout,
    isProtected: true,
  },
  children: [
    {
      path: "medical-history",
      name: Routes.patientChartsMedicalHistory,
      component: MedicalHistory,
      meta: {
        layout: MainLayout,
        isProtected: true,
      },
    },
    {
      path: "family-history",
      name: Routes.patientChartsFamilyHistory,
      component: FamilyHistory,
      meta: {
        layout: MainLayout,
        isProtected: true,
      },
    },
    {
      path: "social-history",
      name: Routes.patientChartsSocialHistory,
      component: SocialHistory,
      meta: {
        layout: MainLayout,
        isProtected: true,
      },
    },
    {
      path: "allergy-history",
      name: Routes.patientChartsAllergyHistory,
      component: AllergyHistory,
      meta: {
        layout: MainLayout,
        isProtected: true,
      },
    },
    {
      path: "medication-review",
      name: Routes.patientChartsMedicationReview,
      component: MedicationReview,
      meta: {
        layout: MainLayout,
        isProtected: true,
      },
    },
    {
      path: "referral-review",
      name: Routes.patientChartsReferralReview,
      component: ReferralReview,
      meta: {
        layout: MainLayout,
        isProtected: true,
      },
    },
    {
      path: "vaccine-history",
      name: Routes.patientChartsVaccineHistory,
      component: VaccineHistory,
      meta: {
        layout: MainLayout,
        isProtected: true,
      },
    },
  ],
};
