
import { computed, defineComponent, PropType, toRefs } from "vue";
import BaseTypography from "@/ui-library/base-typography/base-typography.vue";
import CloseIcon from "@/ui-library/icons/close-icon.vue";
import BaseButtonIcon from "@/ui-library/base-button/base-button-icon.vue";
import { ToastMessage, isToastMessageType } from "../types/toast-message";

export default defineComponent({
  emits: {
    removeMessage: (msg: string) => Boolean(msg),
  },
  props: {
    toastMessage: {
      type: Object as PropType<ToastMessage>,
      required: true,
    },
  },
  setup(props) {
    const { toastMessage } = toRefs(props);
    return {
      toastMessageClasses: computed(() => ({
        "toast-message-error": isToastMessageType(
          toastMessage.value,
          "warning"
        ),
      })),
    };
  },
  components: {
    BaseTypography,
    CloseIcon,
    BaseButtonIcon,
  },
});
