
import { defineComponent } from "vue";
import { Routes } from "@/router";
import BloodPressureIcon from "@/ui-library/icons/blood-pressure-icon.vue";
import HeartRateIcon from "@/ui-library/icons/heart-rate-icon.vue";
import BloodGlucoseIcon from "@/ui-library/icons/blood-glucose-icon.vue";
import WeightIcon from "@/ui-library/icons/weight-icon.vue";
import HeightIcon from "@/ui-library/icons/height-icon.vue";
import SideMenuItem from "./side-menu-item.vue";

export default defineComponent({
  emits: {
    goToRoute: (route: Routes) => Boolean(route),
  },
  components: {
    SideMenuItem,
    BloodPressureIcon,
    HeartRateIcon,
    BloodGlucoseIcon,
    WeightIcon,
    HeightIcon,
  },
  setup(props, context) {
    const goToMenuItem = (route: Routes) => {
      context.emit("goToRoute", route);
    };
    return {
      bloodPressureTitle: "Blood Pressure",
      heartRateTitle: "Heart Rate",
      bloodGlucoseTitle: "Blood Glucose",
      weightTitle: "Weight",
      heightTitle: "Height",
      Routes,
      goToMenuItem,
    };
  },
});
