import { RouteRecordRaw } from "vue-router";
import SingleFocusLayout from "@/layouts/page-layouts/single-focus-layout.vue";
import { Routes } from "@/router";

const ReportIssues = () =>
  import(
    /* webpackChunkName: "the_report_issues_page" */ "@/pages/report-issue/report-issue.vue"
  );

export const reportIssuesRoute: RouteRecordRaw = {
  path: "/report-issues",
  name: Routes.reportIssues,
  component: ReportIssues,
  meta: {
    layout: SingleFocusLayout,
  },
};
