import { ActionTypes } from "@/store/constants/action-types";
import { MutationTypes } from "@/store/constants/mutation-types";
import { AvaStore } from "@/store/types/store.type";
import { ActionContext } from "vuex";
import { TestResultLineGraphDetailsItemRequestPayload } from "../types/test-result-graph-details-item";
import { createTestResultItemFromDto } from "../types/test-result-item";
import {
  createTestResultDetailFromDto,
  TestResultItemDetailRequestPayload,
} from "../types/test-result-item-detail";
import { TestResultsState } from "../types/test-results-state";
import testResultsApi from "./services/test-results-service";

export interface TestResultsActions {
  [ActionTypes.FETCH_TEST_RESULTS_LIST]: (
    context: ActionContext<TestResultsState, AvaStore>,
    patientId: number
  ) => Promise<void>;
  [ActionTypes.FETCH_TEST_RESULT_DETAILS]: (
    context: ActionContext<TestResultsState, AvaStore>,
    payload: TestResultItemDetailRequestPayload
  ) => Promise<void>;
  [ActionTypes.FETCH_TEST_RESULT_LINE_GRAPH_DATA]: (
    context: ActionContext<TestResultsState, AvaStore>,
    payload: TestResultLineGraphDetailsItemRequestPayload
  ) => Promise<{ isSuccessful: boolean } | undefined>;
}

export const testResultsActions: TestResultsActions = {
  async [ActionTypes.FETCH_TEST_RESULTS_LIST](store, patientId) {
    try {
      store.commit(MutationTypes.TEST_RESULTS__TOGGLE_LOADING, true);
      const results = await testResultsApi.fetchTestResults(patientId);
      store.commit(
        MutationTypes.TEST_RESULTS__SET_RESULTS_LIST,
        results.data.data.map(createTestResultItemFromDto)
      );
    } catch (error) {
      store.commit(MutationTypes.TEST_RESULTS__SET_ERROR, error);
    } finally {
      store.commit(MutationTypes.TEST_RESULTS__TOGGLE_LOADING, false);
    }
  },
  async [ActionTypes.FETCH_TEST_RESULT_DETAILS](store, payload) {
    try {
      store.commit(MutationTypes.TEST_RESULTS__TOGGLE_LOADING, true);
      const results = await testResultsApi.fetchTestResultDetails(payload);
      store.commit(
        MutationTypes.TEST_RESULTS__SET_SELECTED_TEST_RESULT,
        createTestResultItemFromDto(results.data.data)
      );
      store.commit(
        MutationTypes.TEST_RESULTS__SET_TEST_RESULT_DETAILS,
        results.data.included.map(createTestResultDetailFromDto)
      );
    } catch (error) {
      store.commit(MutationTypes.TEST_RESULTS__SET_ERROR, error);
    } finally {
      store.commit(MutationTypes.TEST_RESULTS__TOGGLE_LOADING, false);
    }
  },
  async [ActionTypes.FETCH_TEST_RESULT_LINE_GRAPH_DATA](store, payload) {
    try {
      store.commit(MutationTypes.TEST_RESULTS__TOGGLE_LOADING, true);
      const results = await testResultsApi.fetchTestResultLineGraph(payload);
      store.commit(
        MutationTypes.TEST_RESULTS__SET_TEST_RESULT_LINE_GRAPH_DATA,
        results.data
      );
      store.commit(
        MutationTypes.TEST_RESULTS__SET_TEST_RESULT_LINE_GRAPH_TITLE,
        payload.graphTitle
      );
      return { isSuccessful: true };
    } catch (error) {
      store.commit(MutationTypes.TEST_RESULTS__SET_ERROR, error);
    } finally {
      store.commit(MutationTypes.TEST_RESULTS__TOGGLE_LOADING, false);
    }
  },
};
