import { Dependant } from "./types/dependant";
import { ManageDependantsState } from "./types/manage-dependants-state";

export interface ManageDependantsGetters {
  getDependants: (manageDependantsState: ManageDependantsState) => Dependant[];
  getConfirmedDependantsWithPatientId: (
    manageDependantsState: ManageDependantsState
  ) => Dependant[];
  isDependantSuccessfullyAdded: (
    manageDependantsState: ManageDependantsState
  ) => boolean;
}

export const manageDependantsGetters: ManageDependantsGetters = {
  getDependants(manageDependantsState) {
    return manageDependantsState.dependants || [];
  },
  getConfirmedDependantsWithPatientId(manageDependantsState) {
    return (
      manageDependantsState.dependants.filter(
        (dependant) => dependant.confirmed && dependant.patientId
      ) || []
    );
  },
  isDependantSuccessfullyAdded(manageDependantsState) {
    return manageDependantsState.showSuccessfullyAddedDependant;
  },
};
