
import { computed, defineComponent } from "vue";
import BaseTransitionFade from "@/ui-library/base-transition/base-transition-fade.vue";

export default defineComponent({
  emits: ["backgroundClicked"],
  inheritAttrs: false,
  components: {
    BaseTransitionFade,
  },
  props: {
    showBackground: {
      type: Boolean,
      default: false,
    },
    darkerBackground: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const backgroundClasses = computed(() => ({
      "darker-background": props.darkerBackground,
    }));
    return {
      backgroundClasses,
    };
  },
});
