import { RouteRecordRaw } from "vue-router";
import { Routes } from "@/router";

const EntryPointVideoLinkPage = () =>
  import(
    /* webpackChunkName: "video_link_page" */ "@/pages/video-link/video-link.vue"
  );

export const videoLinkRoute: RouteRecordRaw = {
  path: "/vl",
  component: EntryPointVideoLinkPage,
  name: Routes.videoLink,
  meta: {
    isProtected: false,
  },
};
