import { LocalStorageItems } from "./auth-local-storage.enums";

export function getTwoFactorAuthenticationStatusFromLocalMachine(): boolean {
  return Boolean(
    localStorage.getItem(
      LocalStorageItems.hasCompletedTwoFactorAuthenticationAtLogin || ""
    )
  );
}
export function setTwoFactorAuthenticationStatusOnLocalMachine(
  status: boolean
): void {
  return localStorage.setItem(
    LocalStorageItems.hasCompletedTwoFactorAuthenticationAtLogin,
    status ? "true" : ""
  );
}
export function clearLocalStorageAuthItems() {
  localStorage.setItem(LocalStorageItems.patientIdLastRetrieved, "");
  localStorage.setItem(LocalStorageItems.patientUserId, "");
  localStorage.setItem(LocalStorageItems.jwt, "");
  localStorage.setItem(
    LocalStorageItems.hasCompletedTwoFactorAuthenticationAtLogin,
    ""
  );
}

export function reloadWindow() {
  window.location.reload();
}
