
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    iconHtml: {
      type: String,
      required: true,
    },
    large: {
      type: Boolean,
      required: false,
    },
    extraLarge: {
      type: Boolean,
      required: false,
    },
    lightened: {
      type: Boolean,
      required: false,
    },
  },
  setup(props) {
    return {
      iconClasses: {
        large: props.large,
        lightened: props.lightened,
        extraLarge: props.extraLarge,
      },
    };
  },
});
