export function createAddDependantFormRequestDto(
  payload: AddDependantFormPayload
): AddDependantFormRequestDto {
  return {
    patient_user_dependent: {
      nickname: payload.name,
      healthcare_province: payload.province,
      healthcare_number: payload.healthNumber,
    },
  };
}

export interface AddDependantFormRequestDto {
  patient_user_dependent: {
    nickname: string;
    healthcare_province: string;
    healthcare_number: string;
  };
}
export interface AddDependantFormPayload {
  name: string;
  province: string;
  healthNumber: string;
}

export interface AddDependantActionPayload extends AddDependantFormPayload {
  patientUserId: number;
}
