import { AvaModule } from "@/store/modules/types/ava-module.interface";
import { RootState } from "@/store/types/root-state.type";
import {
  BiometricGraphState,
  createDefaultBiometricGraphState,
} from "../types/biometric-graph/biometric-graph-state";
import {
  BiometricGraphActions,
  biometricGraphActions,
} from "./biometric-graph-actions";
import {
  biometricGraphGetters,
  BiometricGraphGetters,
} from "./biometric-graph-getters";
import {
  BiometricGraphMutations,
  biometricGraphMutations,
} from "./biometric-graph-mutations";

export const BiometricGraphStoreModule: AvaModule<
  BiometricGraphState,
  RootState,
  BiometricGraphActions,
  BiometricGraphMutations,
  BiometricGraphGetters
> = {
  state(): BiometricGraphState {
    return createDefaultBiometricGraphState();
  },
  mutations: biometricGraphMutations,
  getters: biometricGraphGetters,
  actions: biometricGraphActions,
};
