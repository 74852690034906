import uniqBy from "lodash/uniqBy";
import { ToastMessage } from "../types/toast-message";
import { ToastMessagesState } from "../types/toast-messages-state.interface";
export interface ToastMessagesGetters {
  getToastMessages: (state: ToastMessagesState) => void;
}

export const toastMessagesGetters = {
  getToastMessages(state: ToastMessagesState): ToastMessage[] {
    return uniqBy(state.toastMessages, "message");
  },
};
