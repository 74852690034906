import { defineStore } from "pinia";
import { store as avaVuexStore } from "./store";
import { ActionTypes } from "./constants/action-types";
import { PatientProfile } from "../features/patient-profile/types/patient-profile";
import { LocalStorageItems } from "../types/auth-local-storage/auth-local-storage.enums";

export const usePatientProfileStore = defineStore("PatientProfileStore", {
  state: (): PatientProfile => {
    return {
      id: null,
      firstName: null,
      middleMame: null,
      lastName: null,
      address1: null,
      address2: null,
      city: null,
      province: null,
      country: null,
      postalCode: null,
      email: null,
      phonePrimary: null,
      phoneSecondary: null,
      textPhonePrimary: null,
      textPhoneSecondary: null,
      sex: null,
      dateOfBirth: null,
      emergencyPhone: null,
      emergencyName: null,
      emergencyRelationship: null,
      primaryPhysicianConfirmed: null,
      primaryClinicConfirmed: null,
      appointmentReminders: null,
      smsAppointmentReminders: null,
      primaryPhysicianName: null,
      primaryClinicName: null,
      base64Avatar: null,
      phones: [],
    };
  },
  getters: {
    getPatientId: (state) => state.id,
  },
  actions: {
    setPatientId(patientId: number | null) {
      this.id = patientId;
    },
    async fetchPatientProfile(patientId: number) {
      const profile = await avaVuexStore.dispatch(
        ActionTypes.FETCH_PATIENT_PROFILE,
        patientId
      );
      if (profile) {
        this.$state = profile;
        this.setPatientIdLastRetrievedOnLocalStorage(patientId);
      }
    },
    setPatientIdLastRetrievedOnLocalStorage(patientId: number) {
      localStorage.setItem(
        LocalStorageItems.patientIdLastRetrieved,
        patientId?.toString() || ""
      );
    },
  },
});
