import { computed, watchEffect } from "vue";
import { usePatientConnectClinicStore } from "../../patient-connect-clinics/store/patient-connect-clinic-store";
import { useSessionStore } from "../../../store/session-store";

export function useFetchPatientConnectClinics() {
  const patientConnectClinicStore = usePatientConnectClinicStore();
  const sessionStore = useSessionStore();
  const patientId = computed(() => sessionStore.getSessionOwnerPatientId);
  watchEffect(() => {
    if (sessionStore.getIsSessionFetched) {
      if (patientId.value) {
        patientConnectClinicStore.fetchPatientConnectClinics(patientId.value);
      }
    }
  });
}
