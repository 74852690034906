import { renderSlot as _renderSlot, withModifiers as _withModifiers, mergeProps as _mergeProps, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, Teleport as _Teleport, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_transition_enter_left = _resolveComponent("base-transition-enter-left")!
  const _component_base_transition_fade = _resolveComponent("base-transition-fade")!

  return (_openBlock(), _createBlock(_Teleport, { to: "body" }, [
    _createVNode(_component_base_transition_fade, null, {
      default: _withCtx(() => [
        _withDirectives(_createElementVNode("div", {
          class: "menu-background",
          onClick: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.toggleMenu && _ctx.toggleMenu(...args)), ["prevent"]))
        }, [
          _createVNode(_component_base_transition_enter_left, null, {
            default: _withCtx(() => [
              (_ctx.isOpen)
                ? (_openBlock(), _createElementBlock("div", _mergeProps({
                    key: 0,
                    class: "menu"
                  }, _ctx.$attrs, {
                    onClick: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["stop"]))
                  }), [
                    _renderSlot(_ctx.$slots, "default", {}, undefined, true)
                  ], 16))
                : _createCommentVNode("", true)
            ]),
            _: 3
          })
        ], 512), [
          [_vShow, _ctx.isOpen]
        ])
      ]),
      _: 3
    })
  ]))
}