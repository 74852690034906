
import RootTheme from "@/themes/root-theme.vue";
import { defineComponent, onMounted, onUnmounted } from "vue";
import ToastMessages from "./features/toast-messages/toast-messages.vue";
import RouteProtector from "./features/route-protector/route-protector.vue";
import { useSetViewportHeight } from "@/composition/use-set-viewport-height";
import AppPageTransition from "./components/app-page-transition.vue";
import BaseLoader from "./ui-library/base-loader/base-loader.vue";
import LayoutProvidedByRoute from "./layouts/page-layouts/layout-provided-by-route.vue";
import appVersion from "../package.json";
import PatientConnectClinicsPopup from "./features/patient-connect-clinics/patient-connect-clinics-popup.vue";
import { updateMetaTag } from "./utils/meta-tag.functions";

export default defineComponent({
  setup() {
    console.log("Current Version: ", appVersion.version);

    useSetViewportHeight();

    // If href contains cloudfront.net, redirect to www.avaconnect.ca
    if (window.location.href.includes("cloudfront.net")) {
      window.location.replace("https://www.avaconnect.ca");
    }

    onMounted(updateMetaTag);
    onUnmounted(() => {
      // Clean up the meta tag when component is destroyed or not needed
      document.querySelector('meta[name="robots"]')?.remove();
    });

    return {};
  },
  components: {
    RootTheme,
    ToastMessages,
    RouteProtector,
    AppPageTransition,
    BaseLoader,
    LayoutProvidedByRoute,
    PatientConnectClinicsPopup,
  },
});
