
import { defineComponent } from "vue";
import { Routes } from "@/router";
import BaseButton from "@/ui-library/base-button/base-button.vue";
import BaseTypography from "@/ui-library/base-typography/base-typography.vue";

export default defineComponent({
  components: { BaseButton, BaseTypography },
  setup() {
    return {
      techSupportButtonTitle: "Tech support",
      reportIssuesRouteName: Routes.reportIssues,
    };
  },
});
