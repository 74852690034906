import { RootState } from "@/store/types/root-state.type";
import { AvaModule } from "@/store/modules/types/ava-module.interface";
import {
  ManageDependantsMutations,
  manageDependantsMutations,
} from "./manage-dependants-mutations";
import {
  ManageDependantsState,
  createInitialManageDependantsState,
} from "./types/manage-dependants-state";
import {
  manageDependantsActions,
  ManageDependantsActions,
} from "./manage-dependants-actions";
import {
  manageDependantsGetters,
  ManageDependantsGetters,
} from "./manage-dependants-getters";

export const ManageDependantsStoreModule: AvaModule<
  ManageDependantsState,
  RootState,
  ManageDependantsActions,
  ManageDependantsMutations,
  ManageDependantsGetters
> = {
  state(): ManageDependantsState {
    return createInitialManageDependantsState();
  },
  mutations: manageDependantsMutations,
  actions: manageDependantsActions,
  getters: manageDependantsGetters,
};
