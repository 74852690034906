
import { DefineComponent, defineComponent } from "vue";

export default defineComponent({
  setup() {
    /**
     Use span as fallback because it is inline display and 
     has no height. If you use any other block tag (div) 
     you will need to add height: 100% in order to perserve 
     page height throughout app 
    */
    const getDefaultLayout = (
      layout?: DefineComponent
    ): DefineComponent | "span" => layout || "span";
    return {
      getDefaultLayout,
    };
  },
});
