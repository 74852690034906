import { withModifiers as _withModifiers, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_transition_fade = _resolveComponent("base-transition-fade")!

  return (_openBlock(), _createBlock(_component_base_transition_fade, null, {
    default: _withCtx(() => [
      (_ctx.showBackground)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: _normalizeClass(["overlay-background", _ctx.backgroundClasses]),
            onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.$emit('backgroundClicked')), ["stop"]))
          }, null, 2))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}