export enum MutationTypes {
  AUTH__SET_SESSION = "AUTH__SET_SESSION",
  AUTH__CLEAR_SESSION = "AUTH__CLEAR_SESSION",
  AUTH__TOGGLE_LOADING = "AUTH__TOGGLE_LOADING",
  AUTH__SET_ERROR = "AUTH__SET_ERROR",
  AUTH__SET_EMAIL_CONFIRMED = "AUTH__SET_EMAIL_CONFIRMED",
  AUTH__SET_PHONE_CONFIRMED = "AUTH__SET_PHONE_CONFIRMED",
  AUTH__SET_IS_AUTHORIZED_T0_EDIT_ACCOUNT_SETTINGS = "AUTH__SET_IS_AUTHORIZED_T0_EDIT_ACCOUNT_SETTINGS",
  AUTH__SET_TWO_FACTOR_QR_CODE_URI = "AUTH__SET_TWO_FACTOR_QR_CODE_URI",
  AUTH__SET_IS_TWO_FACTOR_AUTHENTICATION_ENABLED = "AUTH__SET_IS_TWO_FACTOR_AUTHENTICATION_ENABLED",
  AUTH__SET_IS_TWO_FACTOR_AUTHENTICATED = "AUTH__SET_IS_TWO_FACTOR_AUTHENTICATED",
  AUTH__SET_VALID_JWT_TOKEN_IS_FETCHED = "AUTH__SET_VALID_JWT_TOKEN_IS_FETCHED",
  AUTH__UPDATE_SESSION = "AUTH__UPDATE_SESSION",
  AUTH__UPDATE_JWT_EXPIRY_MS_SINCE_EPOCH = "AUTH__UPDATE_JWT_EXPIRY_MS_SINCE_EPOCH",
  AUTH__UPDATE_LANDING_PAGE = "AUTH__UPDATE_LANDING_PAGE",
  PATIENT_PROFILE__TOGGLE_LOADING = "PATIENT_PROFILE__TOGGLE_LOADING",
  PATIENT_PROFILE__SET_ERROR = "PATIENT_PROFILE__SET_ERROR",
  PATIENT_PROFILE__SET_PATIENT_PROFILE = "PATIENT_PROFILE__SET_PATIENT_PROFILE",
  PATIENT_PROFILE__SET_PATIENT_AVATAR = "PATIENT_PROFILE__SET_PATIENT_AVATAR",
  PATIENT_PROFILE__SET_PATIENT_PHONES = "PATIENT_PROFILE__SET_PATIENT_PHONES",
  PATIENT_PROFILE__SET_PATIENT_ID_TO_INITIATE_PATIENT_PROFILE = "PATIENT_PROFILE__SET_PATIENT_ID_TO_INITIATE_PATIENT_PROFILE",
  DASHBOARD__SET_REPORT = "DASHBOARD__SET_REPORT",
  DASHBOARD__TOGGLE_LOADING = "DASHBOARD__TOGGLE_LOADING",
  DASHBOARD__SET_ERROR = "DASHBOARD_SET_ERROR",
  TOAST__ADD_MESSAGE = "TOAST__ADD_MESSAGE",
  TOAST__REMOVE_MESSAGES = "TOAST__REMOVE_MESSAGES",
  TOAST__REMOVE_SINGLE_MESSAGE = "TOAST__REMOVE_SINGLE_MESSAGE",
  MESSAGES__SET_CONVERSATION_SUMMARY = "MESSAGES__SET_CONVERSATION_SUMMARY",
  MESSAGES__TOGGLE_LOADING = "MESSAGES__TOGGLE_LOADING",
  MESSAGES__SET_ERROR = "MESSAGES__SET_ERROR",
  MESSAGES__SET_CONVERSATION_DETAILS = "MESSAGES__SET_CONVERSATION_DETAILS",
  MESSAGES__SELECT_CONVERSATION = "MESSAGES__SELECT_CONVERSATION",
  MESSAGES__ADD_A_CONVERSATION_MESSAGE = "MESSAGES__ADD_A_CONVERSATION_MESSAGE",
  MESSAGES__SET_MESSAGEABLE_CLINICS = "MESSAGES__SET_MESSAGEABLE_CLINICS",
  APPOINTMENTS__TOGGLE_LOADING = "APPOINTMENTS__TOGGLE_LOADING",
  APPOINTMENTS__SET_ERROR = "APPOINTMENTS__SET_ERROR",
  APPOINTMENTS__SET_BOOKED_APPOINTMENTS = "APPOINTMENTS__SET_BOOKED_APPOINTMENTS",
  APPOINTMENTS__CANCEL_APPOINTMENT = "APPOINTMENTS__CANCEL_APPOINTMENT",
  MANAGE_CLINICS__SET_AUTHORIZED_CLINICS = "MANAGE_CLINICS__SET_AUTHORIZED_CLINICS",
  MANAGE_CLINICS__SET_NEW_AUTHORIZED_CLINIC = "MANAGE_CLINICS__SET_NEW_AUTHORIZED_CLINIC",
  MANAGE_CLINICS__DELETE_CLINIC = "MANAGE_CLINICS__DELETE_CLINIC",
  MANAGE_CLINICS__TOGGLE_LOADING = "MANAGE_CLINICS__TOGGLE_LOADING",
  MANAGE_CLINICS__SET_ERROR = "MANAGE_CLINICS__SET_ERROR",
  MANAGE_CLINICS__SET_SEARCHED_CLINICS = "MANAGE_CLINICS__SET_SEARCHED_CLINICS",
  MANAGE_DEPENDANTS__TOGGLE_LOADING = "MANAGE_DEPENDANTS__TOGGLE_LOADING",
  MANAGE_DEPENDANTS__SET_ERROR = "MANAGE_DEPENDANTS__SET_ERROR",
  MANAGE_DEPENDANTS__SET_DEPENDANTS = "MANAGE_DEPENDANTS__SET_DEPENDANTS",
  MANAGE_DEPENDANTS__ADD_DEPENDANT = "MANAGE_DEPENDANTS__ADD_DEPENDANT",
  MANAGE_DEPENDANTS__TOGGLE_SUCCESSFULLY_ADDED_DEPENDANT = "MANAGE_DEPENDANTS__TOGGLE_SUCCESSFULLY_ADDED_DEPENDANT",
  MANAGE_DEPENDANTS__DELETE_DEPENDANT = "MANAGE_DEPENDANTS__DELETE_DEPENDANT",
  PATIENT_CHARTS__TOGGLE_LOADING = "PATIENT_CHARTS__TOGGLE_LOADING",
  PATIENT_CHARTS__SET_ERROR = "PATIENT_CHARTS__SET_ERROR",
  PATIENT_CHARTS__ADD_MEDICAL_HISTORY = "PATIENT_CHARTS__ADD_MEDICAL_HISTORY",
  PATIENT_CHARTS__SET_MEDICAL_HISTORY = "PATIENT_CHARTS__SET_MEDICAL_HISTORY",
  PATIENT_CHARTS__ADD_FAMILY_HISTORY = "PATIENT_CHARTS__ADD_FAMILY_HISTORY",
  PATIENT_CHARTS__SET_FAMILY_HISTORY = "PATIENT_CHARTS__SET_FAMILY_HISTORY",
  PATIENT_CHARTS__REPLACE_FAMILY_HISTORY = "PATIENT_CHARTS__REPLACE_FAMILY_HISTORY",
  PATIENT_CHARTS__ADD_SOCIAL_HISTORY = "PATIENT_CHARTS__ADD_SOCIAL_HISTORY",
  PATIENT_CHARTS__SET_SOCIAL_HISTORY = "PATIENT_CHARTS__SET_SOCIAL_HISTORY",
  PATIENT_CHARTS__REPLACE_SOCIAL_HISTORY = "PATIENT_CHARTS__REPLACE_SOCIAL_HISTORY",
  PATIENT_CHARTS__ADD_ALLERGY_HISTORY = "PATIENT_CHARTS__ADD_ALLERGY_HISTORY",
  PATIENT_CHARTS__SET_ALLERGY_HISTORY = "PATIENT_CHARTS__SET_ALLERGY_HISTORY",
  PATIENT_CHARTS__REPLACE_ALLERGY_HISTORY = "PATIENT_CHARTS__REPLACE_ALLERGY_HISTORY",
  PATIENT_CHARTS__SET_MEDICATION_REVIEW = "PATIENT_CHARTS__SET_MEDICATION_REVIEW",
  PATIENT_CHARTS__SET_REFERRAL_REVIEW = "PATIENT_CHARTS__SET_REFERRAL_REVIEW",
  PATIENT_CHARTS__ADD_VACCINE_HISTORY = "PATIENT_CHARTS__ADD_VACCINE_HISTORY",
  PATIENT_CHARTS__SET_VACCINE_HISTORY = "PATIENT_CHARTS__SET_VACCINE_HISTORY",
  PATIENT_CHARTS__REPLACE_VACCINE_HISTORY = "PATIENT_CHARTS__REPLACE_VACCINE_HISTORY",
  BIOMETRIC_FORM__ADD_BLOOD_PRESSURE_HEART_RATE = "BIOMETRIC_FORM__ADD_BLOOD_PRESSURE_HEART_RATE",
  BIOMETRIC_FORM__SET_ERROR = "BIOMETRIC_FORM__SET_ERROR",
  BIOMETRIC_FORM__TOGGLE_LOADING = "BIOMETRIC_FORM__TOGGLE_LOADING",
  BIOMETRIC_FORM__FORM_SUBMISSION_SUCCEEDED = "BIOMETRIC_FORM__FORM_SUBMISSION_SUCCEEDED",
  BIOMETRIC_FORM__RESET_FORM_SUBMISSION_SUCCEEDED = "BIOMETRIC_FORM__RESET_FORM_SUBMISSION_SUCCEEDED",
  BIOMETRIC_GRAPH__SET_GRAPH_DETAILS = "BIOMETRIC_GRAPH__SET_GRAPH_DETAILS",
  BIOMETRIC_GRAPH__SET_ERROR = "BIOMETRIC_GRAPH__SET_ERROR",
  BIOMETRIC_GRAPH__TOGGLE_LOADING = "BIOMETRIC_GRAPH__TOGGLE_LOADING",
  TEST_RESULTS__SET_RESULTS_LIST = "TEST_RESULTS__SET_RESULTS_LIST",
  TEST_RESULTS__SET_ERROR = "TEST_RESULTS__SET_ERROR",
  TEST_RESULTS__TOGGLE_LOADING = "TEST_RESULTS__TOGGLE_LOADING",
  TEST_RESULTS__SET_TEST_RESULT_DETAILS = "TEST_RESULTS__SET_TEST_RESULT_DETAILS",
  TEST_RESULTS__SET_SELECTED_TEST_RESULT = "TEST_RESULTS__SET_SELECTED_TEST_RESULT",
  TEST_RESULTS__SET_TEST_RESULT_LINE_GRAPH_DATA = "TEST_RESULTS__SET_TEST_RESULT_LINE_GRAPH_DATA",
  TEST_RESULTS__SET_TEST_RESULT_LINE_GRAPH_TITLE = "TEST_RESULTS__SET_TEST_RESULT_LINE_GRAPH_TITLE",
  VIDEO_LINK__SET_ERROR = "VIDEO_LINK__SET_ERROR",
  VIDEO_LINK__TOGGLE_LOADING = "VIDEO_LINK__TOGGLE_LOADING",
  VIDEO_LINK__SET_INITIALIZATION = "VIDEO_LINK__SET_INITIALIZATION",
  VIDEO_LINK__SET_CONNECTION_TOKEN = "VIDEO_LINK__SET_CONNECTION_TOKEN",
}
