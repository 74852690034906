import http from "@/http/http";
import { AxiosPromise } from "axios";
import {
  BiometricGraphRequestPayload,
  createParametersForBiometricGraphRequest,
} from "../../types/biometric-graph/biometric-graph-request-payload";

function fetchBiometric(
  payload: BiometricGraphRequestPayload
): AxiosPromise<BiometricGraphRequestPayload> {
  const params = createParametersForBiometricGraphRequest(payload);
  return http.get(`/patients/${payload.patientId}/vital_graphs`, { params });
}

export default {
  fetchBiometric,
};
