import { AvaModule } from "@/store/modules/types/ava-module.interface";
import { RootState } from "@/store/types/root-state.type";
import {
  appointmentsActions,
  AppointmentsActions,
} from "./appointments-actions";
import {
  appointmentsGetters,
  AppointmentsGetters,
} from "./appointments-getters";
import {
  AppointmentsMutations,
  appointmentsMutations,
} from "./appointments-mutations";
import {
  AppointmentsState,
  createDefaultAppointmentsState,
} from "../types/appointments-state";

export const AppointmentsStoreModule: AvaModule<
  AppointmentsState,
  RootState,
  AppointmentsActions,
  AppointmentsMutations,
  AppointmentsGetters
> = {
  state() {
    return createDefaultAppointmentsState();
  },
  mutations: appointmentsMutations,
  actions: appointmentsActions,
  getters: appointmentsGetters,
};
