import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_toolbar_side_menu = _resolveComponent("toolbar-side-menu")!
  const _component_patient_profile_name_and_dependant_list = _resolveComponent("patient-profile-name-and-dependant-list")!
  const _component_logout_button_content = _resolveComponent("logout-button-content")!
  const _component_toolbar_row_button = _resolveComponent("toolbar-row-button")!
  const _component_confirm_dependant_selection_success_modal = _resolveComponent("confirm-dependant-selection-success-modal")!
  const _component_toolbar_layout = _resolveComponent("toolbar-layout")!

  return (_openBlock(), _createBlock(_component_toolbar_layout, { onLogoClicked: _ctx.goToDashboard }, {
    "left-side": _withCtx(() => [
      _createVNode(_component_toolbar_side_menu, { onLogout: _ctx.logout }, null, 8, ["onLogout"])
    ]),
    "right-side": _withCtx(() => [
      _createVNode(_component_patient_profile_name_and_dependant_list, {
        dependants: _ctx.dependants,
        isCurrentlyActingCaregiver: _ctx.isActingAsCaregiver,
        patientProfileName: _ctx.patientName,
        onGoToManageDependants: _ctx.goToManageDependants,
        onChooseDependant: _ctx.chooseDependant,
        onStopCaretakingMode: _ctx.stopCaretakingMode
      }, null, 8, ["dependants", "isCurrentlyActingCaregiver", "patientProfileName", "onGoToManageDependants", "onChooseDependant", "onStopCaretakingMode"]),
      _createVNode(_component_toolbar_row_button, { onClick: _ctx.logout }, {
        default: _withCtx(() => [
          _createVNode(_component_logout_button_content)
        ]),
        _: 1
      }, 8, ["onClick"])
    ]),
    default: _withCtx(() => [
      _createVNode(_component_confirm_dependant_selection_success_modal, {
        patientName: _ctx.patientName,
        isOpen: _ctx.isConfirmDependantSelectionModalOpen,
        onClose: _ctx.toggleConfirmDependantSelectionModal,
        onGoToDashboard: _ctx.goToDashboardAndCloseModal
      }, null, 8, ["patientName", "isOpen", "onClose", "onGoToDashboard"])
    ]),
    _: 1
  }, 8, ["onLogoClicked"]))
}