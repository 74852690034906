
import { defineComponent } from "vue";
export default defineComponent({
  props: {
    outlined: {
      type: Boolean,
      default: false,
    },
    grayedOut: {
      type: Boolean,
      default: false,
    },
    selectedOutline: {
      type: Boolean,
      default: false,
    },
    clickable: {
      type: Boolean,
      default: true,
    },
    showSeparationBorders: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    classes(): object {
      return {
        "base-list-item-outlined": this.outlined,
        "base-list-item-grayed-out": this.grayedOut,
        "base-list-item-selected-outline": this.selectedOutline,
        "separation-border": this.showSeparationBorders,
        "ava-clickable": this.clickable,
        faded: !this.clickable,
      };
    },
  },
});
