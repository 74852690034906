import { AvaModule } from "@/store/modules/types/ava-module.interface";
import { RootState } from "@/store/types/root-state.type";
import {
  BiometricFormState,
  createDefaultBiometricSubmissionFormState,
} from "../types/biometric-form-state";
import {
  biometricFormActions,
  BiometricFormActions,
} from "./biometric-form-actions";
import {
  biometricFormGetters,
  BiometricFormGetters,
} from "./biometric-form-getters";
import {
  BiometricFormMutations,
  biometricFormMutations,
} from "./biometric-form-mutations";

export const BiometricFormStoreModule: AvaModule<
  BiometricFormState,
  RootState,
  BiometricFormActions,
  BiometricFormMutations,
  BiometricFormGetters
> = {
  state(): BiometricFormState {
    return createDefaultBiometricSubmissionFormState();
  },
  mutations: biometricFormMutations,
  getters: biometricFormGetters,
  actions: biometricFormActions,
};
