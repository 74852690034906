import { RootState } from "@/store/types/root-state.type";
import { AvaModule } from "@/store/modules/types/ava-module.interface";
import {
  createInitialManageClinicState,
  ManageClinicsState,
} from "./types/manage-clinics-state";
import {
  ManageClinicsGetters,
  manageClinicsGetters,
} from "./manage-clinics-getters";
import {
  manageClinicsActions,
  ManageClinicsActions,
} from "./manage-clinics-actions";
import {
  ManageClinicsMutations,
  manageClinicsMutations,
} from "./manage-clinics-mutations";

export const ManageClinicsStoreModule: AvaModule<
  ManageClinicsState,
  RootState,
  ManageClinicsActions,
  ManageClinicsMutations,
  ManageClinicsGetters
> = {
  state(): ManageClinicsState {
    return createInitialManageClinicState();
  },
  mutations: manageClinicsMutations,
  actions: manageClinicsActions,
  getters: manageClinicsGetters,
};
