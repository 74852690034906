
import { computed, defineComponent } from "vue";
import BaseModal from "@/ui-library/base-modal/base-modal.vue";
import BaseButton from "@/ui-library/base-button/base-button.vue";

export default defineComponent({
  components: { BaseModal, BaseButton },
  emits: ["close", "goToDashboard"],
  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
    patientName: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const modalTitle = computed(
      () => `You are now representing ${props.patientName}`
    );

    return {
      modalTitle,
    };
  },
});
