import { ActionTypes } from "@/store/constants/action-types";
import { MutationTypes } from "@/store/constants/mutation-types";
import { AvaStore } from "@/store/types/store.type";
import { ActionContext } from "vuex";
import { BiometricGraphRequestPayload } from "../types/biometric-graph/biometric-graph-request-payload";
import { BiometricGraphState } from "../types/biometric-graph/biometric-graph-state";
import biometricsGraphApi from "./services/biometrics-graph-service";

export interface BiometricGraphActions {
  [ActionTypes.FETCH_BIOMETRIC_GRAPH_DETAILS]: (
    context: ActionContext<BiometricGraphState, AvaStore>,
    payload: BiometricGraphRequestPayload
  ) => Promise<void>;
}

export const biometricGraphActions: BiometricGraphActions = {
  async [ActionTypes.FETCH_BIOMETRIC_GRAPH_DETAILS](store, payload) {
    try {
      store.commit(MutationTypes.BIOMETRIC_GRAPH__TOGGLE_LOADING, true);
      const result = await biometricsGraphApi.fetchBiometric(payload);
      store.commit(
        MutationTypes.BIOMETRIC_GRAPH__SET_GRAPH_DETAILS,
        result.data
      );
    } catch (error) {
      store.commit(MutationTypes.BIOMETRIC_GRAPH__SET_ERROR, error);
    } finally {
      store.commit(MutationTypes.BIOMETRIC_GRAPH__TOGGLE_LOADING, false);
    }
  },
};
