import { RouteRecordRaw } from "vue-router";
import { appointmentsRoute } from "@/pages/appointments/routes/appointments-routes";
import { manageClinicsRoute } from "@/pages/manage-clinics/routes/manage-clinics-routes";
import { manageDependantsRoute } from "@/pages/manage-dependants/routes/manage-dependants-routes";
import { patientProfileReviewRoute } from "@/pages/patient-profile-review/routes/patient-profile-review-routes";
import { patientChartRoute } from "@/pages/patient-charts/routes/patient-charts-routes";
import { authenticationRoute } from "@/pages/authentication/routes/authentication-routes";
import { dashboardRoute } from "./dashboard-routes";
import { messagesRoute } from "./messages-routes";
import { biometricChartRoute } from "@/pages/biometrics/routes/biometrics-routes";
import { testResultsRoute } from "@/pages/test-results/routes/test-results-routes";
import { videoLinkRoute } from "@/pages/video-link/routes/video-link-routes";
import { accountSettingsRoute } from "@/pages/account-settings/routes/account-settings-routes";
import { reportIssuesRoute } from "@/pages/report-issue/routes/report-issue-routes";
import { subscriptionRoute } from "@/pages/subscriptions/routes/subscriptions-routes";
import { documentRoute } from "@/pages/patient-documents/routes/documents-routes";
import { patientFormRoutes } from "@/pages/patient-forms/routes/patient-forms-routes";

export const routes: Array<RouteRecordRaw> = [
  authenticationRoute,
  dashboardRoute,
  messagesRoute,
  appointmentsRoute,
  manageClinicsRoute,
  manageDependantsRoute,
  patientProfileReviewRoute,
  patientChartRoute,
  biometricChartRoute,
  testResultsRoute,
  videoLinkRoute,
  accountSettingsRoute,
  reportIssuesRoute,
  subscriptionRoute,
  documentRoute,
  ...patientFormRoutes,
];
