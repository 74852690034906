<template>
  <transition name="from-left-side" tag="span" mode="out-in" appear>
    <slot />
  </transition>
</template>

<style lang="scss" scoped>
.from-left-side-enter-active,
.from-left-side-leave-active {
  transition: all 0.3s ease-out;
}

.from-left-side-enter-from,
.from-left-side-leave-to {
  transform: translateX(-120px);
  opacity: 0;
}
</style>
