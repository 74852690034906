import { onMounted, watch } from "vue";
import { useGlobalWindowManager } from "./use-global-window-manager";
import { useResizeWindowHeightAndWidth } from "./use-resize-window-height-and-width";
import debounce from "lodash/debounce";

export function useSetViewportHeight() {
  const { setGlobalCssVariable } = useGlobalWindowManager();
  const { innerHeight } = useResizeWindowHeightAndWidth();
  const setViewportHeightCssVariable = debounce(
    () =>
      setGlobalCssVariable(
        "--full-length-vh",
        innerHeight.value ? `${innerHeight.value}px` : "100vh"
      ),
    300
  );
  onMounted(() => {
    setViewportHeightCssVariable();
  });

  watch(innerHeight, setViewportHeightCssVariable);
}
