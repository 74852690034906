import { RouteRecordRaw } from "vue-router";
import { Routes } from "@/router/types/routes.enum";

const PatientFormsPage = () =>
  import(
    /* webpackChunkName: "patient_forms_index_page" */ "@/pages/patient-forms/patient-forms.vue"
  );
const PatientFormTemplatesPage = () =>
  import(
    /* webpackChunkName: "patient_form_templates_index_page" */ "@/pages/patient-forms/features/patient-forms-demo.vue"
  );

export const patientFormRoutes: RouteRecordRaw[] = [
  {
    path: "/patient-forms",
    component: PatientFormsPage,
    name: Routes.patientForms,
    meta: {
      isProtected: false,
    },
  },
  {
    path: "/patient-forms-demo/:id+",
    component: PatientFormTemplatesPage,
    name: Routes.patientFormDemo,
    meta: {
      isProtected: false,
    },
  },
  {
    path: "/patient-forms/:id+",
    redirect: { name: Routes.patientForms },
  },
];
