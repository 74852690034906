import { ActionTypes } from "@/store/constants/action-types";
import { AvaStore } from "@/store/types/store.type";
import { AvaActionContext } from "@/store/modules/types/ava-action-context";
import { router } from "@/router";
import { MutationTypes } from "@/store/constants/mutation-types";
import { AuthState } from "../../types/auth-state";

export interface AuthActionsNavigate {
  [ActionTypes.AUTH__UPDATE_LANDING_PAGE]: (
    context: AvaActionContext<AuthState, AvaStore>,
    path: string
  ) => void;
  [ActionTypes.AUTH__GO_TO_LANDING_PAGE]: (
    context: AvaActionContext<AuthState, AvaStore>
  ) => void;
}

export const authActionsNavigate: AuthActionsNavigate = {
  [ActionTypes.AUTH__UPDATE_LANDING_PAGE](store, path) {
    store.commit(MutationTypes.AUTH__UPDATE_LANDING_PAGE, path);
  },
  [ActionTypes.AUTH__GO_TO_LANDING_PAGE](store) {
    router.push(store.getters.getLandingPage);
    store.commit(
      MutationTypes.AUTH__UPDATE_LANDING_PAGE,
      router.getDefaultLandingPagePath()
    );
  },
};
