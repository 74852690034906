import { HttpError } from "@/http/types/http-error.interface";
import { ClinicContactInformation } from "./clinic-contact-information";

export function createInitialManageClinicState(): ManageClinicsState {
  return {
    clinics: [],
    searchedClinics: null,
    loading: false,
    error: null,
  };
}

export interface ManageClinicsState {
  clinics: ClinicContactInformation[];
  searchedClinics: ClinicContactInformation[] | null;
  loading: boolean;
  error: HttpError | null;
}
