import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "img-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_img = _resolveComponent("base-img")!
  const _component_base_transition_fade = _resolveComponent("base-transition-fade")!

  return (_openBlock(), _createBlock(_component_base_transition_fade, null, {
    default: _withCtx(() => [
      _createElementVNode("div", {
        class: _normalizeClass(["base-loader-container-pseudo-scoped", _ctx.computedClass]),
        style: _normalizeStyle(_ctx.computedStyle)
      }, [
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_base_img, { src: "loading/loading.svg" })
        ])
      ], 6)
    ]),
    _: 1
  }))
}