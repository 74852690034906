import { InjectionKey } from "vue";
import { createStore, useStore as baseUseStore, createLogger } from "vuex";
import { avaModules as modules } from "./modules/ava-modules";
import { RootState } from "./types/root-state.type";
import { AvaStore } from "./types/store.type";
/**
 We need to ignore ts-error in createStore function because
 I introduced a customized type for modules that is not recognized 
 by createStore function. Refer to './types/store.type' to see the Store typing.

 This was done to get typescript support in vue files. Because of this tweak we should
 'register' our typescripted modules by exporting them from the modules index.ts file
 */

export const key: InjectionKey<AvaStore<RootState>> = Symbol();

export const store = createStore<RootState>({
  // @ts-expect-error
  modules: modules,
  strict: process.env.NODE_ENV === "development",
  plugins:
    process.env.NODE_ENV !== "production"
      ? [createLogger({ logMutations: false })]
      : [],
}) as AvaStore;

export function useStore() {
  return baseUseStore(key) as AvaStore;
}
