import { ActionTypes } from "@/store/constants/action-types";
import { MutationTypes } from "@/store/constants/mutation-types";
import { AvaStore } from "@/store/types/store.type";
import { AvaActionContext } from "@/store/modules/types/ava-action-context";
import { AuthState } from "../../types/auth-state";
import authApi from "../../services/auth-services";
import { HttpError } from "@/http/types/http-error.interface";

export interface AuthActionsConfirmEmail {
  [ActionTypes.CONFIRM_EMAIL_TOKEN]: (
    context: AvaActionContext<AuthState, AvaStore>,
    payload: string
  ) => Promise<void>;
  [ActionTypes.RESEND_EMAIL_TOKEN]: (
    context: AvaActionContext<AuthState, AvaStore>
  ) => Promise<void>;
}

export const authActionsConfirmEmail: AuthActionsConfirmEmail = {
  async [ActionTypes.CONFIRM_EMAIL_TOKEN](store, emailToken) {
    try {
      await authApi.confirmEmailToken(
        store.getters.getPatientUserId as number,
        emailToken
      );
      store.commit(MutationTypes.AUTH__SET_EMAIL_CONFIRMED, true);
    } catch (error) {
      store.commit(MutationTypes.AUTH__SET_ERROR, error as HttpError);
    }
  },
  async [ActionTypes.RESEND_EMAIL_TOKEN](store) {
    try {
      store.commit(MutationTypes.AUTH__TOGGLE_LOADING, true);
      await authApi.resetEmailToken(store.getters.getPatientUserId as number);
      store.dispatch(
        ActionTypes.TOAST_SIMPLE_MESSAGE,
        "A temporary code has been email to you"
      );
    } catch (error) {
      store.commit(MutationTypes.AUTH__SET_ERROR, error as HttpError);
    } finally {
      store.commit(MutationTypes.AUTH__TOGGLE_LOADING, false);
    }
  },
};
