import { ActionTypes } from "@/store/constants/action-types";
import { MutationTypes } from "@/store/constants/mutation-types";
import { AvaStore } from "@/store/types/store.type";
import { router } from "@/router/router";
import { Routes } from "@/router/types/routes.enum";
import {
  clearLocalStorageAuthItems,
  reloadWindow,
} from "@/types/auth-local-storage/auth-local-storage.functions";
import { AvaActionContext } from "@/store/modules/types/ava-action-context";
import { AuthState } from "../../types/auth-state";
import { HttpError } from "@/http/types/http-error.interface";

export interface AuthActionsLogout {
  [ActionTypes.AUTH__LOGOUT]: (
    context: AvaActionContext<AuthState, AvaStore>
  ) => void;
}

export const authActionsLogout: AuthActionsLogout = {
  async [ActionTypes.AUTH__LOGOUT](store) {
    try {
      store.commit(MutationTypes.AUTH__TOGGLE_LOADING, true);
      await router.goTo(Routes.login);
      clearLocalStorageAuthItems();
    } catch (error) {
      store.commit(MutationTypes.AUTH__SET_ERROR, error as HttpError);
    } finally {
      reloadWindow();
      store.commit(MutationTypes.AUTH__TOGGLE_LOADING, false);
    }
  },
};
