import http from "@/http/http";
import { AxiosPromise } from "axios";
import {
  AppointmentSlotsResponseDto,
  GetBookableAppointmentSlotsPayload,
} from "../types/appointment-slots";
import {
  BookAppointmentPayload,
  BookAppointmentPostResponseDto,
} from "../types/book-appointment";
import { AppointmentResponseDto } from "@/features/shared-appointments/types/appointment";
import { BookingAppointmentClinicRequestDto } from "../types/booking-appointment-clinic/index";
import { BookingAppointmentEmployeeRequestDto } from "../types/booking-appointment-employee/index";
import { BookingAppointmentTypeRequestDto } from "../types/booking-appointment-type/index";

function getBookingAppointmentClinics(
  patientId: number
): AxiosPromise<{ clinics: BookingAppointmentClinicRequestDto[] }> {
  return http.get<{ clinics: BookingAppointmentClinicRequestDto[] }>(
    `/patients/${patientId}/patient_clinics`
  );
}

function getBookingAppointmentPositions(
  clinicId: number,
  patientId: number
): AxiosPromise<{ positions: BookingAppointmentEmployeeRequestDto[] }> {
  return http.get<{ positions: BookingAppointmentEmployeeRequestDto[] }>(
    `/clinics/${clinicId}/positions`,
    { params: { patient_id: patientId } }
  );
}

function getBookingAppointmentTypes(
  patientId: number,
  practitionerId: number
): AxiosPromise<{ appointment_types: BookingAppointmentTypeRequestDto[] }> {
  return http.get<{ appointment_types: BookingAppointmentTypeRequestDto[] }>(
    `/patients/${patientId}/positions/${practitionerId}/appointment_types`
  );
}

function getBookableAppointmentSlots(
  payload: GetBookableAppointmentSlotsPayload
): AxiosPromise<AppointmentSlotsResponseDto> {
  const params = {
    position_id: payload.positionId,
    appointment_type: payload.appointmentTypeId,
    start_date: payload.startDate,
    end_date: payload.endDate,
  };
  return http.get<AppointmentSlotsResponseDto>(
    `/patients/${payload.patientId}/available_appointments`,
    { params }
  );
}

function bookAppointment(
  payload: BookAppointmentPayload
): AxiosPromise<BookAppointmentPostResponseDto> {
  const date = payload.startDate.split("T")[0];
  const data = {
    appointment: {
      status: "Booked",
      category: "Regular",
      position_id: payload.positionId,
      appointment_type_id: payload.appointmentTypeId,
      notes: payload.notes,
      start_date: payload.startDate,
      end_date: payload.endDate,
      date,
    },
  };
  return http.post<BookAppointmentPostResponseDto>(
    `/patients/${payload.patientId}/appointments`,
    data
  );
}

function getAppointments(
  patientId: number
): AxiosPromise<{ appointments: AppointmentResponseDto[] }> {
  return http.get<{ appointments: AppointmentResponseDto[] }>(
    `/patients/${patientId}/appointments`
  );
}

function cancelAppointments(
  patientId: number,
  appointmentId: number
): AxiosPromise {
  return http.delete(`/patients/${patientId}/appointments/${appointmentId}`);
}

export default {
  bookAppointment,
  getBookingAppointmentClinics,
  getBookingAppointmentPositions,
  getBookingAppointmentTypes,
  getBookableAppointmentSlots,
  getAppointments,
  cancelAppointments,
};
