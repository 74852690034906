import { ClinicContactInformation } from "./types/clinic-contact-information";
import { ManageClinicsState } from "./types/manage-clinics-state";

export interface ManageClinicsGetters {
  isManageClinicsLoading: (manageClinicsState: ManageClinicsState) => boolean;
  getAuthorizedClinics: (
    manageClinicsState: ManageClinicsState
  ) => ClinicContactInformation[];
  getSearchedClinics: (
    manageClinicsState: ManageClinicsState
  ) => ClinicContactInformation[] | null;
}

export const manageClinicsGetters: ManageClinicsGetters = {
  isManageClinicsLoading(manageClinicsState) {
    return manageClinicsState.loading;
  },
  getAuthorizedClinics(manageClinicsState) {
    return manageClinicsState.clinics;
  },
  getSearchedClinics(manageClinicsState) {
    return manageClinicsState.searchedClinics;
  },
};
