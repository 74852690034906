import { RouteRecordRaw } from "vue-router";
import { Routes } from "@/router/types/routes.enum";
import MainLayout from "@/layouts/page-layouts/main-layout.vue";

const RouteEntryAppointmentsPage = () =>
  import(
    /* webpackChunkName: "the_appointments_route_entry_page" */ "@/pages/appointments/appointments.vue"
  );
const BookAppointment = () =>
  import(
    /* webpackChunkName: "book_appointment_page" */ "@/pages/appointments/pages/book-appointment/book-appointment.vue"
  );
const DefaultAppointments = () =>
  import(
    /* webpackChunkName: "default_appointments_page" */ "@/pages/appointments/pages/default-appointments/default-appointments.vue"
  );

export const appointmentsRoute: RouteRecordRaw = {
  path: "/appointments",
  component: RouteEntryAppointmentsPage,
  children: [
    {
      path: "",
      name: Routes.appointments,
      component: DefaultAppointments,
      meta: {
        layout: MainLayout,
        isProtected: true,
      },
    },
    {
      path: "book-appointment",
      name: Routes.bookAppointment,
      component: BookAppointment,
      meta: {
        layout: MainLayout,
        isProtected: true,
      },
    },
  ],
};
