import { RouteRecordRaw } from "vue-router";
import { Routes } from "@/router";
import MainLayout from "@/layouts/page-layouts/main-layout.vue";

const EntryPointTestResultsPage = () =>
  import(
    /* webpackChunkName: "test_results_index_page" */ "@/pages/test-results/test-results.vue"
  );

export const testResultsRoute: RouteRecordRaw = {
  path: "/test-results",
  component: EntryPointTestResultsPage,
  name: Routes.testResults,
  meta: {
    layout: MainLayout,
    isProtected: true,
  },
};
