import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_chevron_icon = _resolveComponent("chevron-icon")!
  const _component_patient_profile_dependant_button = _resolveComponent("patient-profile-dependant-button")!
  const _component_toolbar_row_button = _resolveComponent("toolbar-row-button")!
  const _component_base_typography = _resolveComponent("base-typography")!
  const _component_base_list_item = _resolveComponent("base-list-item")!
  const _component_base_drop_down_list = _resolveComponent("base-drop-down-list")!

  return (_openBlock(), _createBlock(_component_base_drop_down_list, { align: "right" }, {
    button: _withCtx(({ isOpen }) => [
      _createVNode(_component_toolbar_row_button, null, {
        default: _withCtx(() => [
          _createVNode(_component_chevron_icon, {
            direction: isOpen ? 'up' : 'down'
          }, null, 8, ["direction"]),
          _createVNode(_component_patient_profile_dependant_button, {
            buttonTitle: _ctx.namePhrase,
            isCurrentlyActingCaregiver: _ctx.isCurrentlyActingCaregiver
          }, null, 8, ["buttonTitle", "isCurrentlyActingCaregiver"])
        ]),
        _: 2
      }, 1024)
    ]),
    list: _withCtx(() => [
      (_ctx.isCurrentlyActingCaregiver)
        ? (_openBlock(), _createBlock(_component_base_list_item, {
            key: 0,
            class: "list-item",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('stopCaretakingMode')))
          }, {
            default: _withCtx(() => [
              _createVNode(_component_base_typography, { body2: "" }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.myProfileTitle), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.dependants, (dependant) => {
        return (_openBlock(), _createBlock(_component_base_list_item, {
          key: dependant.nickname,
          class: "list-item",
          onClick: ($event: any) => (_ctx.$emit('chooseDependant', dependant))
        }, {
          default: _withCtx(() => [
            _createVNode(_component_base_typography, { body2: "" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(dependant.nickname), 1)
              ]),
              _: 2
            }, 1024)
          ]),
          _: 2
        }, 1032, ["onClick"]))
      }), 128)),
      _createVNode(_component_base_list_item, {
        class: "list-item",
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('goToManageDependants')))
      }, {
        default: _withCtx(() => [
          _createVNode(_component_base_typography, { body2: "" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.manageDependantsTitle), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}