import { AxiosResponse } from "axios";
import {
  createPhoneEntryFromDto,
  PhoneEntry,
  PhoneEntryDto,
} from "./phone-entry";

const createPhoneEntriesFromPatientProfileDto = (
  phoneEntryDtos: PhoneEntryDto[]
): PhoneEntry[] => {
  const filteredEntries = phoneEntryDtos.filter(
    (item: PhoneEntryDto) => item.type === "phone"
  );
  return filteredEntries.map(createPhoneEntryFromDto);
};

export const createPatientProfileFromPatientProfileDto = (
  dto: AxiosResponse<PatientProfileDto>
): PatientProfile => ({
  id: dto.data.data.attributes.id,
  firstName: dto.data.data.attributes.first_name,
  middleMame: dto.data.data.attributes.middle_name,
  lastName: dto.data.data.attributes.last_name,
  address1: dto.data.data.attributes.address_1,
  address2: dto.data.data.attributes.address_2,
  city: dto.data.data.attributes.city,
  province: dto.data.data.attributes.province,
  country: dto.data.data.attributes.country,
  postalCode: dto.data.data.attributes.postal_code,
  email: dto.data.data.attributes.email,
  phonePrimary: dto.data.data.attributes.phone_primary,
  phoneSecondary: dto.data.data.attributes.phone_secondary,
  textPhonePrimary: dto.data.data.attributes.text_phone_primary,
  textPhoneSecondary: dto.data.data.attributes.text_phone_secondary,
  sex: dto.data.data.attributes.sex,
  dateOfBirth: dto.data.data.attributes.date_of_birth,
  emergencyPhone: dto.data.data.attributes.emergency_phone,
  emergencyName: dto.data.data.attributes.emergency_name,
  emergencyRelationship: dto.data.data.attributes.emergency_relationship,
  primaryPhysicianConfirmed: dto.data.data.attributes.primary_clinic_confirmed,
  primaryClinicConfirmed: dto.data.data.attributes.primary_clinic_confirmed,
  appointmentReminders: dto.data.data.attributes.appointment_reminders,
  smsAppointmentReminders: dto.data.data.attributes.sms_appointment_reminders,
  primaryPhysicianName: dto.data.data.attributes.primary_physician_name,
  primaryClinicName: dto.data.data.attributes.primary_clinic_name,
  base64Avatar: dto.data.data.attributes.base64_avatar,
  phones: createPhoneEntriesFromPatientProfileDto(dto.data?.included || []),
});

export interface PatientProfile {
  id: number | null;
  firstName: string | null;
  middleMame: string | null;
  lastName: string | null;
  address1: string | null;
  address2: string | null;
  city: string | null;
  province: string | null;
  country: string | null;
  postalCode: string | null;
  email: string | null;
  phonePrimary: string | null;
  phoneSecondary: string | null;
  textPhonePrimary: boolean | null;
  textPhoneSecondary: boolean | null;
  sex: string | null;
  dateOfBirth: string | null;
  emergencyPhone: string | null;
  emergencyName: string | null;
  emergencyRelationship: string | null;
  primaryPhysicianConfirmed: boolean | null;
  primaryClinicConfirmed: boolean | null;
  appointmentReminders: boolean | null;
  smsAppointmentReminders: boolean | null;
  primaryPhysicianName: string | null;
  primaryClinicName: string | null;
  base64Avatar: string | null;
  phones: PhoneEntry[];
}

export interface PatientProfileDto {
  data: {
    attributes: PatientAttributesDto;
  };
  included?: PhoneEntryDto[];
}
export interface PatientAttributesDto {
  id: number;
  first_name: string | null;
  middle_name: string | null;
  last_name: string | null;
  address_1: string | null;
  address_2: string | null;
  city: string | null;
  province: string | null;
  country: string | null;
  postal_code: string | null;
  email: string | null;
  phone_primary: string | null;
  phone_secondary: string | null;
  text_phone_primary: boolean | null;
  text_phone_secondary: boolean | null;
  sex: string | null;
  date_of_birth: string | null;
  emergency_phone: string | null;
  emergency_name: string | null;
  emergency_relationship: string | null;
  primary_identifier: string | null;
  primary_identifier_category: string | null;
  clinic_id: number | null;
  employee_id: number | null;
  primary_physician_confirmed: boolean | null;
  primary_clinic_confirmed: boolean | null;
  appointment_reminders: boolean | null;
  sms_appointment_reminders: boolean | null;
  primary_physician_name: string | null;
  primary_clinic_name: string | null;
  base64_avatar: string | null;
}
