import { RouteRecordRaw } from "vue-router";
import { Routes } from "@/router/types/routes.enum";
import MainLayout from "@/layouts/page-layouts/main-layout.vue";

const EntryPointAccountSettingsPage = () =>
  import(
    /* webpackChunkName: "account_settings_index_page" */ "@/pages/account-settings/account-settings.vue"
  );

const AccountSettingsSecurityPage = () =>
  import(
    /* webpackChunkName: "account_settings_index_page" */ "@/pages/account-settings/pages/security/security.vue"
  );

export const accountSettingsRoute: RouteRecordRaw = {
  path: "/account-settings",
  component: EntryPointAccountSettingsPage,
  name: Routes.accountSettings,
  children: [
    {
      path: "security",
      name: Routes.accountSettingsSecurity,
      component: AccountSettingsSecurityPage,
      meta: {
        layout: MainLayout,
        isProtected: true,
      },
    },
  ],
};
