import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["data-ava-theme-mode"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    id: "root",
    "data-ava-theme-mode": _ctx.themeMode
  }, [
    _renderSlot(_ctx.$slots, "default")
  ], 8, _hoisted_1))
}