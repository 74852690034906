
import { defineComponent } from "vue";
import BaseTransitionFade from "../base-transition/base-transition-fade.vue";
import { StyleValue } from "vue";

export default defineComponent({
  name: "BaseLoader",
  components: { BaseTransitionFade },
  props: {
    position: {
      type: String,
      default: "fixed",
    },
  },
  computed: {
    computedClass(): object {
      return {
        relative: this.position === "relative",
      };
    },
    computedStyle(): StyleValue {
      return {
        position: this.position as
          | "static"
          | "relative"
          | "absolute"
          | "sticky"
          | "fixed",
      };
    },
  },
});
