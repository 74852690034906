import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_typography = _resolveComponent("base-typography")!
  const _component_close_icon = _resolveComponent("close-icon")!
  const _component_base_button_icon = _resolveComponent("base-button-icon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["toast-message ava-shadow", _ctx.toastMessageClasses])
  }, [
    _createVNode(_component_base_typography, { class: "message-text" }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.toastMessage.message), 1)
      ]),
      _: 1
    }),
    _createVNode(_component_base_button_icon, {
      onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.$emit('removeMessage', _ctx.toastMessage.message)), ["stop","prevent"])),
      class: "exit-button ava-clickable",
      tertiary: ""
    }, {
      default: _withCtx(() => [
        _createVNode(_component_close_icon)
      ]),
      _: 1
    })
  ], 2))
}