import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1db09308"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "main-layout-container" }
const _hoisted_2 = { class: "main-layout-page" }
const _hoisted_3 = { class: "content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_the_toolbar = _resolveComponent("the-toolbar")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.showToolbar)
      ? (_openBlock(), _createBlock(_component_the_toolbar, { key: 0 }))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _renderSlot(_ctx.$slots, "default", {}, undefined, true)
      ])
    ])
  ]))
}