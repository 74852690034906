import { Provinces } from "@/utils/provinces.constants";

export function createDependantFromDto(dto: DependantDto): Dependant {
  return {
    id: dto.id,
    confirmed: dto.confirmed,
    dateConfirmed: dto.date_confirmed,
    patientId: dto.patient_id,
    nickname: dto.nickname,
    healthcareNumber: dto.healthcare_number,
    healthcareProvince: dto.healthcare_province,
  };
}

export interface DependantDto {
  id: number;
  confirmed: boolean;
  date_confirmed: string | null;
  patient_id: number | null;
  nickname: string;
  healthcare_number: string;
  healthcare_province: Provinces;
  has_upcoming_appointment: boolean;
  has_notification: boolean;
}

export interface Dependant {
  id: number;
  confirmed: boolean;
  dateConfirmed: string | null;
  patientId: number | null;
  nickname: string;
  healthcareNumber: string;
  healthcareProvince: Provinces;
}
