import { RouteRecordRaw } from "vue-router";
import { Routes } from "../../../router/types/routes.enum";
import MainLayout from "@/layouts/page-layouts/main-layout.vue";

const EntryPointManageClinicsPage = () =>
  import(
    /* webpackChunkName: "manage_clinics_index_page" */ "@/pages/manage-clinics/index.vue"
  );
const DefaultManageClinics = () =>
  import(
    /* webpackChunkName: "manage_clinics_default_page" */ "@/pages/manage-clinics/manage-clinics.vue"
  );
const AuthorizeNewClinic = () =>
  import(
    /* webpackChunkName: "authorize_clinic_page" */ "@/pages/manage-clinics/pages/authorize-new-clinic/authorize-new-clinic.vue"
  );

export const manageClinicsRoute: RouteRecordRaw = {
  path: "/manage-clinics",
  component: EntryPointManageClinicsPage,
  children: [
    {
      path: "",
      name: Routes.manageClinics,
      component: DefaultManageClinics,
      meta: {
        layout: MainLayout,
        isProtected: true,
      },
    },
    {
      path: "authorize-new-clinic",
      name: Routes.authorizeNewClinic,
      component: AuthorizeNewClinic,
      meta: {
        layout: MainLayout,
        isProtected: true,
      },
    },
  ],
};
