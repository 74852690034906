
import { defineComponent } from "vue";
import BaseTransitionFade from "../base-transition/base-transition-fade.vue";
import BaseTransitionEnterLeft from "../base-transition/base-transition-enter-left.vue";

export default defineComponent({
  inheritAttrs: false,
  emits: ["close"],
  props: {
    isOpen: {
      type: Boolean,
      default: false,
      required: true,
    },
  },
  components: {
    BaseTransitionFade,
    BaseTransitionEnterLeft,
  },
  methods: {
    toggleMenu() {
      this.$emit("close");
    },
  },
});
