import { BloodGlucoseRequestPayload } from "./blood-glucose-request-payload";
import { BloodPressureHeartRateRequestPayload } from "./blood-pressure-request-payload";
import { WeightHeightRequestPayload } from "./weight-height-request-payload";

export const createBiometricsRequestDto = (
  payload: BiometricsRequestPayload
): BiometricsRequestDto => ({
  systolic: payload?.systolic,
  diastolic: payload?.diastolic,
  heart_rate: payload?.heartRate,
  time_of_measurement: payload?.timeOfMeasurement,
  date_of_measurement: payload?.dateOfMeasurement,
  height: payload?.height,
  height_units: payload?.heightUnit,
  weight: payload?.weight,
  weight_units: payload?.weightUnit,
  blood_glucose: payload?.bloodGlucose,
});

export interface BiometricsRequestDto {
  systolic?: string;
  diastolic?: string;
  heart_rate?: string;
  time_of_measurement?: string;
  date_of_measurement?: string;
  height?: string | null;
  height_units?: string | null;
  weight?: string;
  weight_units?: string;
  blood_glucose?: string;
}

export type BiometricsRequestPayload = BloodPressureHeartRateRequestPayload &
  WeightHeightRequestPayload &
  BloodGlucoseRequestPayload;
