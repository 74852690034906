import { HttpError } from "@/http/types/http-error.interface";
import { Appointment } from "@/features/shared-appointments/types/appointment";
import { AppointmentSlots } from "./appointment-slots";

export interface AppointmentsState {
  appointmentSlots: AppointmentSlots | null;
  appointments: Appointment[];
  loading: boolean;
  error: HttpError | null;
}

export const createDefaultAppointmentsState = (): AppointmentsState => ({
  appointmentSlots: null,
  appointments: [],
  loading: false,
  error: null,
});
