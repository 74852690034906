import { Appointment } from "./appointment";
import { AppointmentResponseDto } from "./appointment-response-dto";
import orderBy from "lodash/orderBy";

export const isAppointmentCancelled = (appointment: Appointment) =>
  appointment.status === "Cancelled";

export const getAppointmentIndex = (
  appointmentId: number,
  appointments: Appointment[]
): number => appointments.findIndex((apt) => apt.id === appointmentId);

export const createCancelledAppointment = (
  appointmentIndex: number,
  appointments: Appointment[]
): Appointment => {
  const newAppointment = { ...appointments[appointmentIndex] };
  newAppointment.status = "Cancelled";
  return newAppointment;
};

export const orderAppointmentsByStartDate = (appointments: Appointment[]) =>
  orderBy(appointments, ["startDate"], ["asc"]);

export const createAppointmentFromDto = (
  dto: AppointmentResponseDto
): Appointment => ({
  id: dto.id,
  status: dto.status,
  patientId: dto.patient_id,
  endDate: dto.end_date,
  startDate: dto.start_date,
  notes: dto.notes,
  clinic: {
    id: null,
    name: dto.clinic.name,
    address1: dto.clinic.address_1,
    address2: dto.clinic.address_2,
    city: dto.clinic.city,
    province: dto.clinic.province,
    country: dto.clinic.country,
    postalCode: dto.clinic.postal_code,
    phonePrimary: dto.clinic.phone_primary,
    phoneSecondary: dto.clinic.phone_secondary,
    fax: null,
  },
  clinicName: dto.clinic.name,
  cancellationPolicy: dto.clinic.cancellation_policy,
  employee: {
    firstName: dto.employee.first_name,
    lastName: dto.employee.last_name,
    employeeCategory: dto.employee.employee_category,
    title: dto.employee.title,
  },
  appointmentTypeName: dto.appointment_type.name,
  patientInstructions: dto.appointment_type.patient_instructions,
});

export const appointmentTitle = (appointment: Appointment) => {
  const employeeName = `${appointment.employee.title} ${appointment.employee.firstName} ${appointment.employee.lastName}`;

  return `${appointment.appointmentTypeName} Appointment with ${employeeName}`;
};
