import { BiometricFormState } from "../types/biometric-form-state";

export interface BiometricFormGetters {
  getBiometricsLoading: (state: BiometricFormState) => boolean;
  hasBiometricFormSubmissionSucceeded: (state: BiometricFormState) => boolean;
}

export const biometricFormGetters: BiometricFormGetters = {
  getBiometricsLoading(state) {
    return state.loading;
  },
  hasBiometricFormSubmissionSucceeded(state) {
    return Boolean(state.formSubmissionSucceeded);
  },
};
