
import { computed, defineComponent } from "vue";
import { useToggledValue } from "@/composition/use-toggled-value";
import BaseOverlayBackground from "../base-overlay-background/base-overlay-background.vue";
export default defineComponent({
  components: { BaseOverlayBackground },
  props: {
    align: {
      type: String,
      default: "left",
      validator: (value: string) => ["left", "right"].indexOf(value) !== -1,
    },
  },
  setup(props) {
    const { isTrue: showMenu, toggleValue: toggleShowMenu } = useToggledValue();
    const menuStyles = computed(() => ({
      [props.align]: 0,
    }));
    return {
      menuStyles,
      showMenu,
      toggleShowMenu,
    };
  },
});
