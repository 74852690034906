import http from "@/http/http";
import { AxiosPromise } from "axios";
import { AuthorizedClinicDto } from "../types/authorized-clinic-dto";
import { SearchedClinicDto } from "../types/searched-clinic-dto";

function getManagedClinics(
  patientId: number
): AxiosPromise<{ authorized_clinics: AuthorizedClinicDto[] }> {
  return http.get<{ authorized_clinics: AuthorizedClinicDto[] }>(
    `/patients/${patientId}/authorized_clinics`
  );
}

function authorizedClinic(
  patientId: number,
  clinicId: number
): AxiosPromise<{ authorized_clinic: AuthorizedClinicDto }> {
  return http.post<{ authorized_clinic: AuthorizedClinicDto }>(
    `/patients/${patientId}/authorized_clinics`,
    { clinic_id: clinicId }
  );
}

function deAuthorizedClinic(
  patientId: number,
  clinicId: number
): AxiosPromise<unknown> {
  return http.delete(`/patients/${patientId}/authorized_clinics/${clinicId}`);
}

function getSearchedClinics(
  searchTerm: string
): AxiosPromise<{ clinics: SearchedClinicDto[] }> {
  const params = {
    search: searchTerm,
  };
  return http.get<{ clinics: SearchedClinicDto[] }>(`/clinics`, { params });
}

export default {
  authorizedClinic,
  deAuthorizedClinic,
  getManagedClinics,
  getSearchedClinics,
};
