export enum Routes {
  theAuthenticationLandingPage = "TheAuthenticationLandingPage",
  login = "Login",
  signUp = "SignUp",
  dashboard = "Dashboard",
  completeRegistration = "CompleteRegistration",
  messages = "Messages",
  appointments = "Appointments",
  bookAppointment = "BookAppointment",
  manageClinics = "ManageClinics",
  authorizeNewClinic = "AuthorizeNewClinic",
  manageDependants = "ManageDependants",
  addDependant = "AddDependant",
  patientProfileReviewDemographics = "PatientProfileReviewDemographics",
  patientCharts = "PatientCharts",
  patientChartsMedicalHistory = "PatientChartsMedicalHistory",
  patientChartsFamilyHistory = "PatientChartsFamilyHistory",
  patientChartsSocialHistory = "PatientChartsSocialHistory",
  patientChartsAllergyHistory = "PatientChartsAllergyHistory",
  patientChartsMedicationReview = "PatientChartsMedicationReview",
  patientChartsReferralReview = "PatientChartsReferralReview",
  patientChartsVaccineHistory = "PatientChartsVaccineHistory",
  biometrics = "Biometrics",
  biometricsBloodPressure = "BiometricsBloodPressure",
  biometricsHeartRate = "BiometricsHeartRate",
  biometricsWeight = "BiometricsWeight",
  biometricsHeight = "BiometricsHeight",
  biometricsBloodGlucose = "BiometricsBloodGlucose",
  testResults = "TestResults",
  videoLink = "VideoLink",
  accountSettings = "AccountSettings",
  accountSettingsSecurity = "AccountSettingsSecurity",
  twoFactorSignIn = "TwoFactorSignIn",
  forgotPassword = "ForgotPassword",
  resetPassword = "ResetPassword",
  reportIssues = "ReportIssues",
  subscriptions = "Subscriptions",
  successfullyPaid = "SuccessfullyPaid",
  patientDocuments = "PatientDocuments",
  nonFillableForms = "NonFillableForms",
  letters = "Letters",
  fillableForms = "FillableForms",
  messageFiles = "MessageFiles",
  otherDocuments = "OtherDocuments",
  preVerifiedInvitation = "PreVerifiedInvitation",
  patientForms = "patientForms",
  patientFormDemo = "patientFormDemo",
}
