import http from "@/http/http";
import { AxiosPromise } from "axios";
import { PatientConnectClinicDto } from "../types/patient-connect-clinic-dto.interface";

function fetchPatientConnectClinics(
  patientId: number
): AxiosPromise<{ patient_connect_clinics: PatientConnectClinicDto[] }> {
  return http.get(`/patients/${patientId}/patient_connect_clinics`);
}

function updatePatientConnectClinic(
  patientId: number,
  patientConnectClinicId: number,
  isAccepted: boolean
) {
  return http.put(
    `/patients/${patientId}/patient_connect_clinics/${patientConnectClinicId}`,
    {
      patient_connect_clinic: {
        [isAccepted ? "accept" : "decline"]: 1,
      },
    }
  );
}
function resetPatientConnectClinic(
  patientId: number,
  patientConnectClinicId: number
) {
  return http.put(
    `/patients/${patientId}/patient_connect_clinics/${patientConnectClinicId}`,
    {
      patient_connect_clinic: {
        undo: 1,
      },
    }
  );
}

// function authorizedClinic(
//   patientId: number,
//   clinicId: number
// ): AxiosPromise<{ authorized_clinic: AuthorizedClinicDto }> {
//   return http.post<{ authorized_clinic: AuthorizedClinicDto }>(
//     `/patients/${patientId}/authorized_clinics`,
//     { clinic_id: clinicId }
//   );
// }

// function deAuthorizedClinic(
//   patientId: number,
//   clinicId: number
// ): AxiosPromise<unknown> {
//   return http.delete(`/patients/${patientId}/authorized_clinics/${clinicId}`);
// }

// function getSearchedClinics(
//   searchTerm: string
// ): AxiosPromise<{ clinics: SearchedClinicDto[] }> {
//   const params = {
//     search: searchTerm,
//   };
//   return http.get<{ clinics: SearchedClinicDto[] }>(`/clinics`, { params });
// }

export default {
  fetchPatientConnectClinics,
  updatePatientConnectClinic,
  resetPatientConnectClinic,
};
