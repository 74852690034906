import { HttpError } from "@/http/types/http-error.interface";
import { AuthSession } from "./auth-session/auth-session.interface";
import { createInitialAuthSession } from "./auth-session/auth-session.functions";
import { router } from "@/router";

export function createInitialAuthState(): AuthState {
  return {
    session: createInitialAuthSession(),
    validJwtTokenIsFetched: false,
    isTwoFactorAuthenticated: false,
    twoFactorQrCodeUri: null,
    isAuthorizedToEditAccountSettings: false,
    loading: false,
    jwtExpiryMillisecondsSinceEpoch: null,
    // Router needs optional chaining for running vue component tests
    landingPage: router?.getDefaultLandingPagePath(),
    error: null,
  };
}

export interface AuthState {
  session: AuthSession;
  validJwtTokenIsFetched: boolean;
  isTwoFactorAuthenticated: boolean;
  twoFactorQrCodeUri: string | null;
  isAuthorizedToEditAccountSettings: boolean;
  loading: boolean;
  jwtExpiryMillisecondsSinceEpoch: number | null;
  landingPage: string;
  error: HttpError | null;
}
