import { HttpError } from "@/http/types/http-error.interface";
import { TestResultLineGraphDetailsItem } from "./test-result-graph-details-item";
import { TestResultItem } from "./test-result-item";
import { TestResultItemDetail } from "./test-result-item-detail";

export const createDefaultTestResultsState = (): TestResultsState => ({
  testResultLineGraphDetailsItem: null,
  testResultLineGraphTitle: null,
  testResultDetails: [],
  testResultsList: [],
  selectedTestResult: null,
  loading: false,
  error: null,
});

export interface TestResultsState {
  testResultLineGraphDetailsItem: TestResultLineGraphDetailsItem | null;
  testResultLineGraphTitle: string | null;
  testResultDetails: TestResultItemDetail[];
  testResultsList: TestResultItem[];
  selectedTestResult: TestResultItem | null;
  loading: boolean;
  error: HttpError | null;
}
