
import { defineComponent } from "vue";
import IconWrapper from "./icon-wrapper.vue";

export default defineComponent({
  components: { IconWrapper },
  props: {
    large: {
      type: Boolean,
      required: false,
    },
  },
  setup() {
    return {
      icon: '<i class="material-icons">local_post_office</i>',
    };
  },
});
