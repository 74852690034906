import { AxiosResponse } from "axios";
import { LocalStorageItems } from "@/types/auth-local-storage/auth-local-storage.enums";

export function storeAuthTokenOnLocalDevice(
  response: AxiosResponse
): AxiosResponse {
  const jwt = response.headers.authorization;
  if (jwt) {
    localStorage.setItem(LocalStorageItems.jwt, jwt);
  }
  return response;
}
