
import { defineComponent } from "vue";
import BaseListItem from "@/ui-library/base-list-item/base-list-item.vue";
import BaseTypography from "@/ui-library/base-typography/base-typography.vue";
import ChevronIcon from "@/ui-library/icons/chevron-icon.vue";
import BaseTransitionFade from "@/ui-library/base-transition/base-transition-fade.vue";

export default defineComponent({
  components: { BaseListItem, BaseTypography, ChevronIcon, BaseTransitionFade },
  props: {
    isNestedItem: {
      type: Boolean,
      default: false,
    },
    showNestedList: {
      type: Boolean,
      default: false,
    },
  },
});
