import {
  ToastMessage,
  ViewMessageTimeLength,
  ToastMessageType,
} from "./toast-message";

export function findToastMessageIndexByMessage(
  toasts: ToastMessage[],
  message: string
): { messageIndex: number; isFound: boolean } {
  const messageIndex = toasts.findIndex((toast) => toast.message === message);
  return {
    messageIndex,
    isFound: messageIndex !== -1,
  };
}

function getTimeToView(length: ViewMessageTimeLength): number | null {
  const viewLengthRecord: Record<ViewMessageTimeLength, number | null> = {
    long: 7000,
    normal: 3000,
    untilClosed: null,
  };
  return viewLengthRecord[length];
}

export const createErrorToastMessage = (
  message: string,
  viewLength: ViewMessageTimeLength = "long"
): ToastMessage => ({
  message,
  type: "warning",
  timeToView: getTimeToView(viewLength),
});

export const createToastMessage = (
  message: string,
  type: ToastMessageType = "notification",
  timeToView = 3000
): ToastMessage => ({
  message,
  type,
  timeToView,
});

export const isToastMessageType = (
  toastMessage: ToastMessage,
  test: ToastMessageType
): boolean => toastMessage.type === test;
