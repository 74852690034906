import http from "@/http/http";
import { AxiosPromise } from "axios";
import {
  PatientAttributesDto,
  PatientProfileDto,
} from "../types/patient-profile";
import {
  DeletePhonePayload,
  EditPhonePayload,
  NewPhonePayload,
  PhoneEntryDto,
} from "../types/phone-entry";

function getPatientProfile(patientId: number): AxiosPromise<PatientProfileDto> {
  return http.get(`/patients/${patientId}`);
}

function submitPatientAvatar(
  patientId: number,
  imgData: FormData
): AxiosPromise<{ img: string }> {
  return http.post<{ img: string }>(
    `/patients/${patientId}/patient_avatar`,
    imgData,
    { headers: { "Content-Type": "multipart/form-data" } }
  );
}

function editPatientProfile(
  patientId: number,
  data: Partial<PatientAttributesDto>
): AxiosPromise<PatientProfileDto> {
  return http.patch<PatientProfileDto>(`/patients/${patientId}`, {
    patient: data,
  });
}

function addPatientPhone(
  payload: NewPhonePayload
): AxiosPromise<{ data: PhoneEntryDto[] }> {
  return http.post<{ data: PhoneEntryDto[] }>(
    `/patients/${payload.patientId}/phones`,
    {
      category: payload.category,
      extension: payload?.extension,
      phone_number: payload.phoneNumber,
      primary: payload.primary,
    }
  );
}

function editPatientPhone(
  payload: EditPhonePayload
): AxiosPromise<{ data: PhoneEntryDto[] }> {
  return http.patch<{ data: PhoneEntryDto[] }>(
    `/patients/${payload.patientId}/phones/${payload.phoneId}`,
    {
      category: payload.category,
      extension: payload?.extension,
      phone_number: payload.phoneNumber,
      primary: payload.primary,
    }
  );
}

function deletePatientPhone(
  payload: DeletePhonePayload
): AxiosPromise<{ data: PhoneEntryDto[] }> {
  return http.delete<{ data: PhoneEntryDto[] }>(
    `/patients/${payload.patientId}/phones/${payload.phoneId}`
  );
}

export default {
  deletePatientPhone,
  editPatientPhone,
  addPatientPhone,
  getPatientProfile,
  editPatientProfile,
  submitPatientAvatar,
};
