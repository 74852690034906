import { PatientProfile } from "../types/patient-profile";
import { PatientProfileState } from "../types/patient-profile-state";
import { PhoneEntry } from "../types/phone-entry";

export interface PatientProfileGetters {
  getPatientProfile: (
    patientProfileState: PatientProfileState
  ) => PatientProfile | null;
  getPhones: (patientProfileState: PatientProfileState) => PhoneEntry[];
  getEmailReminderStatus: (patientProfileState: PatientProfileState) => boolean;
  getSmsReminderStatus: (patientProfileState: PatientProfileState) => boolean;
  getPatientIdToInitiatePatientProfile: (
    patientProfileState: PatientProfileState
  ) => number | null;
}

export const patientProfileGetters: PatientProfileGetters = {
  getPatientProfile(patientProfileState) {
    return patientProfileState.patientProfile || null;
  },
  getPhones(patientProfileState) {
    return patientProfileState.patientProfile?.phones || [];
  },
  getEmailReminderStatus(patientProfileState) {
    return patientProfileState.patientProfile?.appointmentReminders || false;
  },
  getSmsReminderStatus(patientProfileState) {
    return patientProfileState.patientProfile?.smsAppointmentReminders || false;
  },
  getPatientIdToInitiatePatientProfile(patientProfileState) {
    return patientProfileState.patientIdToInitiatePatientProfile;
  },
};
