import { RootState } from "@/store/types/root-state.type";
import {
  createInitialPatientProfileState,
  PatientProfileState,
} from "../types/patient-profile-state";
import { AvaModule } from "../../../store/modules/types/ava-module.interface";
import {
  patientProfileGetters,
  PatientProfileGetters,
} from "./patient-profile-getters";
import {
  patientProfileActions,
  PatientProfileActions,
} from "./patient-profile-actions";
import {
  PatientProfileMutations,
  patientProfileMutations,
} from "./patient-profile-mutations";

export const PatientProfileStoreModule: AvaModule<
  PatientProfileState,
  RootState,
  PatientProfileActions,
  PatientProfileMutations,
  PatientProfileGetters
> = {
  state(): PatientProfileState {
    return createInitialPatientProfileState();
  },
  mutations: patientProfileMutations,
  actions: patientProfileActions,
  getters: patientProfileGetters,
};
