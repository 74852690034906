import { InternalAxiosRequestConfig } from "axios";

export function setConfigHeaderWithAuthToken(
  config: InternalAxiosRequestConfig
): InternalAxiosRequestConfig {
  // @ts-ignore
  config.headers["authorization"] = localStorage.getItem("jwt");
  return config;
}

export function decodeUrl(
  config: InternalAxiosRequestConfig
): InternalAxiosRequestConfig {
  if (config.url) {
    config.url = decodeURIComponent(config.url);
  }
  return config;
}
