import { HttpError } from "@/http/types/http-error.interface";
import { BiometricGraphDetails } from "./biometric-graph-details";

export const createDefaultBiometricGraphState = () => ({
  loading: false,
  error: null,
  graphDetails: null,
});

export interface BiometricGraphState {
  loading: boolean;
  error: HttpError | null;
  graphDetails: BiometricGraphDetails | null;
}
