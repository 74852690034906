import isString from "lodash/isString";
import isPlainObject from "lodash/isPlainObject";
import { AvaErrorMap } from "./ava-error-map";
import { AvaApiErrors } from "./ava-api-errors";

export const convertAvaApiErrorDataToMessageList = (
  errorPackage?: AvaApiErrors
): string[] => {
  if (Array.isArray(errorPackage)) {
    return errorPackage || [];
  }
  if (isString(errorPackage)) {
    return [errorPackage];
  }
  if (isPlainObject(errorPackage)) {
    const mapOfErrors = errorPackage as AvaErrorMap;
    return Object.keys(mapOfErrors).map(
      (fieldName: string) =>
        `${fieldName.replace("_", " ")} ${mapOfErrors[fieldName]}`
    );
  }
  return [];
};
