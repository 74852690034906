import { PhoneCategory } from "@/utils/phone-categories.constants";

export const createPhoneEntryFromDto = (dto: PhoneEntryDto): PhoneEntry => ({
  id: Number(dto.id),
  category: dto.attributes?.category || "Home",
  extension: dto.attributes?.extension,
  phoneNumber: dto.attributes?.phone_number || "",
  primary: dto.attributes?.primary || false,
});

export interface PhoneEntry {
  id: number;
  category: PhoneCategory;
  extension: string | null;
  phoneNumber: string;
  primary: boolean;
}

export interface EditPhonePayload {
  phoneId: number;
  patientId: number;
  category: PhoneCategory;
  phoneNumber: string;
  primary: boolean;
  extension?: string;
}

export interface DeletePhonePayload {
  phoneId: number;
  patientId: number;
}

export interface NewPhonePayload {
  patientId: number;
  category: PhoneCategory;
  phoneNumber: string;
  primary: boolean;
  extension?: string;
}

export interface NewPhoneRequestDto {
  category: PhoneCategory;
  extension?: string;
  phone_number: string;
}

export interface PhoneEntryDto {
  attributes: {
    category: PhoneCategory;
    extension: string | null;
    phone_number: string;
    primary: boolean;
    updated_at: string;
  };
  id: string;
  type: string;
}
