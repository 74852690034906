import http from "@/http/http";
import { AxiosPromise } from "axios";
import {
  BiometricsRequestPayload,
  createBiometricsRequestDto,
} from "../../types/biometrics-request-payload";

function addBiometric(payload: BiometricsRequestPayload): AxiosPromise {
  return http.post(
    `/patients/${payload.patientId}/vitals`,
    createBiometricsRequestDto(payload)
  );
}

export default {
  addBiometric,
};
