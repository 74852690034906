import { HttpError } from "@/http/types/http-error.interface";
import { PatientProfile } from "./patient-profile";

export function createInitialPatientProfileState(): PatientProfileState {
  return {
    patientProfile: null,
    patientIdToInitiatePatientProfile: null,
    loading: false,
    error: null,
  };
}

export interface PatientProfileState {
  patientProfile: PatientProfile | null;
  patientIdToInitiatePatientProfile: number | null;
  loading: boolean;
  error: HttpError | null;
}
