
import { defineComponent } from "vue";
import MediaQueryLayout from "@/layouts/media-query-layout/media-query-layout.vue";
import PeopleIcon from "@/ui-library/icons/people-icon.vue";
import BaseTypography from "@/ui-library/base-typography/base-typography.vue";

export default defineComponent({
  components: {
    MediaQueryLayout,
    PeopleIcon,
    BaseTypography,
  },
  props: {
    buttonTitle: {
      type: String,
      required: true,
    },
    isCurrentlyActingCaregiver: {
      type: Boolean,
      required: true,
    },
  },
});
