import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withModifiers as _withModifiers, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_blood_pressure_icon = _resolveComponent("blood-pressure-icon")!
  const _component_side_menu_item = _resolveComponent("side-menu-item")!
  const _component_heart_rate_icon = _resolveComponent("heart-rate-icon")!
  const _component_blood_glucose_icon = _resolveComponent("blood-glucose-icon")!
  const _component_weight_icon = _resolveComponent("weight-icon")!
  const _component_height_icon = _resolveComponent("height-icon")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_side_menu_item, {
      isNestedItem: "",
      onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.goToMenuItem(_ctx.Routes.biometricsBloodPressure)), ["stop"]))
    }, {
      icon: _withCtx(() => [
        _createVNode(_component_blood_pressure_icon)
      ]),
      title: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.bloodPressureTitle), 1)
      ]),
      _: 1
    }),
    _createVNode(_component_side_menu_item, {
      isNestedItem: "",
      onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_ctx.goToMenuItem(_ctx.Routes.biometricsHeartRate)), ["stop"]))
    }, {
      icon: _withCtx(() => [
        _createVNode(_component_heart_rate_icon)
      ]),
      title: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.heartRateTitle), 1)
      ]),
      _: 1
    }),
    _createVNode(_component_side_menu_item, {
      isNestedItem: "",
      onClick: _cache[2] || (_cache[2] = _withModifiers(($event: any) => (_ctx.goToMenuItem(_ctx.Routes.biometricsBloodGlucose)), ["stop"]))
    }, {
      icon: _withCtx(() => [
        _createVNode(_component_blood_glucose_icon)
      ]),
      title: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.bloodGlucoseTitle), 1)
      ]),
      _: 1
    }),
    _createVNode(_component_side_menu_item, {
      isNestedItem: "",
      onClick: _cache[3] || (_cache[3] = _withModifiers(($event: any) => (_ctx.goToMenuItem(_ctx.Routes.biometricsWeight)), ["stop"]))
    }, {
      icon: _withCtx(() => [
        _createVNode(_component_weight_icon)
      ]),
      title: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.weightTitle), 1)
      ]),
      _: 1
    }),
    _createVNode(_component_side_menu_item, {
      isNestedItem: "",
      onClick: _cache[4] || (_cache[4] = _withModifiers(($event: any) => (_ctx.goToMenuItem(_ctx.Routes.biometricsHeight)), ["stop"]))
    }, {
      icon: _withCtx(() => [
        _createVNode(_component_height_icon)
      ]),
      title: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.heightTitle), 1)
      ]),
      _: 1
    })
  ], 64))
}