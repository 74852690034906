import { HttpError } from "@/http/types/http-error.interface";
import { Dependant } from "./dependant";

export function createInitialManageDependantsState(): ManageDependantsState {
  return {
    dependants: [],
    showSuccessfullyAddedDependant: false,
    isLoading: false,
    error: null,
  };
}

export interface ManageDependantsState {
  dependants: Dependant[];
  showSuccessfullyAddedDependant: boolean;
  isLoading: boolean;
  error: HttpError | null;
}
