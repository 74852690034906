import { TestResultItem } from "../types/test-result-item";
import { TestResultsState } from "../types/test-results-state";
import { TestResultItemDetail } from "../types/test-result-item-detail";
import { LineGraphData, LineGraphOptions } from "../../../types/line-graph";

export interface TestResultsGetters {
  isTestResultsLoading: (state: TestResultsState) => boolean;
  getTestResultsList: (state: TestResultsState) => TestResultItem[];
  getSelectedTestResult: (state: TestResultsState) => TestResultItem | null;
  getNumericTestResultDetails: (
    state: TestResultsState
  ) => TestResultItemDetail[];
  getStringTestResultDetails: (
    state: TestResultsState
  ) => TestResultItemDetail[];
  getTextTestResultDetails: (state: TestResultsState) => TestResultItemDetail[];
  getTestResultDetailGraphData: (
    state: TestResultsState
  ) => LineGraphData | null;
  getTestResultDetailGraphOptions: (
    state: TestResultsState
  ) => LineGraphOptions | null;
  getTestResultDetailGraphTitle: (state: TestResultsState) => string | null;
  getPdfTestResultDetails: (state: TestResultsState) => TestResultItemDetail[];
}

export const testResultsGetters: TestResultsGetters = {
  isTestResultsLoading(state) {
    return state.loading;
  },
  getTestResultsList(state) {
    return state.testResultsList;
  },
  getSelectedTestResult(state) {
    return state.selectedTestResult;
  },
  getNumericTestResultDetails(state) {
    return state.testResultDetails.filter(
      (item) => item.valueType === "numeric"
    );
  },
  getStringTestResultDetails(state) {
    return state.testResultDetails.filter(
      (item) => item.valueType === "string"
    );
  },
  getTextTestResultDetails(state) {
    return state.testResultDetails.filter((item) => item.valueType === "text");
  },
  getTestResultDetailGraphData(state) {
    return state.testResultLineGraphDetailsItem?.data || null;
  },
  getTestResultDetailGraphOptions(state) {
    return state.testResultLineGraphDetailsItem?.options || null;
  },
  getTestResultDetailGraphTitle(state) {
    return state.testResultLineGraphTitle;
  },
  getPdfTestResultDetails(state) {
    return state.testResultDetails.filter((item) => item.base64Pdf);
  },
};
