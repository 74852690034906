import http from "@/http/http";
import { AxiosPromise } from "axios";
import { MassCommunication } from "../types/mass-communications-state";

function fetchMassCommunications(patientId: number): AxiosPromise {
  return http.get<MassCommunication[]>(
    `patients/${patientId}/mass_communications`
  );
}

function acknowledgeCommunication(
  patientId: number,
  massCommunicationId: number
): AxiosPromise {
  return http.post(
    `patients/${patientId}/mass_communications/${massCommunicationId}/acknowledge_communication`
  );
}

export default {
  fetchMassCommunications,
  acknowledgeCommunication,
};
