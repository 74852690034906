import { RootState } from "@/store/types/root-state.type";
import { AuthState, createInitialAuthState } from "../types/auth-state";
import { AuthMutations, authMutations } from "./auth-mutations";
import { authActions, AuthActions } from "./auth-actions";
import { authGetters } from "./auth-getters";
import { AvaModule } from "@/store/modules/types/ava-module.interface";

export const AuthStoreModule: AvaModule<
  AuthState,
  RootState,
  AuthActions,
  AuthMutations,
  typeof authGetters
> = {
  state(): AuthState {
    return createInitialAuthState();
  },
  mutations: authMutations,
  actions: authActions,
  getters: authGetters,
};
