
import { computed, defineComponent, watch } from "vue";
import { router, Routes } from "@/router";
import { useStore } from "@/store";
import { ActionTypes } from "@/store/constants/action-types";
import { usePatientProfileGetters } from "@/features/patient-profile";
import ToolbarSideMenu from "./components/toolbar-side-menu.vue";
import { useManageDependantsManager } from "../shared-manage-dependants/composition/use-manage-dependants-manager";
import PatientProfileNameAndDependantList from "./components/patient-profile-name-and-dependant-list.vue";
import ConfirmDependantSelectionSuccessModal from "../shared-manage-dependants/components/confirm-dependant-selection-success-modal.vue";
import { useConfirmDependantSelectionModalManager } from "../shared-manage-dependants/composition/use-confirm-dependant-selection-modal-manager";
import ToolbarLayout from "./layout/toolbar-layout.vue";
import LogoutButtonContent from "./components/logout-button-content.vue";
import ToolbarRowButton from "./components/toolbar-row-button.vue";

export default defineComponent({
  setup() {
    const store = useStore();
    const dependants = computed(
      () => store.getters.getConfirmedDependantsWithPatientId
    );
    const { patientId, isActingAsCaregiver, patientName } =
      usePatientProfileGetters();
    const { chooseDependant, stopCaretakingMode } =
      useManageDependantsManager();

    const {
      isConfirmDependantSelectionModalOpen,
      toggleConfirmDependantSelectionModal,
      goToDashboardAndCloseModal,
    } = useConfirmDependantSelectionModalManager();

    const goToManageDependants = () => {
      router.goTo(Routes.manageDependants);
    };

    const goToDashboard = () => {
      router.goTo(Routes.dashboard);
    };
    const logout = () => store.dispatch(ActionTypes.AUTH__LOGOUT);

    watch([patientId, isActingAsCaregiver], (): void => {
      if (patientId.value && isActingAsCaregiver.value) {
        toggleConfirmDependantSelectionModal(true);
      }
    });

    return {
      isConfirmDependantSelectionModalOpen,
      toggleConfirmDependantSelectionModal,
      dependants,
      patientName,
      isActingAsCaregiver,
      goToManageDependants,
      logout,
      goToDashboard,
      goToDashboardAndCloseModal,
      chooseDependant,
      stopCaretakingMode,
    };
  },
  components: {
    ToolbarSideMenu,
    PatientProfileNameAndDependantList,
    ConfirmDependantSelectionSuccessModal,
    ToolbarLayout,
    LogoutButtonContent,
    ToolbarRowButton,
  },
});
