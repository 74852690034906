import { computed } from "vue";
import { store } from "@/store";
import capitalize from "lodash/capitalize";
import { useAuthStore } from "@/features/the-authentication";

export function usePatientProfileGetters() {
  const patientProfile = computed(
    () => store.getters.getPatientProfile || null
  );
  const patientId = computed(() => patientProfile.value?.id || null);
  const patientAvatarBase64 = computed(
    () => patientProfile.value?.base64Avatar
  );

  const { sessionPatientId } = useAuthStore();
  const isActingAsCaregiver = computed(
    () =>
      patientId.value !== null &&
      sessionPatientId.value !== null &&
      patientId.value !== sessionPatientId.value
  );
  const patientName = computed(() =>
    capitalize(patientProfile.value?.firstName || "")
  );
  const patientLastName = computed(() =>
    capitalize(patientProfile.value?.lastName || "")
  );
  const patientFullName = computed(
    () => `${patientName.value} ${patientLastName.value}`
  );

  return {
    patientId,
    patientAvatarBase64,
    isActingAsCaregiver,
    patientProfile,
    patientName,
    patientFullName,
  };
}
