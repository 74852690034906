import { ActionTypes } from "@/store/constants/action-types";
import { MutationTypes } from "@/store/constants/mutation-types";
import { AvaStore } from "@/store/types/store.type";
import { AvaActionContext } from "@/store/modules/types/ava-action-context";
import { AuthState } from "../../types/auth-state";
import authApi from "../../services/auth-services";
import { createErrorToastMessage } from "../../../toast-messages/types/toast-message";
import { AuthorizeEditAccountSettingsPayload } from "../../types/account-settings-security/authorize-edit-account-settings-payload";
import { HttpError } from "@/http/types/http-error.interface";

export interface AuthActionsEditAccount {
  [ActionTypes.AUTH__AUTHORIZE_TO_EDIT_ACCOUNT_SETTINGS]: (
    context: AvaActionContext<AuthState, AvaStore>,
    payload: AuthorizeEditAccountSettingsPayload
  ) => Promise<void>;
}

export const authActionsEditAccount: AuthActionsEditAccount = {
  async [ActionTypes.AUTH__AUTHORIZE_TO_EDIT_ACCOUNT_SETTINGS](store, payload) {
    try {
      store.commit(MutationTypes.AUTH__TOGGLE_LOADING, true);
      const result = await authApi.authorizeToEditAccountSettings(payload);
      store.commit(
        MutationTypes.AUTH__SET_IS_AUTHORIZED_T0_EDIT_ACCOUNT_SETTINGS,
        true
      );
      store.commit(
        MutationTypes.AUTH__SET_TWO_FACTOR_QR_CODE_URI,
        result.data.data.attributes.provisioning_uri
      );
      store.commit(
        MutationTypes.AUTH__SET_IS_TWO_FACTOR_AUTHENTICATION_ENABLED,
        result.data.data.attributes.two_factor_enabled
      );
    } catch (error) {
      store.commit(MutationTypes.AUTH__SET_ERROR, error as HttpError);
      store.dispatch(
        ActionTypes.TOAST_NEW_MESSAGE,
        createErrorToastMessage("Failed to authorize")
      );
    } finally {
      store.commit(MutationTypes.AUTH__TOGGLE_LOADING, false);
    }
  },
};
