
import { defineComponent } from "vue";
import { Routes } from "@/router";
import SecurityIcon from "@/ui-library/icons/security-icon.vue";
import DependantsIcon from "@/ui-library/icons/dependants-icon.vue";
// import ReceiptIcon from '@/ui-library/icons/receipt-icon.vue';
import SideMenuItem from "./side-menu-item.vue";

export default defineComponent({
  emits: {
    goToRoute: (route: Routes) => Boolean(route),
  },
  components: {
    SideMenuItem,
    SecurityIcon,
    DependantsIcon,
    // ReceiptIcon
  },
  setup(props, context) {
    const goToMenuItem = (route: Routes) => {
      context.emit("goToRoute", route);
    };
    return {
      manageDependantsTitle: "Manage Dependants",
      securityTitle: "Security",
      subscriptionsTitle: "Subscriptions and Payment",
      Routes,
      goToMenuItem,
    };
  },
});
