import axios, { AxiosError, InternalAxiosRequestConfig } from "axios";
import {
  isResponseSuccessful,
  createPatientApiBaseUrl,
} from "./types/http.functions";
import { decodeUrl, setConfigHeaderWithAuthToken } from "./interceptors";
import {
  createHttpError,
  navigateUserAccordingToError,
} from "./types/http-error.functions";
import { HttpError } from "./types/http-error.interface";
import { Http } from "./types/http.interface";
import { storeAuthTokenOnLocalDevice } from "../features/the-authentication/services/types/auth-services.functions";
import { AvaApiErrorData } from "./types/ava-api-error-data.interface";

const http = axios.create({
  baseURL: createPatientApiBaseUrl(),
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

http.interceptors.response.use(
  (response) => {
    storeAuthTokenOnLocalDevice(response);
    return response;
  },
  (error: AxiosError<AvaApiErrorData>): Promise<HttpError> => {
    const httpError = createHttpError(error);
    navigateUserAccordingToError(httpError);
    return Promise.reject(httpError);
  }
);

http.interceptors.request.use(
  (config: InternalAxiosRequestConfig) => {
    return decodeUrl(setConfigHeaderWithAuthToken(config));
  },
  (error: AxiosError) => Promise.reject(error)
);

export default {
  ...http,
  isResponseSuccessful,
  createPatientApiBaseUrl,
} as Http;
