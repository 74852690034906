import { ActionTypes } from "@/store/constants/action-types";
import { MutationTypes } from "@/store/constants/mutation-types";
import { AvaStore } from "@/store/types/store.type";
import { AvaActionContext } from "@/store/modules/types/ava-action-context";
import { router, Routes } from "@/router";
import { AuthState } from "../../types/auth-state";
import authApi from "../../services/auth-services";
import { ChangeForgottenPasswordRequestPayload } from "../../types/account-settings-security/change-forgotten-password-request-payload";
import { ChangePasswordRequestPayload } from "../../types/account-settings-security/change-password-request-payload";
import { HttpError } from "@/http/types/http-error.interface";

export interface AuthActionsResetPassword {
  [ActionTypes.AUTH__EMAIL_RESET_PASSWORD_TOKEN]: (
    context: AvaActionContext<AuthState, AvaStore>,
    payload: string
  ) => Promise<void>;
  [ActionTypes.AUTH__CHANGE_FORGOTTEN_PASSWORD]: (
    context: AvaActionContext<AuthState, AvaStore>,
    payload: ChangeForgottenPasswordRequestPayload
  ) => Promise<void>;
  [ActionTypes.AUTH__CHANGE_PASSWORD]: (
    context: AvaActionContext<AuthState, AvaStore>,
    payload: ChangePasswordRequestPayload
  ) => Promise<void>;
}

export const authActionsResetPassword: AuthActionsResetPassword = {
  async [ActionTypes.AUTH__EMAIL_RESET_PASSWORD_TOKEN](store, email) {
    try {
      store.commit(MutationTypes.AUTH__TOGGLE_LOADING, true);
      await authApi.emailPasswordResetToken(email);
      router.goTo(Routes.resetPassword, { query: { email } });
    } catch (error) {
      store.commit(MutationTypes.AUTH__SET_ERROR, error as HttpError);
    } finally {
      store.commit(MutationTypes.AUTH__TOGGLE_LOADING, false);
    }
  },
  async [ActionTypes.AUTH__CHANGE_FORGOTTEN_PASSWORD](store, payload) {
    try {
      store.commit(MutationTypes.AUTH__TOGGLE_LOADING, true);
      await authApi.changeForgottenPassword(payload);
      router.goTo(Routes.login);
      store.dispatch(
        ActionTypes.TOAST_SIMPLE_MESSAGE,
        "Successfully changed password"
      );
    } catch (error) {
      store.commit(MutationTypes.AUTH__SET_ERROR, error as HttpError);
    } finally {
      store.commit(MutationTypes.AUTH__TOGGLE_LOADING, false);
    }
  },
  async [ActionTypes.AUTH__CHANGE_PASSWORD](store, payload) {
    try {
      store.commit(MutationTypes.AUTH__TOGGLE_LOADING, true);
      await authApi.changePassword(payload);
      store.commit(
        MutationTypes.AUTH__SET_IS_AUTHORIZED_T0_EDIT_ACCOUNT_SETTINGS,
        false
      );
      store.dispatch(
        ActionTypes.TOAST_SIMPLE_MESSAGE,
        "Successfully updated password"
      );
    } catch (error) {
      store.commit(MutationTypes.AUTH__SET_ERROR, error as HttpError);
    } finally {
      store.commit(MutationTypes.AUTH__TOGGLE_LOADING, false);
    }
  },
};
