import { RouteRecordRaw } from "vue-router";
import { Routes } from "@/router/types/routes.enum";
import MainLayout from "@/layouts/page-layouts/main-layout.vue";

const EntryPointBiometricResultsPage = () =>
  import(
    /* webpackChunkName: "biometrics_index_page" */ "@/pages/biometrics/biometrics.vue"
  );

const BloodPressureGraph = () =>
  import(
    /* webpackChunkName: "biometrics_blood_pressure_index_page" */ "@/pages/biometrics/pages/blood-pressure-graph/blood-pressure-graph.vue"
  );

const BloodGlucoseGraph = () =>
  import(
    /* webpackChunkName: "biometrics_blood_glucose_index_page" */ "@/pages/biometrics/pages/blood-glucose-graph/blood-glucose-graph.vue"
  );

const HeartRateGraph = () =>
  import(
    /* webpackChunkName: "biometrics_heart_rate_index_page" */ "@/pages/biometrics/pages/heart-rate-graph/heart-rate-graph.vue"
  );

const WeightGraph = () =>
  import(
    /* webpackChunkName: "biometrics_weight_index_page" */ "@/pages/biometrics/pages/weight-graph/weight-graph.vue"
  );

const HeightGraph = () =>
  import(
    /* webpackChunkName: "biometrics_height_index_page" */ "@/pages/biometrics/pages/height-graph/height-graph.vue"
  );

export const biometricChartRoute: RouteRecordRaw = {
  path: "/biometrics",
  component: EntryPointBiometricResultsPage,
  name: Routes.biometrics,
  meta: {
    layout: MainLayout,
    isProtected: true,
  },
  children: [
    {
      path: "blood-pressure",
      name: Routes.biometricsBloodPressure,
      component: BloodPressureGraph,
      meta: {
        layout: MainLayout,
        isProtected: true,
      },
    },
    {
      path: "blood-glucose",
      name: Routes.biometricsBloodGlucose,
      component: BloodGlucoseGraph,
      meta: {
        layout: MainLayout,
        isProtected: true,
      },
    },
    {
      path: "heart-rate",
      name: Routes.biometricsHeartRate,
      component: HeartRateGraph,
      meta: {
        layout: MainLayout,
        isProtected: true,
      },
    },
    {
      path: "height",
      name: Routes.biometricsHeight,
      component: HeightGraph,
      meta: {
        layout: MainLayout,
        isProtected: true,
      },
    },
    {
      path: "weight",
      name: Routes.biometricsWeight,
      component: WeightGraph,
      meta: {
        layout: MainLayout,
        isProtected: true,
      },
    },
  ],
};
