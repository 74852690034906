import { AxiosResponse } from "axios";
import { JwtPayload } from "./types/jwt-payload.interface";

export function parseJwt(token: string): JwtPayload {
  const base64Url = token.split(".")[1];
  const base64 = base64Url?.replace(/-/g, "+").replace(/_/g, "/");
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );
  return JSON.parse(jsonPayload);
}

export function getJwtExpiry(token: string | null): number | null {
  if (token) {
    const expiryInSeconds = parseJwt(token).exp;
    return expiryInSeconds ? expiryInSeconds * 1000 : null;
  }
  return null;
}

export function getJwtExpiryFromHttpResult(
  response: AxiosResponse<unknown>
): number | null {
  const token = response.headers.authorization;
  return getJwtExpiry(token);
}
