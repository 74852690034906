import { RouteRecordRaw } from "vue-router";
import MainLayout from "@/layouts/page-layouts/main-layout.vue";
import SingleFocusLayout from "@/layouts/page-layouts/single-focus-layout.vue";
import { Routes } from "@/router";

const SubscriptionPageRouterViewEntry = () =>
  import(
    /* webpackChunkName: "the_subscriptions_page" */ "@/pages/subscriptions/components/subscription-router-view.vue"
  );

const SubscriptionPage = () =>
  import(
    /* webpackChunkName: "the_subscriptions_page" */ "@/pages/subscriptions/subscriptions.vue"
  );

const SuccessfullyPaidPage = () =>
  import(
    /* webpackChunkName: "the_payment_is_successful_page" */ "@/pages/subscriptions/pages/successfully-paid/successfully-paid.vue"
  );

export const subscriptionRoute: RouteRecordRaw = {
  path: "/subscriptions",
  component: SubscriptionPageRouterViewEntry,
  children: [
    {
      path: "",
      name: Routes.subscriptions,
      component: SubscriptionPage,
      meta: {
        layout: MainLayout,
        isProtected: true,
      },
    },
    {
      path: "successfully-paid",
      name: Routes.successfullyPaid,
      component: SuccessfullyPaidPage,
      meta: {
        layout: SingleFocusLayout,
        isProtected: false,
      },
    },
  ],
};
