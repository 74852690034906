
import { defineComponent } from "vue";
import PatientAvatar from "@/components/patient-avatar.vue";
import { usePatientProfileGetters } from "@/features/patient-profile";
import BaseTypography from "@/ui-library/base-typography/base-typography.vue";

export default defineComponent({
  components: {
    PatientAvatar,
    BaseTypography,
  },
  setup() {
    const { patientName, patientAvatarBase64 } = usePatientProfileGetters();
    return {
      patientAvatarBase64,
      patientName,
    };
  },
});
