
import { computed, defineComponent, StyleValue } from "vue";

export default defineComponent({
  props: {
    inheritColor: {
      type: Boolean,
      default: false,
    },
    noMargin: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Boolean,
      default: false,
    },
    caution: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    bold: {
      type: Boolean,
      default: false,
    },
    capitalize: {
      type: Boolean,
      default: false,
    },
    italics: {
      type: Boolean,
      default: false,
    },
    textAlign: {
      type: String,
      default: "",
    },
    spacer: {
      type: Boolean,
      required: false,
    },
    h1: {
      type: Boolean,
      required: false,
    },
    h2: {
      type: Boolean,
      required: false,
    },
    h3: {
      type: Boolean,
      required: false,
    },
    h4: {
      type: Boolean,
      required: false,
    },
    h5: {
      type: Boolean,
      required: false,
    },
    h6: {
      type: Boolean,
      required: false,
    },
    subtitle1: {
      type: Boolean,
      required: false,
    },
    subtitle2: {
      type: Boolean,
      required: false,
    },
    heading1: {
      type: Boolean,
      required: false,
    },
    body1: {
      type: Boolean,
      required: false,
    },
    body2: {
      type: Boolean,
      required: false,
    },
    caption: {
      type: Boolean,
      required: false,
    },
    titleVariant: {
      type: String,
      default: "h1",
    },
  },
  setup(props) {
    const classes = computed(() => ({
      error: props.error || props.caution,
      disabled: props.disabled,
      bold: props.bold,
      spacer: props.spacer,
      h1: props.h1,
      h2: props.h2,
      h3: props.h3,
      h4: props.h4,
      h5: props.h5,
      h6: props.h6,
      subtitle1: props.subtitle1,
      subtitle2: props.subtitle2,
      heading1: props.heading1,
      body1: props.body1,
      body2: props.body2,
      caption: props.caption,
      capitalize: props.capitalize,
      italics: props.italics,
      titleVariant: props.titleVariant,
      "no-margin": props.noMargin,
      "inherit-color": props.inheritColor,
    }));
    const styles = computed(
      () => ({ textAlign: props.textAlign } as StyleValue)
    );
    return {
      classes,
      styles,
    };
  },
});
