import { Ref } from "vue";
import { router } from "../../../router";
import { useSessionStore } from "../../../store/session-store";

export function useHandleLandingPageNavigation(isProtected: Ref<boolean>) {
  const sessionStore = useSessionStore();
  const currentPath = router.currentRoute.value.path;
  if (isProtected.value) {
    sessionStore.setLandingPage(currentPath);
  }
}
