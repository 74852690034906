import http from "@/http/http";
import { AxiosPromise } from "axios";
import { AddDependantFormRequestDto } from "../types/add-dependant-form-payload";
import { DependantDto } from "../types/dependant";

function getDependants(
  patientUserId: number
): AxiosPromise<{ patient_user_dependents: DependantDto[] }> {
  return http.get<{ patient_user_dependents: DependantDto[] }>(
    `/patient_users/${patientUserId}/patient_user_dependents`
  );
}
function addDependant(
  patientUserId: number,
  requestDto: AddDependantFormRequestDto
): AxiosPromise<{ patient_user_dependent: DependantDto }> {
  return http.post<{ patient_user_dependent: DependantDto }>(
    `/patient_users/${patientUserId}/patient_user_dependents`,
    requestDto
  );
}

function deleteDependant(
  patientUserId: number,
  patientUserDependantId: number
): AxiosPromise {
  return http.delete(
    `/patient_users/${patientUserId}/patient_user_dependents/${patientUserDependantId}`
  );
}

export default {
  addDependant,
  deleteDependant,
  getDependants,
};
