import { HttpError } from "@/http/types/http-error.interface";
import { MutationTypes } from "@/store/constants/mutation-types";
import { Dependant } from "./types/dependant";
import { ManageDependantsState } from "./types/manage-dependants-state";

export const manageDependantsMutations = {
  [MutationTypes.MANAGE_DEPENDANTS__SET_DEPENDANTS](
    state: ManageDependantsState,
    dependants: Dependant[]
  ) {
    state.dependants = dependants;
  },
  [MutationTypes.MANAGE_DEPENDANTS__ADD_DEPENDANT](
    state: ManageDependantsState,
    dependant: Dependant
  ) {
    state.dependants.push(dependant);
    state.showSuccessfullyAddedDependant = true;
  },
  [MutationTypes.MANAGE_DEPENDANTS__DELETE_DEPENDANT](
    state: ManageDependantsState,
    patientUserDependantId: number
  ) {
    const dependantIndex = state.dependants.findIndex(
      (dependant) => dependant.id === patientUserDependantId
    );
    if (dependantIndex !== -1) {
      state.dependants.splice(dependantIndex, 1);
    }
  },
  [MutationTypes.MANAGE_DEPENDANTS__TOGGLE_SUCCESSFULLY_ADDED_DEPENDANT](
    state: ManageDependantsState,
    status: boolean
  ) {
    state.showSuccessfullyAddedDependant = status;
  },
  [MutationTypes.MANAGE_DEPENDANTS__TOGGLE_LOADING](
    state: ManageDependantsState,
    isLoading: boolean
  ) {
    state.isLoading = isLoading;
  },
  [MutationTypes.MANAGE_DEPENDANTS__SET_ERROR](
    state: ManageDependantsState,
    error: HttpError | null
  ) {
    state.error = error;
  },
};

export type ManageDependantsMutations = typeof manageDependantsMutations;
