import { AxiosResponse } from "axios";
import { Environment } from "../../types/environment";

function createBaseURL(): string {
  switch (process.env.NODE_ENV as Environment) {
    case "development":
      if (process.env.VUE_APP_API_PORT) {
        return `http://localhost:${process.env.VUE_APP_API_PORT}`;
      }
      console.error(
        "Vue app api port not defined. Possible cause: .ENV file not set"
      );
      return "Error";

    case "developRemote":
      return `https://dev-www.avaemr.ca`;
    case "testing":
      return "https://qa-www.avaemr.ca";
    case "staging":
      return "https://staging-www.avaemr.ca";
    case "production":
      return "https://www.avaemr.ca";
    case "demo":
      return "https://www.avademo.ca";
    default:
      return `http://localhost:${process.env.VUE_APP_API_PORT}`;
  }
}

export function createPatientApiBaseUrl(): string {
  return `${createBaseURL()}/patient_api/v1`;
}

export function isResponseSuccessful(response: AxiosResponse): boolean {
  return response.status > 199 && response.status < 300;
}
