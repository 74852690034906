import { AxiosPromise, AxiosResponse } from "axios";
import { ActionTypes } from "@/store/constants/action-types";
import { MutationTypes } from "@/store/constants/mutation-types";
import { AvaStore } from "@/store/types/store.type";
import { AvaActionContext } from "@/store/modules/types/ava-action-context";
import { getJwtExpiryFromHttpResult } from "@/utils/jwt.functions";
import { SessionResponseDto } from "../../types/auth-session/auth-session.interface";
import { AuthState } from "../../types/auth-state";
import { SignInPayload } from "../../types/sign-in-payload";
import authApi from "../../services/auth-services";
import { storeAuthTokenOnLocalDevice } from "../../services/types/auth-services.functions";
import { EditTwoFactorRequestPayload } from "../../types/account-settings-security/edit-two-factor-request-payload";

export interface AuthActionsJwt {
  [ActionTypes.AUTH__SET_JWT_FROM_HTTP]: (
    context: AvaActionContext<AuthState, AvaStore>,
    payload: AxiosResponse<unknown>
  ) => void;
  [ActionTypes.AUTH__CREATE_AUTH_SESSION__UPDATES_JWT]: (
    context: AvaActionContext<AuthState, AvaStore>,
    payload: SignInPayload
  ) => AxiosPromise<SessionResponseDto>;
  [ActionTypes.AUTH__AUTH__SUBMIT_TWO_FACTOR_CODE__UPDATES_JWT]: (
    context: AvaActionContext<AuthState, AvaStore>,
    payload: string
  ) => AxiosPromise<SessionResponseDto>;
  [ActionTypes.AUTH__UPDATE_JWT_EXPIRY_IF_NECESSARY]: (
    context: AvaActionContext<AuthState, AvaStore>,
    payload: number | null
  ) => void;
  [ActionTypes.AUTH__RENEW_JWT]: (
    context: AvaActionContext<AuthState, AvaStore>
  ) => AxiosPromise<SessionResponseDto>;
  [ActionTypes.AUTH__EDIT_TWO_FACTOR_SETTING__UPDATES_JWT]: (
    context: AvaActionContext<AuthState, AvaStore>,
    payload: EditTwoFactorRequestPayload
  ) => void;
}

export const authActionsJwt: AuthActionsJwt = {
  [ActionTypes.AUTH__SET_JWT_FROM_HTTP](store, payload) {
    storeAuthTokenOnLocalDevice(payload);
    store.commit(
      MutationTypes.AUTH__UPDATE_JWT_EXPIRY_MS_SINCE_EPOCH,
      getJwtExpiryFromHttpResult(payload) || null
    );
  },
  async [ActionTypes.AUTH__CREATE_AUTH_SESSION__UPDATES_JWT](store, payload) {
    const response = await authApi.createSession__retrievesNewJwt(payload);
    store.dispatch(ActionTypes.AUTH__SET_JWT_FROM_HTTP, response);
    return response;
  },
  async [ActionTypes.AUTH__AUTH__SUBMIT_TWO_FACTOR_CODE__UPDATES_JWT](
    store,
    payload
  ) {
    const response = await authApi.submitTwoFactorCode__retrievesNewJwt(
      payload
    );
    store.dispatch(ActionTypes.AUTH__SET_JWT_FROM_HTTP, response);
    return response;
  },
  [ActionTypes.AUTH__UPDATE_JWT_EXPIRY_IF_NECESSARY](store, expiry) {
    if (expiry) {
      store.commit(
        MutationTypes.AUTH__UPDATE_JWT_EXPIRY_MS_SINCE_EPOCH,
        expiry
      );
    }
  },
  //: USE RENEW JWT
  async [ActionTypes.AUTH__RENEW_JWT](store) {
    const response = await authApi.renewJwt__retrievesNewJwt();
    store.dispatch(ActionTypes.AUTH__SET_JWT_FROM_HTTP, response);
    return response;
  },
  async [ActionTypes.AUTH__EDIT_TWO_FACTOR_SETTING__UPDATES_JWT](
    store,
    payload
  ) {
    const response = await authApi.changeTwoFactor(payload);
    store.dispatch(ActionTypes.AUTH__SET_JWT_FROM_HTTP, response);
  },
};
