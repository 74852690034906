
import { defineComponent } from "vue";
import iconWrapper from "./icon-wrapper.vue";

export default defineComponent({
  components: { iconWrapper },
  setup() {
    return {
      icon: '<span class="material-icons">menu</span>',
    };
  },
});
