
import { computed, defineComponent } from "vue";
import { useStore } from "@/store";
import { MutationTypes } from "@/store/constants/mutation-types";
import BaseTransitionGroupFlyDownUp from "@/ui-library/base-transition-group/base-transition-group-fly-down-up.vue";
import ToastMessage from "./components/toast-message.vue";

export default defineComponent({
  setup() {
    const store = useStore();
    const removeMessage = (msg: string) => {
      store.commit(MutationTypes.TOAST__REMOVE_SINGLE_MESSAGE, msg);
    };

    return {
      toastMessages: computed(() => store.getters.getToastMessages),
      removeMessage,
    };
  },
  components: {
    BaseTransitionGroupFlyDownUp,
    ToastMessage,
  },
});
