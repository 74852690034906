import { computed, watchEffect } from "vue";
import { useSessionStore } from "../../../store/session-store";
import { usePatientProfileStore } from "../../../store/patient-profile-store";
import { LocalStorageItems } from "../../../types/auth-local-storage/auth-local-storage.enums";

export function useFetchUserPatientProfile() {
  const patientProfileStore = usePatientProfileStore();
  const sessionStore = useSessionStore();
  const sessionOwnerPatientId = computed(
    () => sessionStore.getSessionOwnerPatientId
  );

  const defaultPatientId = parseInt(
    localStorage
      .getItem(LocalStorageItems.patientIdLastRetrieved)
      ?.toString() || ""
  );
  const patientId = computed(
    () => defaultPatientId || sessionOwnerPatientId.value
  );

  watchEffect(() => {
    if (patientId.value) {
      patientProfileStore.fetchPatientProfile(patientId.value);
    }
  });
}
