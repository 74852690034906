import { Provinces } from "@/utils/provinces.constants";
import { ClinicContactInformation } from "./clinic-contact-information";
import { OnlineBookingSetting } from "./online-booking-setting";

export function createClinicContactInformationFromAuthorizedClinicDto(
  dto: AuthorizedClinicDto
): ClinicContactInformation {
  return {
    id: dto.clinic.id,
    name: dto.clinic.name,
    address1: dto.clinic.address_1,
    address2: dto.clinic.address_2,
    city: dto.clinic.city,
    province: dto.clinic.province,
    country: dto.clinic.country,
    postalCode: dto.clinic.postal_code,
    phonePrimary: dto.clinic.phone_primary,
    phoneSecondary: dto.clinic.phone_secondary,
    fax: dto.clinic.fax,
    authorizedClinicId: dto.id,
    isDeauthorized: dto.active === false,
  };
}

export interface AuthorizedClinicDto {
  id: number;
  active: boolean;
  clinic: {
    id: number;
    name: string;
    address_1: string | null;
    address_2: string | null;
    city: string | null;
    province: Provinces | null;
    country: string | null;
    postal_code: string | null;
    phone_primary: string | null;
    phone_secondary: string | null;
    fax: string | null;
    online_booking: OnlineBookingSetting | null;
    accepting_patients: boolean;
    time_zone: string | null;
    accepts_walk_ins: boolean;
    business_hours: object[];
  };
}
