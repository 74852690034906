import { HttpError } from "@/http/types/http-error.interface";
import { MutationTypes } from "@/store/constants/mutation-types";
import { AuthSession } from "../types/auth-session/auth-session.interface";
import { AuthState } from "../types/auth-state";
import { createInitialAuthState } from "../types/auth-state";

export const authMutations = {
  [MutationTypes.AUTH__SET_SESSION](state: AuthState, newSession: AuthSession) {
    state.session = newSession;
  },
  [MutationTypes.AUTH__UPDATE_SESSION](
    state: AuthState,
    partialSession: Partial<AuthSession>
  ) {
    state.session = { ...state.session, ...partialSession };
  },
  [MutationTypes.AUTH__CLEAR_SESSION](state: AuthState) {
    state.session = createInitialAuthState().session;
    return state;
  },
  [MutationTypes.AUTH__TOGGLE_LOADING](state: AuthState, isLoading: boolean) {
    state.loading = isLoading;
  },
  [MutationTypes.AUTH__SET_ERROR](state: AuthState, error: HttpError | null) {
    state.error = error;
  },
  [MutationTypes.AUTH__SET_EMAIL_CONFIRMED](state: AuthState, status: boolean) {
    state.session = {
      ...state.session,
      emailConfirmed: status,
    };
  },
  [MutationTypes.AUTH__SET_IS_AUTHORIZED_T0_EDIT_ACCOUNT_SETTINGS](
    state: AuthState,
    status: boolean
  ) {
    state.isAuthorizedToEditAccountSettings = status;
  },
  [MutationTypes.AUTH__SET_TWO_FACTOR_QR_CODE_URI](
    state: AuthState,
    uri: string
  ) {
    state.twoFactorQrCodeUri = uri;
  },
  [MutationTypes.AUTH__SET_IS_TWO_FACTOR_AUTHENTICATION_ENABLED](
    state: AuthState,
    status: boolean
  ) {
    state.session = {
      ...state.session,
      twoFactorEnabled: status,
    };
  },
  [MutationTypes.AUTH__SET_IS_TWO_FACTOR_AUTHENTICATED](
    state: AuthState,
    status: boolean
  ) {
    state.isTwoFactorAuthenticated = status;
  },
  [MutationTypes.AUTH__SET_VALID_JWT_TOKEN_IS_FETCHED](
    state: AuthState,
    status: boolean
  ) {
    state.validJwtTokenIsFetched = status;
  },
  [MutationTypes.AUTH__UPDATE_JWT_EXPIRY_MS_SINCE_EPOCH](
    state: AuthState,
    expiry: number | null
  ) {
    state.jwtExpiryMillisecondsSinceEpoch = expiry;
  },
  [MutationTypes.AUTH__UPDATE_LANDING_PAGE](state: AuthState, path: string) {
    state.landingPage = path;
  },
};

export type AuthMutations = typeof authMutations;
