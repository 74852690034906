import { resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_toast_messages = _resolveComponent("toast-messages")!
  const _component_patient_connect_clinics_popup = _resolveComponent("patient-connect-clinics-popup")!
  const _component_app_page_transition = _resolveComponent("app-page-transition")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_layout_provided_by_route = _resolveComponent("layout-provided-by-route")!
  const _component_route_protector = _resolveComponent("route-protector")!
  const _component_RootTheme = _resolveComponent("RootTheme")!

  return (_openBlock(), _createBlock(_component_RootTheme, null, {
    default: _withCtx(() => [
      _createVNode(_component_toast_messages),
      _createVNode(_component_patient_connect_clinics_popup),
      _createVNode(_component_route_protector, {
        isProtected: Boolean(_ctx.$route.meta.isProtected)
      }, {
        default: _withCtx(() => [
          _createVNode(_component_layout_provided_by_route, null, {
            default: _withCtx(() => [
              _createVNode(_component_router_view, null, {
                default: _withCtx(({ Component }) => [
                  _createVNode(_component_app_page_transition, null, {
                    default: _withCtx(() => [
                      (_openBlock(), _createBlock(_resolveDynamicComponent(Component)))
                    ]),
                    _: 2
                  }, 1024)
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["isProtected"])
    ]),
    _: 1
  }))
}