
import { computed, defineComponent, PropType } from "vue";
import IconWrapper from "./icon-wrapper.vue";

export default defineComponent({
  components: { IconWrapper },
  props: {
    direction: {
      type: String as PropType<"down" | "up">,
      default: () => "up",
    },
  },
  setup(props) {
    return {
      icon: computed(() =>
        props.direction === "down"
          ? '<i class="material-icons">expand_more</i>'
          : '<i class="material-icons">expand_less</i>'
      ),
    };
  },
});
