
import { computed, defineComponent, PropType } from "vue";
import BaseTypography from "@/ui-library/base-typography/base-typography.vue";
import BaseListItem from "@/ui-library/base-list-item/base-list-item.vue";
import BaseDropDownList from "@/ui-library/base-drop-down-list/base-drop-down-list.vue";
import { Dependant } from "@/features/shared-manage-dependants/store/types/dependant";
import PatientProfileDependantButton from "./patient-profile-dependant-button.vue";
import ToolbarRowButton from "./toolbar-row-button.vue";
import ChevronIcon from "@/ui-library/icons/chevron-icon.vue";

export default defineComponent({
  emits: {
    goToManageDependants: null,
    stopCaretakingMode: null,
    chooseDependant: (dependant: Dependant) => Boolean(dependant.id),
  },
  props: {
    isCurrentlyActingCaregiver: {
      type: Boolean,
      required: true,
    },
    patientProfileName: {
      type: String,
      required: true,
    },
    dependants: {
      type: Array as PropType<Dependant[]>,
    },
  },
  setup(props) {
    const caregiverNamePhrase = computed(
      () => `You are acting on behalf of ${props.patientProfileName}`
    );
    return {
      myProfileTitle: "My profile",
      manageDependantsTitle: "Manage dependants",
      namePhrase: computed(() =>
        props.isCurrentlyActingCaregiver
          ? caregiverNamePhrase.value
          : props.patientProfileName
      ),
    };
  },
  components: {
    BaseTypography,
    BaseListItem,
    BaseDropDownList,
    PatientProfileDependantButton,
    ToolbarRowButton,
    ChevronIcon,
  },
});
