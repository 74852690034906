import { HttpError } from "@/http/types/http-error.interface";
import { MutationTypes } from "@/store/constants/mutation-types";
import { ClinicContactInformation } from "./types/clinic-contact-information";
import { ManageClinicsState } from "./types/manage-clinics-state";

export const manageClinicsMutations = {
  [MutationTypes.MANAGE_CLINICS__SET_AUTHORIZED_CLINICS](
    state: ManageClinicsState,
    clinics: ClinicContactInformation[]
  ) {
    state.clinics = clinics;
  },
  [MutationTypes.MANAGE_CLINICS__SET_NEW_AUTHORIZED_CLINIC](
    state: ManageClinicsState,
    clinic: ClinicContactInformation
  ) {
    state.clinics.push(clinic);
  },
  [MutationTypes.MANAGE_CLINICS__DELETE_CLINIC](
    state: ManageClinicsState,
    authorizedClinicId: number
  ) {
    const clinicIndex = state.clinics.findIndex(
      (clinic) => clinic.authorizedClinicId === authorizedClinicId
    );
    if (clinicIndex !== -1) {
      state.clinics.splice(clinicIndex, 1);
    }
  },
  [MutationTypes.MANAGE_CLINICS__SET_SEARCHED_CLINICS](
    state: ManageClinicsState,
    clinics: ClinicContactInformation[]
  ) {
    state.searchedClinics = clinics;
  },
  [MutationTypes.MANAGE_CLINICS__TOGGLE_LOADING](
    state: ManageClinicsState,
    isLoading: boolean
  ) {
    state.loading = isLoading;
  },
  [MutationTypes.MANAGE_CLINICS__SET_ERROR](
    state: ManageClinicsState,
    error: HttpError | null
  ) {
    state.error = error;
  },
};

export type ManageClinicsMutations = typeof manageClinicsMutations;
