import { computed } from "vue";
import { store } from "@/store";
import { isRegistrationCompleteBySession } from "../types/auth-session/auth-session.functions";

export function useAuthStore() {
  const authSession = computed(() => store.getters.getAuthSession);
  const sessionPatientId = computed(
    () => store.getters.getAuthSession.patientId
  );
  const patientUserId = computed(() => store.getters.getPatientUserId || null);
  const isAuthLoading = computed(() => store.getters.isAuthLoading);
  const isEmailConfirmed = computed(() => store.getters.isEmailConfirmed);
  const isPatientConfirmed = computed(() => store.getters.isPatientConfirmed);
  const isRegistrationComplete = computed(() =>
    isRegistrationCompleteBySession(authSession.value)
  );
  const isClinicConfirmationNecessary = computed(() =>
    Boolean(isEmailConfirmed.value)
  );
  const isTwoFactorAuthenticationEnabled = computed(
    () => store.getters.isTwoFactorAuthenticationEnabled
  );
  return {
    isAuthLoading,
    patientUserId,
    authSession,
    sessionPatientId,
    isEmailConfirmed,
    isPatientConfirmed,
    isRegistrationComplete,
    isClinicConfirmationNecessary,
    isTwoFactorAuthenticationEnabled,
  };
}
