import { Provinces } from "@/utils/provinces.constants";
import { ClinicContactInformation } from "./clinic-contact-information";

export function createClinicContactInformationFromSearchedClinicDto(
  dto: SearchedClinicDto
): ClinicContactInformation {
  return {
    id: dto.id,
    name: dto.name,
    address1: dto.address_1,
    address2: dto.address_2,
    city: dto.city,
    province: dto.province,
    country: dto.country,
    postalCode: dto.postal_code,
    phonePrimary: dto.phone_primary,
    phoneSecondary: dto.phone_secondary,
    fax: dto.fax,
  };
}

export interface SearchedClinicDto {
  id: number;
  name: string | null;
  address_1: string | null;
  address_2: string | null;
  city: string | null;
  province: Provinces | null;
  country: string | null;
  postal_code: string | null;
  phone_primary: string | null;
  phone_secondary: string | null;
  fax: string | null;
  online_booking: string | null;
  accepting_patients: boolean;
  time_zone: string | null;
  accepts_walk_ins: boolean;
  employees: object[];
  business_hours: object[];
}
