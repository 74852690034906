import { getFormattedDate } from "../../../utils/date.functions";
import { AuthSession } from "../types/auth-session/auth-session.interface";
import { AuthState } from "../types/auth-state";
import { AccountStatus } from "../types/subscriptions/account-status";

function getAuthSession(authState: AuthState): AuthSession {
  return authState.session;
}

function getAuthSessionEmail(authState: AuthState): string | null {
  return authState.session.email;
}

function isAuthLoading(authState: AuthState): boolean {
  return authState.loading;
}

function getPatientUserId(authState: AuthState): number | null {
  return authState.session.patientUserId;
}

function isEmailConfirmed(authState: AuthState): boolean {
  return authState.session.emailConfirmed;
}

function isPatientConfirmed(authState: AuthState): boolean {
  return authState.session.patientConfirmed;
}

function getUserPhoneNumber(authState: AuthState): string | null {
  return authState.session.recoveryPhone;
}

function isAuthorizedToEditAccountSettings(authState: AuthState): boolean {
  return authState.isAuthorizedToEditAccountSettings;
}

function isTwoFactorEnabled(authState: AuthState): boolean {
  return authState.session.twoFactorEnabled;
}

function twoFactorQrCodeUri(authState: AuthState): string | null {
  return authState.twoFactorQrCodeUri;
}

function authSessionHasBeenSet(authState: AuthState): boolean {
  return Boolean(authState.session.patientId);
}

function isValidAuthSessionAndJwtSet(authState: AuthState): boolean {
  return (
    Boolean(authState.validJwtTokenIsFetched) &&
    authSessionHasBeenSet(authState)
  );
}

function isTwoFactorAuthenticationEnabled(authState: AuthState): boolean {
  return authState.session.twoFactorEnabled;
}

function isTwoFactorAuthenticated(authState: AuthState): boolean {
  return authState.isTwoFactorAuthenticated;
}

function getAccountStatus(authState: AuthState): AccountStatus {
  return getAuthSession(authState).accountStatus;
}

function isTrialSubscriptionActive(authState: AuthState): boolean {
  return getAuthSession(authState).activeTrial;
}

function getTrialEndDate(authState: AuthState): string {
  return getAuthSession(authState).trialEndDate
    ? getFormattedDate(getAuthSession(authState).trialEndDate)
    : "";
}

function getJwtExpiryMillisecondsSinceEpoch(
  authState: AuthState
): number | null {
  return authState.jwtExpiryMillisecondsSinceEpoch;
}

function getLandingPage(authState: AuthState): string {
  return authState.landingPage;
}

export const authGetters = {
  getAuthSession,
  getAuthSessionEmail,
  isAuthLoading,
  getPatientUserId,
  isEmailConfirmed,
  isPatientConfirmed,
  getUserPhoneNumber,
  isAuthorizedToEditAccountSettings,
  isTwoFactorEnabled,
  twoFactorQrCodeUri,
  authSessionHasBeenSet,
  isTwoFactorAuthenticationEnabled,
  isTwoFactorAuthenticated,
  isValidAuthSessionAndJwtSet,
  getAccountStatus,
  getTrialEndDate,
  isTrialSubscriptionActive,
  getJwtExpiryMillisecondsSinceEpoch,
  getLandingPage,
};

export type AuthGetters = typeof authGetters;
