import { AvaModule } from "@/store/modules/types/ava-module.interface";
import { RootState } from "@/store/types/root-state.type";
import {
  createDefaultTestResultsState,
  TestResultsState,
} from "../types/test-results-state";
import { testResultsGetters, TestResultsGetters } from "./test-results-getters";
import {
  TestResultsMutations,
  testResultsMutations,
} from "./test-results-mutations";
import { testResultsActions, TestResultsActions } from "./test-results-actions";

export const TestResultsStoreModule: AvaModule<
  TestResultsState,
  RootState,
  TestResultsActions,
  TestResultsMutations,
  TestResultsGetters
> = {
  state(): TestResultsState {
    return createDefaultTestResultsState();
  },
  mutations: testResultsMutations,
  getters: testResultsGetters,
  actions: testResultsActions,
};
