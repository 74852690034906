import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, mergeProps as _mergeProps, Teleport as _Teleport, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5149e5fc"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "header"
}
const _hoisted_2 = { class: "body" }
const _hoisted_3 = {
  key: 1,
  class: "footer"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_arrow_back_icon = _resolveComponent("arrow-back-icon")!
  const _component_base_typography = _resolveComponent("base-typography")!
  const _component_base_transition_fade = _resolveComponent("base-transition-fade")!

  return (_ctx.isOpen)
    ? (_openBlock(), _createBlock(_Teleport, {
        key: 0,
        to: "body"
      }, [
        _createVNode(_component_base_transition_fade, null, {
          default: _withCtx(() => [
            _createElementVNode("div", _mergeProps(_ctx.$attrs, {
              class: "modal-background",
              onClick: _cache[2] || (_cache[2] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.toggleModal && _ctx.toggleModal(...args)), ["prevent"]))
            }), [
              _createElementVNode("div", {
                class: _normalizeClass(["modal", _ctx.modalClasses]),
                onClick: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["stop"]))
              }, [
                (_ctx.title)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                      _createVNode(_component_arrow_back_icon, {
                        class: "tos-back-arrow-mobile",
                        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close')))
                      }),
                      _createVNode(_component_base_typography, {
                        h1: "",
                        caution: _ctx.caution
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.title), 1)
                        ]),
                        _: 1
                      }, 8, ["caution"])
                    ]))
                  : _createCommentVNode("", true),
                _createElementVNode("div", _hoisted_2, [
                  _renderSlot(_ctx.$slots, "default", {}, undefined, true)
                ]),
                (_ctx.footer)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                      _createElementVNode("div", null, [
                        _renderSlot(_ctx.$slots, "footer-close-button", {}, undefined, true)
                      ]),
                      _createElementVNode("div", null, [
                        _renderSlot(_ctx.$slots, "footer-affirmative-button", {}, undefined, true)
                      ])
                    ]))
                  : _createCommentVNode("", true)
              ], 2)
            ], 16)
          ]),
          _: 3
        })
      ]))
    : _createCommentVNode("", true)
}