import { RouteRecordRaw } from "vue-router";
import { Routes } from "@/router/types/routes.enum";
import MainLayout from "@/layouts/page-layouts/main-layout.vue";

const EntryPointPatientProfileReviewPage = () =>
  import(
    /* webpackChunkName: "patient_profile_review_index_page" */ "@/pages/patient-profile-review/index.vue"
  );
const Demographics = () =>
  import(
    /* webpackChunkName: "patient_profile_review_demographics" */ "@/pages/patient-profile-review/pages/demographics/demographics.vue"
  );

export const patientProfileReviewRoute: RouteRecordRaw = {
  path: "/patient-profile-review",
  component: EntryPointPatientProfileReviewPage,
  children: [
    {
      path: "demographics",
      name: Routes.patientProfileReviewDemographics,
      component: Demographics,
      meta: {
        layout: MainLayout,
        isProtected: true,
      },
    },
  ],
};
