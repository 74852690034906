import { createApp } from "vue";
import App from "./App.vue";
import { router } from "./router/router";
import { key, store } from "./store";
import { createPinia } from "pinia";
import vuetify from "./plugins/vuetify";
import { loadFonts } from "./plugins/webfontloader";
import BaseImgVue from "./ui-library/base-img/base-img.vue";

loadFonts();

const app = createApp(App);

app.use(store, key);

app.use(router);

const pinia = createPinia();

app.use(pinia);

app.use(vuetify);

app.component("base-img", BaseImgVue);

app.mount("#app");
