
import { defineComponent } from "vue";
import IconWrapper from "./icon-wrapper.vue";

export default defineComponent({
  components: { IconWrapper },
  setup() {
    return {
      icon: '<i class="material-icons">home_work</i>',
    };
  },
});
