import { RouteRecordRaw } from "vue-router";
import { Routes } from "@/router/types/routes.enum";
import MainLayout from "@/layouts/page-layouts/main-layout.vue";

const EntryPointManageDependantsPage = () =>
  import(
    /* webpackChunkName: "manage_dependants_index_page" */ "@/pages/manage-dependants/index.vue"
  );
const DefaultManageDependants = () =>
  import(
    /* webpackChunkName: "manage_dependants_default_page" */ "@/pages/manage-dependants/manage-dependants.vue"
  );
const AddDependant = () =>
  import(
    /* webpackChunkName: "add_new_dependant_page" */ "@/pages/manage-dependants/pages/add-dependant/add-dependant.vue"
  );

export const manageDependantsRoute: RouteRecordRaw = {
  path: "/manage-dependants",
  component: EntryPointManageDependantsPage,
  children: [
    {
      path: "",
      name: Routes.manageDependants,
      component: DefaultManageDependants,
      meta: {
        layout: MainLayout,
        isProtected: true,
      },
    },
    {
      path: "add-dependant",
      name: Routes.addDependant,
      component: AddDependant,
      meta: {
        layout: MainLayout,
        isProtected: true,
      },
    },
  ],
};
