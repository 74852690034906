import { HttpError } from "@/http/types/http-error.interface";
import { MutationTypes } from "@/store/constants/mutation-types";
import { BiometricFormState } from "../types/biometric-form-state";

export const biometricFormMutations = {
  [MutationTypes.BIOMETRIC_FORM__TOGGLE_LOADING](
    state: BiometricFormState,
    isLoading: boolean
  ) {
    state.loading = isLoading;
  },
  [MutationTypes.BIOMETRIC_FORM__SET_ERROR](
    state: BiometricFormState,
    error: HttpError
  ) {
    state.error = error;
  },
  [MutationTypes.BIOMETRIC_FORM__FORM_SUBMISSION_SUCCEEDED](
    state: BiometricFormState
  ) {
    state.formSubmissionSucceeded = true;
    state.error = null;
  },
  [MutationTypes.BIOMETRIC_FORM__RESET_FORM_SUBMISSION_SUCCEEDED](
    state: BiometricFormState
  ) {
    state.formSubmissionSucceeded = false;
  },
};

export type BiometricFormMutations = typeof biometricFormMutations;
