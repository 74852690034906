import { Ref, onMounted } from "vue";
import throttle from "lodash/throttle";
import { bulkAddEventListener } from "../types/jwt-session-management.functions";
import { useSessionStore } from "../../../store/session-store";

export function useCheckJwtSessionOnVisibilityChange(
  isProtected: Ref<boolean>
) {
  const sessionStore = useSessionStore();
  // Everytime user moves away from the screen and comes back we renew token. If token is expired, it will send user to sign in
  const visibilityEventsHandler = throttle(
    () => {
      if (document.visibilityState === "visible") {
        if (isProtected.value) {
          sessionStore.renewJwt();
        }
      }
    },
    10000, // 10 seconds
    { leading: true, trailing: false }
  );
  onMounted(() => {
    bulkAddEventListener(
      document, // use document rather than window since Safari visibility events only work with document
      ["visibilitychange"],
      visibilityEventsHandler
    );
  });
}
