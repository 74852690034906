import { TrackedWindowEvents } from "./tracked-window-events.type";

export const bulkAddEventListener = function bulkAddEventListener(
  object: Document | Window,
  events: TrackedWindowEvents[],
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  callback: any
) {
  events.forEach((event: TrackedWindowEvents) => {
    object.addEventListener(event, callback);
  });
};
