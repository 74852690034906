
import { defineComponent } from "vue";
import MediaQueryLayout from "@/layouts/media-query-layout/media-query-layout.vue";
import LogoutIcon from "@/ui-library/icons/logout-icon.vue";
import BaseTypography from "@/ui-library/base-typography/base-typography.vue";

export default defineComponent({
  setup() {
    return {
      logoutButtonText: "Logout",
    };
  },
  components: {
    MediaQueryLayout,
    LogoutIcon,
    BaseTypography,
  },
});
