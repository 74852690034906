import { HttpError } from "@/http/types/http-error.interface";
import { MutationTypes } from "@/store/constants/mutation-types";
import { TestResultLineGraphDetailsItem } from "../types/test-result-graph-details-item";
import { TestResultItem } from "../types/test-result-item";
import { TestResultItemDetail } from "../types/test-result-item-detail";
import { TestResultsState } from "../types/test-results-state";

export const testResultsMutations = {
  [MutationTypes.TEST_RESULTS__SET_RESULTS_LIST](
    state: TestResultsState,
    results: TestResultItem[]
  ) {
    state.testResultsList = results;
  },
  [MutationTypes.TEST_RESULTS__SET_SELECTED_TEST_RESULT](
    state: TestResultsState,
    result: TestResultItem
  ) {
    state.selectedTestResult = result;
  },
  [MutationTypes.TEST_RESULTS__SET_TEST_RESULT_DETAILS](
    state: TestResultsState,
    resultDetails: TestResultItemDetail[]
  ) {
    state.testResultDetails = resultDetails;
  },
  [MutationTypes.TEST_RESULTS__SET_TEST_RESULT_LINE_GRAPH_DATA](
    state: TestResultsState,
    graphDetailsItem: TestResultLineGraphDetailsItem
  ) {
    state.testResultLineGraphDetailsItem = graphDetailsItem;
  },
  [MutationTypes.TEST_RESULTS__SET_TEST_RESULT_LINE_GRAPH_TITLE](
    state: TestResultsState,
    title: string
  ) {
    state.testResultLineGraphTitle = title;
  },
  [MutationTypes.TEST_RESULTS__TOGGLE_LOADING](
    state: TestResultsState,
    isLoading: boolean
  ) {
    state.loading = isLoading;
  },
  [MutationTypes.TEST_RESULTS__SET_ERROR](
    state: TestResultsState,
    error: HttpError | null
  ) {
    state.error = error;
  },
};

export type TestResultsMutations = typeof testResultsMutations;
