import { authActionsJwt, AuthActionsJwt } from "./auth-actions-to-update-jwt";
import {
  AuthActionsNavigate,
  authActionsNavigate,
} from "./auth-actions-to-navigate";
import { authActionsLogout, AuthActionsLogout } from "./auth-actions-to-logout";
import {
  authActionsConfirmEmail,
  AuthActionsConfirmEmail,
} from "./auth-actions-to-confirm-email";
import {
  authActionsResetPassword,
  AuthActionsResetPassword,
} from "./auth-actions-to-reset-password";
import {
  AuthActionsTwoFactorAuthenticate,
  authActionsTwoFactorAuthenticate,
} from "./auth-actions-to-two-factor-authenticate";
import {
  AuthActionsEditAccount,
  authActionsEditAccount,
} from "./auth-actions-to-edit-account";

export type AuthActions = AuthActionsLogout &
  AuthActionsConfirmEmail &
  AuthActionsTwoFactorAuthenticate &
  AuthActionsResetPassword &
  AuthActionsJwt &
  AuthActionsNavigate &
  AuthActionsEditAccount;

export const authActions: AuthActions = {
  ...authActionsLogout,
  ...authActionsConfirmEmail,
  ...authActionsTwoFactorAuthenticate,
  ...authActionsResetPassword,
  ...authActionsJwt,
  ...authActionsNavigate,
  ...authActionsEditAccount,
};
