import { useSessionStore } from "../../../store/session-store";
import { watchEffect } from "vue";
import { LocalStorageItems } from "../../../types/auth-local-storage/auth-local-storage.enums";
import { Ref } from "vue";

/**

Session in sessionStore would be already set if the user logs in.
but not when user refreshes the page or visits Connect before token expires.
This composition ensures that we actually initialize the session in sessionStore.
The session is defined as useful data of the person who logs in. This can be
a patient or a patient's caregiver.

 */

export function useInitializeSession(isProtected: Ref<boolean>) {
  const sessionStore = useSessionStore();

  const defaultPatientUserId = parseInt(
    localStorage.getItem(LocalStorageItems.patientUserId)?.toString() || ""
  );

  watchEffect(() => {
    if (isProtected.value) {
      if (!sessionStore.getIsSessionFetched) {
        // if defaultPatientUserId is NaN then http error and user
        // will be returned to login page via axios interceptor
        sessionStore.fetchSession(defaultPatientUserId);
      }
    }
  });
}
