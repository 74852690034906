import { ActionTypes } from "@/store/constants/action-types";
import { MutationTypes } from "@/store/constants/mutation-types";
import { AvaStore } from "@/store/types/store.type";
import { ActionContext } from "vuex";
import { HttpError } from "@/http/types/http-error.interface";
import { BiometricFormState } from "../types/biometric-form-state";
import { BloodPressureHeartRateRequestPayload } from "../types/blood-pressure-request-payload";
import biometricsApi from "./services/biometrics-service";

export interface BiometricFormActions {
  [ActionTypes.SUBMIT_BIOMETRIC_FORM]: (
    context: ActionContext<BiometricFormState, AvaStore>,
    payload: BloodPressureHeartRateRequestPayload
  ) => Promise<void>;
  [ActionTypes.CONFIRM_BIOMETRIC_FORM_SUBMISSION]: (
    context: ActionContext<BiometricFormState, AvaStore>
  ) => void;
}

export const biometricFormActions: BiometricFormActions = {
  async [ActionTypes.SUBMIT_BIOMETRIC_FORM](store, payload) {
    try {
      store.commit(MutationTypes.BIOMETRIC_FORM__TOGGLE_LOADING, true);
      await biometricsApi.addBiometric(payload);
      store.dispatch(ActionTypes.CONFIRM_BIOMETRIC_FORM_SUBMISSION);
    } catch (error) {
      store.commit(MutationTypes.BIOMETRIC_FORM__SET_ERROR, error as HttpError);
    } finally {
      store.commit(MutationTypes.BIOMETRIC_FORM__TOGGLE_LOADING, false);
    }
  },
  [ActionTypes.CONFIRM_BIOMETRIC_FORM_SUBMISSION](store) {
    store.dispatch(ActionTypes.TOAST_SUCCESSFUL_SUBMISSION, 3000);
    store.commit(MutationTypes.BIOMETRIC_FORM__FORM_SUBMISSION_SUCCEEDED);
    setTimeout(() => {
      store.commit(
        MutationTypes.BIOMETRIC_FORM__RESET_FORM_SUBMISSION_SUCCEEDED
      );
    }, 1000);
  },
};
