import { AuthStoreModule } from "@/features/the-authentication";
import { ToastMessagesModule } from "@/features/toast-messages/store/toast-messages-module";
import { AppointmentsStoreModule } from "@/pages/appointments/store";
import { ManageClinicsStoreModule } from "@/pages/manage-clinics/store";
import { ManageDependantsStoreModule } from "@/features/shared-manage-dependants/store";
import { BiometricFormStoreModule } from "@/features/biometric-form/store/store-biometric-form";
import { BiometricGraphStoreModule } from "@/pages/biometrics/store/store-biometric-graphs";
import { TestResultsStoreModule } from "@/pages/test-results/store";
import { PatientProfileStoreModule } from "@/features/patient-profile";

export const avaModules = {
  auth: AuthStoreModule,
  toastMessages: ToastMessagesModule,
  appointments: AppointmentsStoreModule,
  patientProfile: PatientProfileStoreModule,
  manageClinics: ManageClinicsStoreModule,
  manageDependants: ManageDependantsStoreModule,
  biometricForms: BiometricFormStoreModule,
  biometricGraphs: BiometricGraphStoreModule,
  testResults: TestResultsStoreModule,
};
