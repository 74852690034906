import { HttpError } from "@/http/types/http-error.interface";
import { MutationTypes } from "@/store/constants/mutation-types";
import {
  Appointment,
  createCancelledAppointment,
  getAppointmentIndex,
} from "@/features/shared-appointments/types/appointment";
import { AppointmentsState } from "../types/appointments-state";

export const appointmentsMutations = {
  [MutationTypes.APPOINTMENTS__SET_BOOKED_APPOINTMENTS](
    state: AppointmentsState,
    appointments: Appointment[]
  ) {
    state.appointments = appointments;
  },
  [MutationTypes.APPOINTMENTS__CANCEL_APPOINTMENT](
    state: AppointmentsState,
    appointmentId: number
  ) {
    const appointmentIndex = getAppointmentIndex(
      appointmentId,
      state.appointments
    );
    if (appointmentIndex === -1) return;
    state.appointments.splice(
      appointmentIndex,
      1,
      createCancelledAppointment(appointmentIndex, state.appointments)
    );
  },
  [MutationTypes.APPOINTMENTS__TOGGLE_LOADING](
    state: AppointmentsState,
    isLoading: boolean
  ) {
    state.loading = isLoading;
  },
  [MutationTypes.APPOINTMENTS__SET_ERROR](
    state: AppointmentsState,
    error: HttpError | null
  ) {
    state.error = error;
  },
};

export type AppointmentsMutations = typeof appointmentsMutations;
