import { ActionTypes } from "@/store/constants/action-types";
import { MutationTypes } from "@/store/constants/mutation-types";
import { AvaStore } from "@/store/types/store.type";
import { AvaActionContext } from "@/store/modules/types/ava-action-context";
import { AuthState } from "../../types/auth-state";
import authApi from "../../services/auth-services";
import { EditTwoFactorRequestPayload } from "../../types/account-settings-security/edit-two-factor-request-payload";
import { HttpError } from "@/http/types/http-error.interface";

export interface AuthActionsTwoFactorAuthenticate {
  [ActionTypes.AUTH__EDIT_TWO_FACTOR_AUTHENTICATION_SETTING]: (
    context: AvaActionContext<AuthState, AvaStore>,
    payload: EditTwoFactorRequestPayload
  ) => Promise<void>;
  [ActionTypes.AUTH__EMAIL_TWO_FACTOR_CODE]: (
    context: AvaActionContext<AuthState, AvaStore>
  ) => Promise<void>;
  [ActionTypes.AUTH__SUBMIT_TWO_FACTOR_CODE]: (
    context: AvaActionContext<AuthState, AvaStore>,
    payload: string
  ) => Promise<void>;
}

export const authActionsTwoFactorAuthenticate: AuthActionsTwoFactorAuthenticate =
  {
    async [ActionTypes.AUTH__EDIT_TWO_FACTOR_AUTHENTICATION_SETTING](
      store,
      payload
    ) {
      try {
        store.commit(MutationTypes.AUTH__TOGGLE_LOADING, true);
        await store.dispatch(
          ActionTypes.AUTH__EDIT_TWO_FACTOR_SETTING__UPDATES_JWT,
          payload
        );
        store.commit(
          MutationTypes.AUTH__SET_IS_TWO_FACTOR_AUTHENTICATION_ENABLED,
          payload.isEnabled
        );
        store.dispatch(
          ActionTypes.TOAST_SIMPLE_MESSAGE,
          "Successfully updated two factor authentication"
        );
      } catch (error) {
        store.commit(MutationTypes.AUTH__SET_ERROR, error as HttpError);
      } finally {
        store.commit(MutationTypes.AUTH__TOGGLE_LOADING, false);
      }
    },
    async [ActionTypes.AUTH__EMAIL_TWO_FACTOR_CODE](store) {
      try {
        store.commit(MutationTypes.AUTH__TOGGLE_LOADING, true);
        await authApi.emailTwoFactorCode();
        store.dispatch(ActionTypes.TOAST_NEW_MESSAGE, {
          message:
            "Successfully emailed two factor authentication code. Your email may take a few minutes to arrive. Check your spam folder for any messages from Ava.",
          type: "notification",
          timeToView: 10000,
        });
      } catch (error) {
        store.commit(MutationTypes.AUTH__SET_ERROR, error as HttpError);
      } finally {
        store.commit(MutationTypes.AUTH__TOGGLE_LOADING, false);
      }
    },
    async [ActionTypes.AUTH__SUBMIT_TWO_FACTOR_CODE](store, code) {
      try {
        store.commit(MutationTypes.AUTH__TOGGLE_LOADING, true);
        await store.dispatch(
          ActionTypes.AUTH__AUTH__SUBMIT_TWO_FACTOR_CODE__UPDATES_JWT,
          code
        );
        store.dispatch(ActionTypes.AUTH__GO_TO_LANDING_PAGE);
      } catch (error) {
        store.commit(MutationTypes.AUTH__SET_ERROR, error as HttpError);
      } finally {
        store.commit(MutationTypes.AUTH__TOGGLE_LOADING, false);
      }
    },
  };
