
import { defineComponent } from "vue";
export default defineComponent({
  data() {
    return {
      themeMode: "light",
    };
  },
  computed: {
    isThemeModeDark(): boolean {
      return this.themeMode === "dark";
    },
  },
  methods: {
    switchDarkMode(): void {
      this.isThemeModeDark
        ? (this.themeMode = "light")
        : (this.themeMode = "dark");
    },
  },
});
