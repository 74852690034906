import { RouteRecordRaw } from "vue-router";
import { Routes } from "../types/routes.enum";
import MainLayout from "@/layouts/page-layouts/main-layout.vue";

const TheDashboard = () =>
  import(
    /* webpackChunkName: "the_dashboard_page" */ "@/pages/dashboard/dashboard.vue"
  );

export const dashboardRoute: RouteRecordRaw = {
  path: "/dashboard",
  name: Routes.dashboard,
  component: TheDashboard,
  meta: {
    layout: MainLayout,
    isProtected: true,
  },
};
