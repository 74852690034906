
import { defineComponent, PropType } from "vue";

export default defineComponent({
  props: {
    large: {
      type: Boolean,
      default: false,
    },
    avatarImg: {
      type: String as PropType<string | null>,
      default: "",
    },
  },
});
