import { renderSlot as _renderSlot, mergeProps as _mergeProps, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _mergeProps(_ctx.$attrs, { class: "extra-small" }), [
      _renderSlot(_ctx.$slots, "extra-small", {}, undefined, true)
    ], 16),
    _createElementVNode("div", _mergeProps(_ctx.$attrs, { class: "default" }), [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ], 16)
  ], 64))
}