import { ActionTypes } from "@/store/constants/action-types";
import { MutationTypes } from "@/store/constants/mutation-types";
import { AvaStore } from "@/store/types/store.type";
import { ActionContext } from "vuex";
import { ManageDependantsState } from "./types/manage-dependants-state";
import ManageDependantsApi from "./services/manage-dependants-services";
import { createDependantFromDto } from "./types/dependant";
import {
  AddDependantActionPayload,
  createAddDependantFormRequestDto,
} from "./types/add-dependant-form-payload";
import { DeleteDependantPayload } from "./types/delete-dependant-payload";
import { HttpError } from "@/http/types/http-error.interface";
export interface ManageDependantsActions {
  [ActionTypes.FETCH_DEPENDANTS]: (
    context: ActionContext<ManageDependantsState, AvaStore>,
    patientUserId: number
  ) => Promise<void>;
  [ActionTypes.ADD_DEPENDANT]: (
    context: ActionContext<ManageDependantsState, AvaStore>,
    payload: AddDependantActionPayload
  ) => Promise<void>;
  [ActionTypes.TOGGLE_SUCCESSFULLY_ADDED_DEPENDANT]: (
    context: ActionContext<ManageDependantsState, AvaStore>,
    status: boolean
  ) => void;
  [ActionTypes.DELETE_DEPENDANT]: (
    context: ActionContext<ManageDependantsState, AvaStore>,
    payload: DeleteDependantPayload
  ) => Promise<void>;
}

export const manageDependantsActions: ManageDependantsActions = {
  async [ActionTypes.FETCH_DEPENDANTS](store, patientUserId) {
    try {
      store.commit(MutationTypes.MANAGE_DEPENDANTS__TOGGLE_LOADING, true);
      const results = await ManageDependantsApi.getDependants(patientUserId);
      store.commit(
        MutationTypes.MANAGE_DEPENDANTS__SET_DEPENDANTS,
        results.data.patient_user_dependents.map(createDependantFromDto)
      );
      store.commit(MutationTypes.MANAGE_DEPENDANTS__SET_ERROR, null);
    } catch (error) {
      store.commit(
        MutationTypes.MANAGE_DEPENDANTS__SET_ERROR,
        error as HttpError
      );
    } finally {
      store.commit(MutationTypes.MANAGE_DEPENDANTS__TOGGLE_LOADING, false);
    }
  },
  async [ActionTypes.ADD_DEPENDANT](store, payload) {
    try {
      store.commit(MutationTypes.MANAGE_DEPENDANTS__TOGGLE_LOADING, true);
      createAddDependantFormRequestDto(payload);
      const results = await ManageDependantsApi.addDependant(
        payload.patientUserId,
        createAddDependantFormRequestDto(payload)
      );
      store.commit(
        MutationTypes.MANAGE_DEPENDANTS__ADD_DEPENDANT,
        createDependantFromDto(results.data.patient_user_dependent)
      );
      store.commit(MutationTypes.MANAGE_DEPENDANTS__SET_ERROR, null);
    } catch (error) {
      store.commit(
        MutationTypes.MANAGE_DEPENDANTS__SET_ERROR,
        error as HttpError
      );
    } finally {
      store.commit(MutationTypes.MANAGE_DEPENDANTS__TOGGLE_LOADING, false);
    }
  },
  [ActionTypes.TOGGLE_SUCCESSFULLY_ADDED_DEPENDANT](store, status) {
    store.commit(
      MutationTypes.MANAGE_DEPENDANTS__TOGGLE_SUCCESSFULLY_ADDED_DEPENDANT,
      status
    );
  },
  async [ActionTypes.DELETE_DEPENDANT](store, payload) {
    try {
      store.commit(MutationTypes.MANAGE_DEPENDANTS__TOGGLE_LOADING, true);
      await ManageDependantsApi.deleteDependant(
        payload.patientUserId,
        payload.patientUserDependantId
      );
      store.commit(
        MutationTypes.MANAGE_DEPENDANTS__DELETE_DEPENDANT,
        payload.patientUserDependantId
      );
      store.commit(MutationTypes.MANAGE_DEPENDANTS__SET_ERROR, null);
    } catch (error) {
      store.commit(
        MutationTypes.MANAGE_DEPENDANTS__SET_ERROR,
        error as HttpError
      );
      store.dispatch(ActionTypes.TOAST_GENERAL_ERROR);
    } finally {
      store.commit(MutationTypes.MANAGE_DEPENDANTS__TOGGLE_LOADING, false);
    }
  },
};
