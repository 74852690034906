import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-55592130"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "img-container" }
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_img = _resolveComponent("base-img")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["avatar-container", { large: _ctx.large }])
  }, [
    _createElementVNode("div", _hoisted_1, [
      (_ctx.avatarImg)
        ? (_openBlock(), _createElementBlock("img", {
            key: 0,
            src: _ctx.avatarImg,
            alt: "patient-avatar",
            class: "avatar-img"
          }, null, 8, _hoisted_2))
        : (_openBlock(), _createBlock(_component_base_img, {
            key: 1,
            src: "avatar/avatar-grey.svg",
            alt: "patient-avatar",
            class: "avatar-img"
          }))
    ])
  ], 2))
}