import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-104e971d"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "side-menu-item-slot-container" }
const _hoisted_2 = {
  key: 0,
  class: "nested-list"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_typography = _resolveComponent("base-typography")!
  const _component_chevron_icon = _resolveComponent("chevron-icon")!
  const _component_base_transition_fade = _resolveComponent("base-transition-fade")!
  const _component_base_list_item = _resolveComponent("base-list-item")!

  return (_openBlock(), _createBlock(_component_base_list_item, {
    showSeparationBorders: !_ctx.isNestedItem,
    class: _normalizeClass(["side-menu-item", { indent: _ctx.isNestedItem }])
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_base_typography, {
          "inherit-color": "",
          bold: "",
          class: "side-menu-icon"
        }, {
          default: _withCtx(() => [
            _renderSlot(_ctx.$slots, "icon", {}, undefined, true)
          ]),
          _: 3
        }),
        _createVNode(_component_base_typography, {
          subtitle2: "",
          noMargin: ""
        }, {
          default: _withCtx(() => [
            _renderSlot(_ctx.$slots, "title", {}, undefined, true)
          ]),
          _: 3
        }),
        _createVNode(_component_base_transition_fade, null, {
          default: _withCtx(() => [
            (!_ctx.showNestedList && _ctx.$slots['nested-list'])
              ? (_openBlock(), _createBlock(_component_base_typography, {
                  key: 0,
                  class: "down-arrow"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_chevron_icon, { direction: "down" })
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true)
          ]),
          _: 1
        })
      ]),
      _createVNode(_component_base_transition_fade, null, {
        default: _withCtx(() => [
          (_ctx.showNestedList)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _renderSlot(_ctx.$slots, "nested-list", {}, undefined, true)
              ]))
            : _createCommentVNode("", true)
        ]),
        _: 3
      })
    ]),
    _: 3
  }, 8, ["showSeparationBorders", "class"]))
}