import { BiometricTypes } from "../biometrics-types.enum";

export const createParametersForBiometricGraphRequest = (
  payload: BiometricGraphRequestPayload
): BiometricRequestParams => ({
  result_type: payload.biometricType,
  start_date: payload.startDate,
  end_date: payload.endDate,
  split_by_time: payload.splitByTime,
  units: payload.units,
  last_x_days: payload?.lastNumberOfDays,
});

export interface BiometricRequestParams {
  result_type: string;
  start_date: string;
  end_date: string;
  split_by_time: boolean;
  units: string;
  last_x_days?: number;
}

export interface BiometricGraphRequestPayload {
  patientId: number;
  biometricType: BiometricTypes;
  lastNumberOfDays?: number;
  startDate: string;
  endDate: string;
  splitByTime: boolean;
  units: string;
}
