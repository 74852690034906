import { RouteRecordRaw } from "vue-router";
import { Routes } from "../types/routes.enum";
import MainLayout from "@/layouts/page-layouts/main-layout.vue";

const TheMessages = () =>
  import(
    /* webpackChunkName: "the_messages_page" */ "@/pages/messages/messages.vue"
  );

export const messagesRoute: RouteRecordRaw = {
  path: "/messages",
  name: Routes.messages,
  component: TheMessages,
  meta: {
    layout: MainLayout,
    isProtected: true,
  },
};
