import { AxiosError } from "axios";
import { createErrorToastMessage } from "../../features/toast-messages/types/toast-message";
import { router, Routes } from "../../router";
import { store } from "../../store";
import { ActionTypes } from "../../store/constants/action-types";
import { AvaApiErrorData } from "./ava-api-error-data.interface";
import { HttpError } from "./http-error.interface";

export function createHttpError(error: AxiosError<AvaApiErrorData>): HttpError {
  return {
    name: error.name,
    status: error.response?.status,
    message: error.message,
    responseData: error.response?.data,
    errorCode: error.response?.data?.error_code,
    request: error?.request,
  };
}

export function navigateUserAccordingToError(httpError: HttpError) {
  const code = httpError.status;
  const avaInternalErrorCode = httpError.errorCode;
  if (avaInternalErrorCode === "incomplete") {
    router.goTo(Routes.completeRegistration);
  } else if (avaInternalErrorCode === "two_factor_incomplete") {
    router.goTo(Routes.twoFactorSignIn);
  } else if (code === 400) {
    store.dispatch(
      ActionTypes.TOAST_NEW_MESSAGE,
      createErrorToastMessage(
        `error code 400: Bad request ${httpError.request}`
      )
    );
  } else if (code === 401) {
    store.dispatch(ActionTypes.TOAST_AVA_API_ERRORS, httpError);
    router.handleUnauthorizedStatusError();
  } else if (code === 402) {
    router.goTo(Routes.subscriptions);
  } else {
    store.dispatch(ActionTypes.TOAST_AVA_API_ERRORS, httpError);
  }
}
