import { TestResultItemDto } from "./test-result-item";

const createTestResultItemDetailValueTypeFromDto = (
  dto?: TestResultItemDetailDtoValueType
): TestResultItemDetailValueType | undefined => {
  switch (dto) {
    case "NM":
      return "numeric";
    case "TX":
      return "text";
    case "SN":
      return "string"; // consider SN as string. I think SN means String Number.
    case "ST":
      return "string";
    default:
      return "text";
  }
};

export const createTestResultDetailFromDto = (
  dto: TestResultItemDetailDto
): TestResultItemDetail => ({
  id: Number(dto.id),
  label: dto.attributes.name,
  value: dto.attributes.value,
  valueType: createTestResultItemDetailValueTypeFromDto(
    dto.attributes.value_type
  ),
  reference: dto.attributes.reference,
  units: dto.attributes.units,
  base64Pdf: dto.attributes.base64_pdf,
});

export interface TestResultItemDetailRequestPayload {
  patientId: number;
  resultId: number;
}

export interface TestResultItemDetailResponseDto {
  data: TestResultItemDto;
  included: TestResultItemDetailDto[];
}

export interface TestResultItemDetail {
  id: number;
  label: string;
  value?: string;
  valueType?: TestResultItemDetailValueType;
  reference?: string;
  units?: string;
  base64Pdf?: string;
}

export type TestResultItemDetailDtoValueType = "NM" | "TX" | "ST" | "SN";
export type TestResultItemDetailValueType = "numeric" | "text" | "string";

export interface TestResultItemDetailDto {
  id: number;
  type: string;
  attributes: {
    name: string;
    value?: string;
    value_type?: TestResultItemDetailDtoValueType;
    reference?: string;
    abnormal_flag?: boolean | null;
    units?: string;
    component_code?: string;
    base64_pdf?: string;
  };
}
