import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_typography = _resolveComponent("base-typography")!
  const _component_base_button = _resolveComponent("base-button")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createBlock(_component_router_link, {
    class: "link",
    to: { name: _ctx.reportIssuesRouteName },
    target: "_blank",
    title: "Tech Support"
  }, {
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default", {}, () => [
        _createVNode(_component_base_button, {
          class: "tech-support-button",
          tertiary: ""
        }, {
          default: _withCtx(() => [
            _createVNode(_component_base_typography, {
              italics: "",
              caption: ""
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.techSupportButtonTitle), 1)
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ], true)
    ]),
    _: 3
  }, 8, ["to"]))
}