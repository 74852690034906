
import { defineComponent } from "vue";
import BaseSideMenu from "@/ui-library/base-side-menu/base-side-menu.vue";
import BaseButton from "@/ui-library/base-button/base-button.vue";
import { useToggledValue } from "@/composition/use-toggled-value";
import BaseButtonIcon from "@/ui-library/base-button/base-button-icon.vue";
import MenuIcon from "@/ui-library/icons/menu-icon.vue";
import { router, Routes } from "@/router";
import ProfileCard from "./profile-card.vue";
import DashboardIcon from "@/ui-library/icons/dashboard-icon.vue";
import CloseIcon from "@/ui-library/icons/close-icon.vue";
import CalendarIcon from "@/ui-library/icons/calendar-icon.vue";
import MailIcon from "@/ui-library/icons/mail-icon.vue";
import LineGraphIcon from "@/ui-library/icons/line-graph-icon.vue";
import BiometricsIcon from "@/ui-library/icons/biometrics-icon.vue";
import BiometricsMenuItems from "./biometrics-menu-items.vue";
import TestResultsIcon from "@/ui-library/icons/test-results-icon.vue";
import ProfileSettingsIcon from "@/ui-library/icons/profile-settings-icon.vue";
import LogoutIcon from "@/ui-library/icons/logout-icon.vue";
import GearIcon from "@/ui-library/icons/gear-icon.vue";
import QuestionIcon from "@/ui-library/icons/question-icon.vue";
import NoteIcon from "@/ui-library/icons/note-icon.vue";
import DocumentIcon from "@/ui-library/icons/document-icon.vue";
import ChevronIcon from "@/ui-library/icons/chevron-icon.vue";
import AccountSettingsMenuItems from "./account-settings-menu-items.vue";
import ProfileSettingsMenuItems from "./profile-settings-menu-items.vue";
import SideMenuItem from "./side-menu-item.vue";
import ToolbarRowButton from "./toolbar-row-button.vue";
import TermsOfServiceBodyText from "../../../pages/authentication/pages/sign-up/components/terms-of-service-body-text.vue";
import BaseModal from "../../../ui-library/base-modal/base-modal.vue";
import BaseTypography from "@/ui-library/base-typography/base-typography.vue";

export default defineComponent({
  emits: ["logout"],
  components: {
    BaseSideMenu,
    BaseButtonIcon,
    BaseButton,
    MenuIcon,
    ProfileCard,
    SideMenuItem,
    DashboardIcon,
    CloseIcon,
    CalendarIcon,
    MailIcon,
    LineGraphIcon,
    BiometricsIcon,
    BiometricsMenuItems,
    TestResultsIcon,
    ProfileSettingsIcon,
    LogoutIcon,
    GearIcon,
    AccountSettingsMenuItems,
    ProfileSettingsMenuItems,
    QuestionIcon,
    ToolbarRowButton,
    DocumentIcon,
    NoteIcon,
    ChevronIcon,
    TermsOfServiceBodyText,
    BaseModal,
    BaseTypography,
  },
  setup(props, context) {
    const { isTrue: isMenuOpen, toggleValue: toggleMenu } = useToggledValue();
    const { isTrue: isTermsOfServiceOpen, toggleValue: toggleTermsOfService } =
      useToggledValue();
    const {
      isTrue: isPatientSettingsListOpen,
      toggleValue: togglePatientSettingsListItems,
    } = useToggledValue();
    const {
      isTrue: isBiometricsListOpen,
      toggleValue: toggleBiometricsListItems,
    } = useToggledValue();
    const {
      isTrue: isAccountSettingsListOpen,
      toggleValue: toggleAccountSettingsListItems,
    } = useToggledValue();
    const goToMenuItem = (route: Routes) => {
      router.goTo(route);
      toggleMenu();
    };
    return {
      dashboardTitle: "Dashboard",
      appointmentsTitle: "Appointments",
      messagesTitle: "Messages",
      biometricsTitle: "Biometrics",
      testResultsTitle: "Test Results",
      patientDocumentsTitle: "Patient Documents",
      patientChartsTitle: "Patient Charts",
      patientSettingsTitle: "Patient Settings",
      accountSettingsTitle: "Account Settings",
      helpTitle: "Tech Support",
      logoutTitle: "Logout",
      reportIssuesRouteName: Routes.reportIssues,
      isMenuOpen,
      toggleMenu,
      toggleTermsOfService,
      isTermsOfServiceOpen,
      Routes,
      goToMenuItem,
      isBiometricsListOpen,
      toggleBiometricsListItems,
      isPatientSettingsListOpen,
      togglePatientSettingsListItems,
      isAccountSettingsListOpen,
      toggleAccountSettingsListItems,
      logout: () => context.emit("logout"),
      goToManageClinics: () => router.goTo(Routes.manageClinics),
      goToManageDependants: () => router.goTo(Routes.manageDependants),
      goToPatientProfileReview: () =>
        router.goTo(Routes.patientProfileReviewDemographics),
      goToPatientCharts: () => router.goTo(Routes.patientCharts),
      goToReportIssuePage: () =>
        window.open(
          router.resolve({ name: Routes.reportIssues }).href,
          "_blank"
        ),
    };
  },
});
