export const createTestResultItemFromDto = (dto: TestResultItemDto) => ({
  id: Number(dto.id),
  label: dto.attributes.full_name,
  datePerformed: dto.attributes.date_performed,
  category: dto.attributes.category,
});

export interface TestResultItem {
  id: number;
  label: string;
  datePerformed: string;
  category: TestResultItemCategory;
}

export type TestResultItemCategory = "Lab" | "Investigation";

export interface TestResultItemDto {
  id: string;
  type: string;
  attributes: {
    full_name: string;
    category: string;
    date_performed: string;
    subcategory: string | null;
  };
  relationships: unknown;
}
