import { MutationTypes } from "@/store/constants/mutation-types";
import {
  ToastMessage,
  findToastMessageIndexByMessage,
} from "../types/toast-message";
import { ToastMessagesState } from "../types/toast-messages-state.interface";

export const toastMessagesMutations = {
  [MutationTypes.TOAST__ADD_MESSAGE](
    state: ToastMessagesState,
    payload: ToastMessage
  ) {
    const { isFound } = findToastMessageIndexByMessage(
      state.toastMessages,
      payload.message
    );
    if (!isFound) {
      state.toastMessages.push(payload);
    }
  },
  [MutationTypes.TOAST__REMOVE_MESSAGES](state: ToastMessagesState) {
    state.toastMessages = [];
  },
  [MutationTypes.TOAST__REMOVE_SINGLE_MESSAGE](
    state: ToastMessagesState,
    message: string
  ) {
    const { messageIndex, isFound } = findToastMessageIndexByMessage(
      state.toastMessages,
      message
    );
    if (isFound) {
      state.toastMessages.splice(messageIndex, 1);
    }
  },
};

export type ToastMessagesMutations = typeof toastMessagesMutations;
