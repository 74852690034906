
import TheToolbar from "@/features/the-toolbar/the-toolbar.vue";
import { defineComponent } from "vue";
import ReportIssueButton from "@/pages/report-issue/components/report-issue-button.vue";

export default defineComponent({
  props: {
    showToolbar: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    TheToolbar,
    ReportIssueButton,
  },
});
