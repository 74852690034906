import { defineStore } from "pinia";
import {
  MassCommunicationsState,
  MassCommunication,
} from "../types/mass-communications-state";
import massCommunicationService from "../services/mass-communication-service";
import { store } from "@/store";
import { ActionTypes } from "@/store/constants/action-types";
import { createToastMessage } from "@/features/toast-messages/types/toast-message";

export const useMassCommunicationsStore = defineStore(
  "MassCommunicationsStore",
  {
    state: (): MassCommunicationsState => {
      return {
        isLoading: false,
        massCommunications: [],
      };
    },
    getters: {
      getMassCommunications: (state) => state.massCommunications,
      getIsLoading: (state) => state.isLoading,
    },
    actions: {
      async fetchMassCommunications(patientId: number) {
        if (patientId === null) return;
        this.isLoading = true;
        try {
          const result = await massCommunicationService.fetchMassCommunications(
            patientId
          );
          this.setUnreadMassCommunications(result.data.mass_communications);
        } catch (error) {
          console.error(error);
        } finally {
          this.isLoading = false;
        }
      },
      async acknowledgeThisCommunication(
        patientId: number,
        massCommunicationId: number
      ) {
        this.isLoading = true;
        try {
          this.closeMassCommunication(massCommunicationId);
          await massCommunicationService.acknowledgeCommunication(
            patientId,
            massCommunicationId
          );
          this.successfullyAcknowledgedToastMessage();
        } catch (error) {
          console.error(error);
        } finally {
          this.isLoading = false;
        }
      },
      setUnreadMassCommunications(massCommunications: MassCommunication[]) {
        this.massCommunications = massCommunications;
      },
      closeMassCommunication(massCommunicationId: number) {
        this.massCommunications = this.massCommunications.filter(
          (massCommunication) => massCommunication.id !== massCommunicationId
        );
      },
      successfullyAcknowledgedToastMessage() {
        store.dispatch(
          ActionTypes.TOAST_NEW_MESSAGE,
          createToastMessage("Communication acknowledged")
        );
      },
    },
  }
);
