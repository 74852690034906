export enum ActionTypes {
  AUTH__SET_JWT_FROM_HTTP = "AUTH__SET_JWT_FROM_HTTP",
  AUTH__CREATE_AUTH_SESSION__UPDATES_JWT = "AUTH__CREATE_AUTH_SESSION__UPDATES_JWT",
  AUTH__AUTH__SUBMIT_TWO_FACTOR_CODE__UPDATES_JWT = "AUTH__AUTH__SUBMIT_TWO_FACTOR_CODE__UPDATES_JWT",
  AUTH__UPDATE_JWT_EXPIRY_IF_NECESSARY = "AUTH__UPDATE_JWT_EXPIRY_IF_NECESSARY",
  AUTH__RENEW_JWT = "AUTH__RENEW_JWT",
  AUTH__GO_TO_LANDING_PAGE = "AUTH__GO_TO_LANDING_PAGE",
  AUTH__UPDATE_LANDING_PAGE = "AUTH__UPDATE_LANDING_PAGE",
  AUTH__EDIT_TWO_FACTOR_SETTING__UPDATES_JWT = "AUTH__EDIT_TWO_FACTOR_SETTING__UPDATES_JWT",
  AUTH__SIGN_INTO_APP = "AUTH__SIGN_INTO_APP",
  AUTH__LOGOUT = "AUTH__LOGOUT",
  AUTH__FETCH_SESSION = "AUTH__FETCH_SESSION",
  AUTH__AUTHORIZE_TO_EDIT_ACCOUNT_SETTINGS = "AUTH__AUTHORIZE_TO_EDIT_ACCOUNT_SETTINGS",
  AUTH__CHANGE_PASSWORD = "AUTH__CHANGE_PASSWORD",
  AUTH__EDIT_TWO_FACTOR_AUTHENTICATION_SETTING = "AUTH__EDIT_TWO_FACTOR_AUTHENTICATION_SETTING",
  AUTH__EMAIL_TWO_FACTOR_CODE = "AUTH__EMAIL_TWO_FACTOR_CODE",
  AUTH__SUBMIT_TWO_FACTOR_CODE = "AUTH__SUBMIT_TWO_FACTOR_CODE",
  AUTH__EMAIL_RESET_PASSWORD_TOKEN = "AUTH__EMAIL_RESET_PASSWORD_TOKEN",
  AUTH__CHANGE_FORGOTTEN_PASSWORD = "AUTH__CHANGE_FORGOTTEN_PASSWORD",
  FETCH_PATIENT_PROFILE = "FETCH_PATIENT_PROFILE",
  SAVE_PATIENT_AVATAR = "SAVE_PATIENT_AVATAR",
  EDIT_PATIENT_PROFILE = "EDIT_PATIENT_PROFILE",
  EDIT_PATIENT_PHONE = "EDIT_PATIENT_PHONE",
  DELETE_PATIENT_PHONE = "DELETE_PATIENT_PHONE",
  ADD_PATIENT_PHONE = "ADD_PATIENT_PHONE",
  SET_PATIENT_USER_ID_ON_PERSISTANCE_LAYER = "SET_PATIENT_USER_ID_ON_PERSISTANCE_LAYER",
  SET_PATIENT_ID_TO_INITIATE_PATIENT_PROFILE = "SET_PATIENT_ID_TO_INITIATE_PATIENT_PROFILE",
  CONFIRM_EMAIL_TOKEN = "CONFIRM_EMAIL_TOKEN",
  RESEND_EMAIL_TOKEN = "RESEND_EMAIL_TOKEN",
  CONFIRM_PHONE_TOKEN = "CONFIRM_PHONE_TOKEN",
  RESEND_PHONE_TOKEN = "RESEND_PHONE_TOKEN",
  FETCH_DASHBOARD_REPORT = "FETCH_DASHBOARD_REPORT",
  TOAST_SIMPLE_MESSAGE = "TOAST_SIMPLE_MESSAGE",
  TOAST_NEW_MESSAGE = "TOAST_NEW_MESSAGE",
  TOAST_GENERAL_ERROR = "TOAST_GENERAL_ERROR",
  TOAST_SUCCESSFUL_SUBMISSION = "TOAST_SUCCESSFUL_SUBMISSION",
  TOAST_AVA_API_ERRORS = "TOAST_AVA_API_ERRORS",
  SUBMIT_SIGN_UP_FORM = "SUBMIT_SIGN_UP_FORM",
  FETCH_CONVERSATION_SUMMARY = "FETCH_CONVERSATION_SUMMARY",
  FETCH_CONVERSATION = "FETCH_CONVERSATION",
  SUBMIT_MESSAGE = "SUBMIT_MESSAGE",
  FETCH_MESSAGEABLE_CLINICS = "FETCH_MESSAGEABLE_CLINICS",
  SUBMIT_NEW_CONVERSATION_MESSAGE = "SUBMIT_NEW_CONVERSATION_MESSAGE",
  FETCH_APPOINTMENTS = "FETCH_APPOINTMENTS",
  CANCEL_APPOINTMENT = "CANCEL_APPOINTMENT",
  FETCH_AUTHORIZED_CLINICS = "FETCH_AUTHORIZED_CLINICS",
  FETCH_SEARCHED_CLINICS = "FETCH_SEARCHED_CLINICS",
  AUTHORIZE_CLINIC = "AUTHORIZE_CLINIC",
  DEAUTHORIZE_CLINIC = "DEAUTHORIZE_CLINIC",
  FETCH_DEPENDANTS = "FETCH_DEPENDANTS",
  ADD_DEPENDANT = "ADD_DEPENDANT",
  TOGGLE_SUCCESSFULLY_ADDED_DEPENDANT = "TOGGLE_SUCCESSFULLY_ADDED_DEPENDANT",
  DELETE_DEPENDANT = "DELETE_DEPENDANT",
  FETCH_MEDICAL_HISTORY = "FETCH_MEDICAL_HISTORY",
  ADD_MEDICAL_HISTORY = "ADD_MEDICAL_HISTORY",
  FETCH_FAMILY_HISTORY = "FETCH_FAMILY_HISTORY",
  ADD_FAMILY_HISTORY = "ADD_FAMILY_HISTORY",
  EDIT_FAMILY_HISTORY = "EDIT_FAMILY_HISTORY",
  FETCH_SOCIAL_HISTORY = "FETCH_SOCIAL_HISTORY",
  ADD_SOCIAL_HISTORY = "ADD_SOCIAL_HISTORY",
  EDIT_SOCIAL_HISTORY = "EDIT_SOCIAL_HISTORY",
  FETCH_ALLERGY_HISTORY = "FETCH_ALLERGY_HISTORY",
  ADD_ALLERGY_HISTORY = "ADD_ALLERGY_HISTORY",
  EDIT_ALLERGY_HISTORY = "EDIT_ALLERGY_HISTORY",
  FETCH_MEDICATION_REVIEW = "FETCH_MEDICATION_REVIEW",
  FETCH_REFERRAL_REVIEW = "FETCH_REFERRAL_REVIEW",
  ADD_VACCINE_HISTORY = "ADD_VACCINE_HISTORY",
  EDIT_VACCINE_HISTORY = "EDIT_VACCINE_HISTORY",
  FETCH_VACCINE_HISTORY = "FETCH_VACCINE_HISTORY",
  SUBMIT_BIOMETRIC_FORM = "SUBMIT_BIOMETRIC_FORM",
  CONFIRM_BIOMETRIC_FORM_SUBMISSION = "CONFIRM_BIOMETRIC_FORM_SUBMISSION",
  FETCH_BIOMETRIC_GRAPH_DETAILS = "FETCH_BIOMETRIC_GRAPH_DETAILS",
  FETCH_TEST_RESULTS_LIST = "FETCH_TEST_RESULTS_LIST",
  FETCH_TEST_RESULT_DETAILS = "FETCH_TEST_RESULT_DETAILS",
  FETCH_TEST_RESULT_LINE_GRAPH_DATA = "FETCH_TEST_RESULT_LINE_GRAPH_DATA",
  FETCH_VIDEO_LINK_INITIALIZATION = "FETCH_VIDEO_LINK_INITIALIZATION",
  FETCH_VIDEO_LINK_CONNECTION_TOKEN = "FETCH_VIDEO_LINK_CONNECTION_TOKEN",
}
