import { renderSlot as _renderSlot, withModifiers as _withModifiers, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-275868e8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "base-drop-down-list" }
const _hoisted_2 = {
  key: 0,
  class: "drop-down-container"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_overlay_background = _resolveComponent("base-overlay-background")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("a", {
      onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.toggleShowMenu && _ctx.toggleShowMenu(...args)), ["prevent"]))
    }, [
      _renderSlot(_ctx.$slots, "button", { isOpen: _ctx.showMenu }, undefined, true)
    ]),
    _createVNode(_component_base_overlay_background, {
      showBackground: _ctx.showMenu,
      onBackgroundClicked: _ctx.toggleShowMenu
    }, null, 8, ["showBackground", "onBackgroundClicked"]),
    (_ctx.showMenu)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", {
            class: "menu ava-shadow",
            onClick: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.toggleShowMenu && _ctx.toggleShowMenu(...args)), ["stop"])),
            style: _normalizeStyle(_ctx.menuStyles)
          }, [
            _renderSlot(_ctx.$slots, "list", {}, undefined, true)
          ], 4)
        ]))
      : _createCommentVNode("", true)
  ]))
}