import { RootState } from "@/store/types/root-state.type";
import { AvaModule } from "@/store/modules/types/ava-module.interface";
import { ToastMessagesState } from "../types/toast-messages-state.interface";
import {
  toastMessagesActions,
  ToastMessagesActions,
} from "./toast-messages-actions";
import {
  toastMessagesGetters,
  ToastMessagesGetters,
} from "./toast-messages-getters";
import { createToastMessagesState } from "../types/toast-messages-state.functions";
import {
  ToastMessagesMutations,
  toastMessagesMutations,
} from "./toast-messages-mutations";

export const ToastMessagesModule: AvaModule<
  ToastMessagesState,
  RootState,
  ToastMessagesActions,
  ToastMessagesMutations,
  ToastMessagesGetters
> = {
  state() {
    return createToastMessagesState();
  },
  mutations: toastMessagesMutations,
  actions: toastMessagesActions,
  getters: toastMessagesGetters,
};
