import { RouteRecordRaw } from "vue-router";
import { Routes } from "@/router/types/routes.enum";
import MainLayout from "@/layouts/page-layouts/main-layout.vue";

const EntryPointDocumentsPage = () =>
  import(
    /* webpackChunkName: "patient_documents_index_page" */ "@/pages/patient-documents/patient-documents.vue"
  );

export const documentRoute: RouteRecordRaw = {
  path: "/documents",
  component: EntryPointDocumentsPage,
  name: Routes.patientDocuments,
  meta: {
    layout: MainLayout,
    isProtected: true,
  },
};
