import { format } from "date-fns";
import parseISO from "date-fns/parseISO";
import formatDistanceToNow from "date-fns/formatDistanceToNow";
import { FormatTimeOptions } from "./format-time-options.interface";

export const getISODateFromDate = (date: Date) =>
  date.toISOString().substring(0, 10);

export const getISODateFromString = (date?: string) => {
  const today = new Date();
  return date ? getISODateFromDate(new Date(date)) : getISODateFromDate(today);
};

export const getISODateInURLFormatFromString = (date: string) => {
  return new Date(date).toISOString().replace(/[-:]/g, "").split(".")[0] + "Z";
};

export const convertISODateToReadableString = (isoDate: string): string => {
  return parseISO(isoDate).toDateString();
};

export const isValidTimeZone = (zone?: string) => {
  try {
    if (!Intl || !Intl.DateTimeFormat().resolvedOptions().timeZone || !zone) {
      return false;
    }
    Intl.DateTimeFormat(undefined, { timeZone: zone });
    return true;
  } catch (e) {
    return false;
  }
};

const createTimeFormatter = (
  options: Intl.DateTimeFormatOptions,
  locales?: string | string[]
) => new Intl.DateTimeFormat(locales, options);

export const getTime = (
  date: string,
  options: FormatTimeOptions = {}
): string => {
  const formatOptions: Intl.DateTimeFormatOptions = {
    hour: "numeric",
    minute: "numeric",
    timeZone: isValidTimeZone(options?.timeZone)
      ? options?.timeZone
      : undefined,
    timeZoneName: options?.includeTimeZone ? "short" : undefined,
  };
  return createTimeFormatter(formatOptions, "en-CA").format(parseISO(date));
};

export const getFormattedTimeRange = (
  startDate: string,
  endDate: string,
  options: FormatTimeOptions = {}
) => {
  const startTime = getTime(startDate, options);
  const endTime = getTime(endDate, { ...options, includeTimeZone: true });
  return `${startTime} - ${endTime}`;
};

export const getFormattedDate = (
  date: string,
  preferredFormat?: string
): string => {
  return format(parseISO(date), preferredFormat || "PPP");
};

export const getFormattedDateRange = (
  startDate: string,
  endDate: string,
  preferredFormat?: string
): string => {
  return `${getFormattedDate(startDate, preferredFormat)} - ${getFormattedDate(
    endDate,
    preferredFormat
  )}`;
};

export const getTimeAgoFromIsoString = (isoDate: string) => {
  formatDistanceToNow(parseISO(isoDate), { addSuffix: true });
};

export const dateDifferenceInDays = (date1: Date, date2: Date): number => {
  const date1utc = Date.UTC(
    date1.getFullYear(),
    date1.getMonth(),
    date1.getDate()
  );
  const date2utc = Date.UTC(
    date2.getFullYear(),
    date2.getMonth(),
    date2.getDate()
  );
  const day = 1000 * 60 * 60 * 24;
  const dayDifference = date2utc - date1utc;
  return dayDifference ? dayDifference / day : 0;
};

export const generateRandomDate = (start: Date, end: Date) =>
  new Date(start.getTime() + Math.random() * (end.getTime() - start.getTime()));
