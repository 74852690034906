import { useStore } from "@/store";
import { ActionTypes } from "@/store/constants/action-types";
import { usePatientProfileGetters } from "@/features/patient-profile";
import { Dependant } from "@/features/shared-manage-dependants/store/types/dependant";
import { useAuthStore } from "@/features/the-authentication";
import { usePatientProfileStore } from "../../../store/patient-profile-store";

export function useManageDependantsManager() {
  const store = useStore();
  const { patientUserId, sessionPatientId } = useAuthStore();
  const { patientId } = usePatientProfileGetters();
  const patientProfileStore = usePatientProfileStore();

  const deleteDependant = (patientUserDependantId: number) => {
    if (patientUserId.value) {
      store.dispatch(ActionTypes.DELETE_DEPENDANT, {
        patientUserId: patientUserId.value,
        patientUserDependantId: patientUserDependantId,
      });
    }
  };

  const isDependantSelected = (dependant: Dependant): boolean =>
    dependant.patientId === patientId.value;

  const chooseDependant = (dependant: Dependant) => {
    if (dependant.patientId && dependant.confirmed) {
      patientProfileStore.fetchPatientProfile(dependant.patientId);
    }
  };

  const stopCaretakingMode = () => {
    if (sessionPatientId.value) {
      patientProfileStore.fetchPatientProfile(sessionPatientId.value);
    }
  };

  return {
    deleteDependant,
    isDependantSelected,
    chooseDependant,
    stopCaretakingMode,
  };
}
