import http from "@/http/http";
import { AxiosPromise } from "axios";
import { SignUpRequestDto } from "@/features/the-authentication/types/sign-up-form-submission";

export function signUpPatientUser(
  signUpRequestDto: SignUpRequestDto
): AxiosPromise {
  return http.post(`/patient_users`, signUpRequestDto);
}

export function resetPhoneToken(patientUserId: number): AxiosPromise {
  return http.get(`/patient_users/${patientUserId}/recovery_phone_token`);
}

export function confirmPhoneToken(
  patientUserId: number,
  token: string
): AxiosPromise {
  return http.post(
    `/patient_users/${patientUserId}/recovery_phone_token`,
    null,
    {
      params: {
        sms_token: token,
      },
    }
  );
}

export function resetEmailToken(patientUserId: number): AxiosPromise {
  return http.get(`/patient_users/${patientUserId}/email_token`);
}

export function confirmEmailToken(
  patientUserId: number,
  token: string
): AxiosPromise {
  return http.post(`/patient_users/${patientUserId}/email_token`, null, {
    params: {
      token,
    },
  });
}
