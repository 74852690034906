import { LineGraphData, LineGraphOptions } from "@/types/line-graph";
import { BiometricGraphState } from "../types/biometric-graph/biometric-graph-state";

export interface BiometricGraphGetters {
  getBiometricGraphLoading: (state: BiometricGraphState) => boolean;
  getBiometricGraphData: (state: BiometricGraphState) => LineGraphData | null;
  getBiometricGraphOptions: (
    state: BiometricGraphState
  ) => LineGraphOptions | null;
}

export const biometricGraphGetters: BiometricGraphGetters = {
  getBiometricGraphLoading(state) {
    return state.loading;
  },
  getBiometricGraphData: (state: BiometricGraphState) => {
    return state.graphDetails?.data || null;
  },
  getBiometricGraphOptions: (state: BiometricGraphState) => {
    return state.graphDetails?.options || null;
  },
};
