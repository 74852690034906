import { computed, watchEffect } from "vue";
import { store as avaVuexStore } from "@/store";
import { ActionTypes } from "@/store/constants/action-types";
import { useSessionStore } from "../../../store/session-store";

export function useInitializeDependants() {
  const sessionStore = useSessionStore();
  const sessionOwnerPatientUserId = computed(
    () => sessionStore.getSessionOwnerPatientUserId
  );
  watchEffect(() => {
    if (sessionOwnerPatientUserId.value) {
      avaVuexStore.dispatch(
        ActionTypes.FETCH_DEPENDANTS,
        sessionOwnerPatientUserId.value
      );
    }
  });
}
