import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3c6dcd00"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "toolbar-container" }
const _hoisted_2 = { class: "toolbar-content-container" }
const _hoisted_3 = { class: "toolbar-side-flex-grow-left" }
const _hoisted_4 = { class: "toolbar-side-flex-grow-right" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_toolbar_logo = _resolveComponent("toolbar-logo")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _renderSlot(_ctx.$slots, "left-side", {}, undefined, true),
        _createVNode(_component_toolbar_logo, {
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('logoClicked')))
        })
      ]),
      _createElementVNode("div", _hoisted_4, [
        _renderSlot(_ctx.$slots, "right-side", {}, undefined, true)
      ])
    ]),
    _renderSlot(_ctx.$slots, "default", {}, undefined, true)
  ]))
}