
import { defineComponent } from "vue";
import BaseTransitionFade from "../base-transition/base-transition-fade.vue";
import BaseTypography from "../base-typography/base-typography.vue";
import { PropType } from "vue";
import ArrowBackIcon from "../icons/arrow-back-icon.vue";

export default defineComponent({
  inheritAttrs: false,
  emits: ["close"],
  props: {
    isOpen: {
      type: Boolean,
      default: false,
      required: true,
    },
    title: {
      type: String as PropType<string | null>,
      required: false,
    },
    clickOutsideToClose: {
      type: Boolean,
      default: false,
      required: false,
    },
    largeModal: {
      type: Boolean,
      default: false,
    },
    caution: {
      type: Boolean,
      default: false,
    },
    footer: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      modalClasses: {
        "modal-large-height": this.largeModal,
      },
    };
  },
  components: {
    BaseTransitionFade,
    BaseTypography,
    ArrowBackIcon,
  },
  methods: {
    toggleModal() {
      if (this.clickOutsideToClose) {
        this.$emit("close");
      }
    },
  },
  watch: {
    isOpen: function (state: boolean): void {
      if (state) {
        document.body.style.overflow = "hidden";
        document.body.style.position = "fixed";
        document.body.style.width = "100vw";
      } else {
        document.body.style.overflow = "auto";
        document.body.style.position = "static";
        document.body.style.width = "auto";
      }
    },
  },
});
