import { ActionTypes } from "@/store/constants/action-types";
import { AvaStore } from "@/store/types/store.type";
import { MutationTypes } from "@/store/constants/mutation-types";
import { HttpError } from "@/http/types/http-error.interface";
import { convertAvaApiErrorDataToMessageList } from "@/http/types/ava-api-error-data.functions";
import { AvaActionContext } from "@/store/modules/types/ava-action-context";
import { ToastMessagesState } from "../types/toast-messages-state.interface";
import {
  createErrorToastMessage,
  createToastMessage,
  ToastMessage,
} from "../types/toast-message";

const genericMessage =
  "Oh... Something went wrong. Please refresh and try again";

const successfulMessage = "Succesfully submitted!";

type StoreContext = AvaActionContext<ToastMessagesState, AvaStore>;
export interface ToastMessagesActions {
  [ActionTypes.TOAST_SIMPLE_MESSAGE]: (
    store: StoreContext,
    message: string
  ) => void;
  [ActionTypes.TOAST_NEW_MESSAGE]: (
    store: StoreContext,
    payload: ToastMessage
  ) => void;
  [ActionTypes.TOAST_GENERAL_ERROR]: (store: StoreContext) => void;
  [ActionTypes.TOAST_SUCCESSFUL_SUBMISSION]: (
    store: StoreContext,
    timeToView?: number
  ) => void;
  [ActionTypes.TOAST_AVA_API_ERRORS]: (
    store: StoreContext,
    error: HttpError
  ) => void;
}

export const toastMessagesActions: ToastMessagesActions = {
  [ActionTypes.TOAST_SIMPLE_MESSAGE]({ commit }, message) {
    commit(MutationTypes.TOAST__ADD_MESSAGE, createToastMessage(message));
    setTimeout(() => {
      commit(MutationTypes.TOAST__REMOVE_MESSAGES);
    }, 2000);
  },
  [ActionTypes.TOAST_NEW_MESSAGE]({ commit }, payload) {
    commit(MutationTypes.TOAST__ADD_MESSAGE, payload);
    if (payload.timeToView) {
      setTimeout(() => {
        commit(MutationTypes.TOAST__REMOVE_MESSAGES);
      }, payload.timeToView);
    }
  },
  [ActionTypes.TOAST_GENERAL_ERROR]({ commit }) {
    commit(
      MutationTypes.TOAST__ADD_MESSAGE,
      createToastMessage(genericMessage)
    );
    setTimeout(() => {
      commit(MutationTypes.TOAST__REMOVE_MESSAGES);
    }, 2000);
  },
  [ActionTypes.TOAST_SUCCESSFUL_SUBMISSION]({ dispatch }, timeToView) {
    dispatch(ActionTypes.TOAST_NEW_MESSAGE, {
      message: successfulMessage,
      type: "notification",
      timeToView: timeToView || 2000,
    });
  },
  [ActionTypes.TOAST_AVA_API_ERRORS](store, error) {
    const responseDataErrors = error.responseData?.errors;
    const errorsCodeMessage = error.responseData?.error_code || "";
    if (responseDataErrors) {
      convertAvaApiErrorDataToMessageList(responseDataErrors).forEach(
        (msg: string) =>
          store.dispatch(
            ActionTypes.TOAST_NEW_MESSAGE,
            createErrorToastMessage(msg)
          )
      );
    } else if (errorsCodeMessage) {
      store.dispatch(
        ActionTypes.TOAST_NEW_MESSAGE,
        createErrorToastMessage(errorsCodeMessage)
      );
    } else {
      store.dispatch(
        ActionTypes.TOAST_NEW_MESSAGE,
        createErrorToastMessage("An error occurred")
      );
    }
  },
};
